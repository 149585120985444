/* eslint-disable no-param-reassign */
import { useState } from 'react';
import {
  Form, Select, Row, Col, Button, Slider,
} from 'antd';
import { CSSTransition } from 'react-transition-group';
import { useTransformationTypes } from 'contexts/TransformationTypesContext';
import { getValue } from 'components/formatters/ObjectFormatter';
import { useLocalization } from 'contexts/LocalizationContext';

const { Option } = Select;
const filterValues = (index) => {
  if (localStorage.getItem('transformation') && localStorage.getItem('transformation') !== '{}') {
    return JSON.parse(localStorage.getItem('transformation'))[index];
  }
  return null;
};
function FilterTransformation(props) {
  const {
    onSubmitData, setFilterIsOn, form,
  } = props;
  // States
  const [valuesObject, setValuesObject] = useState({});

  // Contexts
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  const transformationTypes = useTransformationTypes();

  function setNewValue(changedvalues) {
    if (changedvalues.date) {
      changedvalues.date = changedvalues.date.format('YYYY-MM');
    }
    setValuesObject(Object.assign(valuesObject, changedvalues));
  }

  function submitData() {
    setFilterIsOn(true);
    if (valuesObject) { onSubmitData(valuesObject); }
    setValuesObject({});
  }

  return (

    <CSSTransition
      in
      timeout={500}
      classNames="form"
      appear
      unmountOnExit
    >
      <Form
        layout="vertical"
        name="newData"
        onValuesChange={setNewValue}
        onFinish={submitData}
        form={form}
        className="d-100"
        preserve={false}
      >
        <Row gutter={[0, 30]} className="d-100">
          <Col span={24}>
            <Row gutter={[10, 20]} className="d-100">
              <Col sm={24} md={8}>
                <Form.Item
                  label="Opération"
                  labelAlign="left"
                  name="type"
                  initialValue={filterValues('type')}
                >
                  <Select
                    placeholder={locObject.translate('transformation_newDeclaration_operation_ph')}
                    size="middle"
                  >
                    {transformationTypes
                      .values.filter((ty) => ty.enumType !== 'SELL' && ty.enumType !== 'CLOSE').map((type) => (
                        <Option
                          id={type.enumType}
                          key={type.id}
                          value={type.id}
                        >
                          {getValue(type, lang)}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} push={2}>
                <Form.Item label={locObject.translate('surfaces_filter_resultsPerPage_title')} valuePropName="value" name="nbElems">
                  <Slider
                    min={10}
                    max={100}
                    marks={{
                      10: '10', 20: '20', 30: '30', 40: '40', 50: '50', 60: '60', 70: '70', 80: '80', 90: '90', 100: '100',
                    }}
                  />
                </Form.Item>
              </Col>

            </Row>
          </Col>
          <Col span={24}>
            <Row style={{ justifyContent: 'end', fontSize: '2vw' }} gutter={[10, 0]}>
              <Col span={4}>
                <Button
                  block="true"
                  htmlType="submit"
                >
                  {locObject.translate('apply')}

                </Button>
              </Col>

            </Row>
          </Col>

        </Row>

      </Form>
    </CSSTransition>
  );
}

export default FilterTransformation;
