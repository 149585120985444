/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Popover,
} from 'antd';

function TextEditor(props) {
  const {
    commitCellValues,
    enterEditingMod,
    editorOnFocus,
    editorLostFocus,
    rowEditing,
    column,
    isReadOnly,
    tableRow,
    getCellValue,
    editorHasValue,
    valueNotChaged,
    isEditable,
    defaultValue,
  } = props;

  /**
   * @val Is the input value
   * @touches Capture touches from touches devices as like mobile ..
   */
  const [touches, setTouches] = useState([]);
  const [isError, setIsError] = useState({ visible: false, content: '' });
  const [initVal, setInitVal] = useState(tableRow[column.id]);
  const customStyle = column.cellCustomStyle;
  function handleit(e) {
    if (column.validator) {
      const validationResult = column.validator(getCellValue(column.dependencyIndex), e.target.value);
      if (validationResult !== '') {
        setIsError({ visible: true, content: validationResult });
        editorHasValue(column.id, initVal);
      } else {
        // validated
        const value = e.target.value === '' || !e.target.value ? defaultValue : e.target.value;
        editorHasValue(column.id, value);
        setIsError({ visible: false, content: '' });
      }
    } else {
      editorHasValue(column.id, e.target.value);
    }
  }

  useEffect(() => {
    setInitVal(tableRow[column.id]);
  }, [isReadOnly]);

  function touchStart(e) {
    setTouches(touches.concat(e));
    if (touches.length === 1) {
      enterEditingMod();
      setTouches([]);
    }
  }
  function handleOnBlur(e) {
    if (Number(e.target.value) === initVal) {
      return valueNotChaged();
    }
    if (isError.content === '') {
      commitCellValues();
    } else {
      editorLostFocus(true);
    }
    setIsError({ visible: false, content: '' });
    return true;
  }
  function handleOnKeyDown(e) {
    if (e.target.value === initVal) {
      return valueNotChaged();
    }
    if (isError.content === '') {
      setInitVal(tableRow[column.id]);
      commitCellValues();
    } else {
      valueNotChaged();
      editorLostFocus();
    }
    setIsError({ visible: false, content: '' });
    return true;
  }

  function formatter() {
    if (typeof column.formatter !== 'undefined') {
      return column.formatter(tableRow, column, null);
    }
    return tableRow[column.id];
  }

  function onEdit() {
    const isColumnEditable = column.editable ?? true;
    if (isColumnEditable === true && isEditable === true) {
      enterEditingMod();
      editorOnFocus();
    }
  }
  let controller = (
    isEditable === true
      ? (
        <button
          type="button"
          onDoubleClick={onEdit}
          className="editor-read-only d-100 h-100"
          onFocus={editorOnFocus}
          onBlur={editorLostFocus}
          style={customStyle ?? {}}
        >
          {formatter()}
        </button>
      )
      : (
        <span
          className="editor-read-only d-100 h-100"
          style={customStyle ?? { cursor: 'not-allowed' }}
        >
          {formatter()}
        </span>
      )

  );
  if (isReadOnly !== true || rowEditing === true) {
    controller = (
      <Input
        autoFocus
        bordered={false}
        className="editor number d-100 h-100"
        data-column={column.id}
        defaultValue={initVal}
        onPressEnter={handleOnKeyDown}
        onTouchStartCapture={touchStart}
        onBlur={handleOnBlur}
        onChange={handleit}
      />
    );
  }
  return (
    <Popover content={<p className="pop-over-error">{isError.content}</p>} visible={isError.visible}>
      {controller}
    </Popover>
  );
}

// default function
function defaultBehaviour() {}

TextEditor.defaultProps = {
  commitCellValues: defaultBehaviour,
  enterEditingMod: defaultBehaviour,
  editorOnFocus: defaultBehaviour,
  defaultValue: '',
};
TextEditor.propTypes = {
  commitCellValues: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  enterEditingMod: PropTypes.func,
  editorOnFocus: PropTypes.func,
};

export default TextEditor;
