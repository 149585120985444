import { useAuth } from 'contexts/AuthContext';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import {
  Button, Card, Row, Col, Space, Typography, Alert, Form,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  ImportOutlined, CopyOutlined, PlusCircleFilled, CloseCircleFilled,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useLocalization } from 'contexts/LocalizationContext';
import { useNavigate } from 'react-router-dom';
import styles from '../assets/styles/TableAddonsBox.module.css';
import 'assets/styles/transitions.css';
import { checkAdmin } from './RequireAdmin';
import { checkDataEntryRole } from './RequireDataEntry';

const { Title } = Typography;
function NewDataBox(props) {
// context
  const [form] = Form.useForm();
  const { user } = useAuth();
  const notify = useNotificationCenter()();
  const navigate = useNavigate();
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  const userCanEnterData = checkDataEntryRole(user);
  // Props
  const {
    dataSource, newDataText, canImport, title, activityName,
    handleNewDataHidden, newDataFormHidden, NewDataForm, updateData, importButtonText,
  } = props;

  // States

  const [showErrorMessege, setShowErrorMessege] = useState({ visible: false, msg: '', type: 'error' });
  const [hideGlobalMessege, setHideGlobalMessege] = useState(false);
  useEffect(() => {
    if (hideGlobalMessege === true) {
      setShowErrorMessege({ visible: false });
    }
    return () => setHideGlobalMessege(false);
  }, [hideGlobalMessege]);

  function openImportPage() {
    if (checkAdmin(user) === true) {
      navigate('declare/annual-surfaces/import/');
    } else {
      navigate('/declare/annual-surfaces/import');
    }
  }
  function onCancelModifications() {
    form.resetFields();
    handleNewDataHidden(true);
  }

  function handleShowForm() {
    handleNewDataHidden(false);
  }

  function submitNewData(valuesChanged) {
    const response = dataSource.create(valuesChanged);
    const accepted = new Promise((resolve, reject) => {
      response.then((res) => {
        if (res === true) {
          updateData();
          notify({ msg: locObject.translate('newDataBox_success_msg'), type: 'success', desc: '' });
          setShowErrorMessege({ msg: locObject.translate('newDataBox_success_msg'), visible: true, type: 'success' });
          resolve();
        } else {
          setShowErrorMessege({ msg: locObject.translate(res) ?? locObject.translate('detect_error'), visible: true, type: 'error' });
          reject();
        }
      });
    });
    return accepted;
  }

  if (userCanEnterData === true) {
    return (
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        {showErrorMessege.visible && (
          <Alert message={showErrorMessege.msg} onClose={() => setShowErrorMessege({ visible: false })} type={showErrorMessege.type} closable />
        )}
        <Card className="d-100" hidden={newDataFormHidden.isButtonHidden} size="small">
          <Row gutter={[10, 20]}>
            <Col>
              <Button
                onClick={handleShowForm}
                className={styles.filterButton}
                type="default"
                shape="default"
              >
                <PlusCircleFilled />
                {newDataText}
              </Button>

            </Col>
            {canImport && (
              <Col>
                <Button
                  onClick={openImportPage}
                  className={styles.filterButton}
                  type="default"
                  shape="default"
                >
                  <ImportOutlined />
                  {importButtonText}
                  <CopyOutlined />
                </Button>
              </Col>
            )}
          </Row>
        </Card>

        <Row style={{ fontSize: '2vw' }} gutter={[0, 24]} hidden={newDataFormHidden.isFormHidden}>
          <Col span={24}>
            <Card>
              <div style={{ display: 'flex' }}>
                <Row className="d-50">
                  <Title level={5}>{title}</Title>
                </Row>
                <Row justify="end" className="d-50" hidden={activityName === 'transformation'}>
                  <Col
                    span={1}
                    style={{ fontSize: '2em', color: 'var(--primary-color)' }}
                    onClick={() => onCancelModifications()}
                  >
                    <CloseCircleFilled />
                  </Col>
                </Row>
              </div>
              <NewDataForm
                form={form}
                onSubmitData={submitNewData}
                onCancelModifications={onCancelModifications}
                dataSource={dataSource}
                setShowErrorMessege={setHideGlobalMessege}
                {...props}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
  return <Space />;
}

NewDataBox.defaultProps = {
  newDataFormHidden: { isFormHidden: true, isButtonHidden: false },
  newDataText: 'Saisie Manuelle',
  canImport: true,
};

NewDataBox.propTypes = {
  newDataText: PropTypes.string,
  newDataFormHidden: PropTypes.shape({ isFormHidden: PropTypes.bool, isButtonHidden: PropTypes.bool }),
  canImport: PropTypes.bool,
};
export default NewDataBox;
