/* eslint-disable no-nested-ternary */
import { Col, Row, Empty } from 'antd';
import '../assets/styles/homeStatsTable.css';
import { useLocalization } from 'contexts/LocalizationContext';
import StatsTableNumberFormatter from './formatters/StatsTableNumberFormatter';

export default function ContributionsStatsDataTable(props) {
  const {
    columns, data, period, threeDec = true, metadata, unit,
  } = props;
  const loc = useLocalization();
  const { locObject } = loc;

  const finalData = [];

  const formatterThreeDecimals = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  });
  const formatterTwoDecimals = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigit: 2,
  });

  if (data) {
    Object.keys(data).forEach((key) => {
      finalData.push({ title: key, data: data[key] });
    });
  }

  if (finalData) {
    finalData.forEach((d) => {
      if (d.title === 'TOTAL') {
        console.log(d.data.prior);
      }
    });
  }

  return (
    <div className="w-100">
      <Row className="w-100 stats-table-thead">
        {columns.map((thd, ind) => (ind === 0
          ? <Col className="stats-table-td" span={4} key={thd}>{thd}</Col>
          : <Col className="stats-table-td-headers" style={{ width: '10%' }} key={thd}>{thd}</Col>))}
      </Row>
      {finalData.length !== 0 && finalData.length !== 1 ? (
        <>
          <Row className="seperator meta-size" style={{ paddingLeft: '18px' }}>{metadata}</Row>
          {finalData.map((d, index) => (
            <Row className="stats-table-tr">
              <Col key={`tt${d.title}`} span={4} className="stats-table-td" style={index === finalData.length - 1 ? { fontWeight: 'bold' } : null}>{d.title}</Col>
              <Col key={unit} style={{ width: '10%', ...(index === finalData.length - 1 ? { fontWeight: 'bold' } : null) }} className="stats-table-td">{unit}</Col>
              <Col key="frmt-col" style={{ width: '10%', ...(index === finalData.length - 1 ? { fontWeight: 'bold' } : null) }} className="data stats-table-td">{d.data.total !== undefined ? threeDec ? (formatterThreeDecimals.format(d.data.total === 0 ? 0.00 : d.data.total)) : (formatterTwoDecimals.format(d.data.total)) : ('')}</Col>
              {period.map((year) => (
                <Col style={{ width: '10%', ...(index === finalData.length - 1 ? { fontWeight: 'bold' } : null) }} className="data stats-table-td" key={`data-${year}`}>
                  {d.data[year] !== undefined ? threeDec ? (formatterThreeDecimals.format(d.data[year] === 0 ? 0.00 : d.data[year])) : (formatterTwoDecimals.format(d.data[year])) : ('')}
                </Col>
              ))}
              <Col key="frmt-col" style={{ width: '10%', ...(index === finalData.length - 1 ? { fontWeight: 'bold' } : null) }} className="data stats-table-td">{d.data.prior !== undefined ? threeDec ? (formatterThreeDecimals.format(d.data.prior === 0 ? 0.00 : d.data.prior)) : (formatterTwoDecimals.format(d.data.prior)) : ('')}</Col>
            </Row>
          ))}
        </>
      ) : (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            padding: '5vw',
          }}
        >
          <Col span={24}>
            <Empty description={locObject.translate('noData')} imageStyle={{ height: '15vw' }} />
          </Col>
        </Row>
      )}
    </div>
  );
}
