import { ConfigProvider, Empty } from 'antd';
import { AuthProvider } from 'contexts/AuthContext';
import TokenExpirationHandler from 'components/TokenExpirationHandler';
import Router from 'pages';
import 'moment/locale/fr';
import 'moment/locale/nl';
import FrLocale from 'antd/lib/locale/fr_FR';
import DuLocale from 'antd/lib/locale/nl_NL';
import './assets/styles/App.css';
import { NotificationCenter } from 'contexts/NotificationCenter';
import { UserContextProvider } from 'contexts/UserContext';
import { LocalizationProvider, useLocalization } from 'contexts/LocalizationContext';
import { useState } from 'react';
import moment from 'moment';

function App() {
  const [coreLang, setCoreLang] = useState(DuLocale);
  const loc = useLocalization();
  const { locObject } = loc;
  function changeCoreLang(lang) {
    if (lang === 'fr') {
      moment.locale('fr');
      setCoreLang(FrLocale);
    } else {
      moment.locale('nl');
      setCoreLang(DuLocale);
    }
  }
  const empty = () => <Empty description="Pas de données" />;
  return (
    <ConfigProvider renderEmpty={empty} locale={coreLang}>
      <NotificationCenter>
        <AuthProvider>
          <TokenExpirationHandler>
            <UserContextProvider>
              <LocalizationProvider coreLocFunction={changeCoreLang}>
                <Router />
              </LocalizationProvider>
            </UserContextProvider>
          </TokenExpirationHandler>
        </AuthProvider>
      </NotificationCenter>
    </ConfigProvider>
  );
}

export default App;
