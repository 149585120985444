import {
  createContext, useContext, useReducer, useEffect,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

const FederationsContext = createContext([]);

const initialState = {
  values: [],
  isLoading: false,
  isError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fetch_start':
      return {
        ...state,
        isLoading: true,
      };
    case 'fetch_success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        values: action.payload,
      };
    case 'fetch_error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

function FederationsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchFederations() {
      dispatch({ type: 'fetch_start' });

      try {
        const result = await api.get('/company-federation/');
        dispatch({ type: 'fetch_success', payload: result.data });
      } catch (error) {
        dispatch({ type: 'fetch_error' });
      }
    }

    fetchFederations();
  }, []);

  return (
    <FederationsContext.Provider value={state}>
      {children}
    </FederationsContext.Provider>
  );
}

FederationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useFederations() {
  const value = useContext(FederationsContext);

  if (isUndefined(value)) {
    throw new Error('useFederations must be used inside an FederationsProvider component');
  }

  return value;
}

export {
  FederationsProvider, useFederations,
};
