import { useState } from 'react';
import {
  Form, Row, Col, Button, Slider,
} from 'antd';
import { CSSTransition } from 'react-transition-group';
import { useLocalization } from 'contexts/LocalizationContext';

function SellFilter(props) {
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const {
    onSubmitData, onCancelModifications, setFilterIsOn, form,
  } = props;
  // States
  const [valuesObject, setValuesObject] = useState({});

  function removeFilters() {
    const accepted = onCancelModifications(true);
    accepted.then(() => {
      setValuesObject({});
      form.resetFields();
    });
  }

  function setNewValue(changedvalues) {
    setValuesObject(Object.assign(valuesObject, changedvalues));
  }
  function submitData() {
    setFilterIsOn(true);
    if (valuesObject) { onSubmitData(valuesObject); }
    setValuesObject({});
  }

  return (

    <CSSTransition
      in
      timeout={500}
      classNames="form"
      appear
      unmountOnExit
    >
      <Form
        layout="vertical"
        name="newData"
        onValuesChange={setNewValue}
        onFinish={submitData}
        form={form}
        className="d-100"
      >
        <Row gutter={[0, 30]} className="d-100">
          <Col span={24}>
            <Row gutter={[10, 20]} className="d-100">
              <Col span={8} push={2}>
                <Form.Item label={locObject.translate('surfaces_filter_resultsPerPage_title')} valuePropName="value" name="nbElems">
                  <Slider
                    min={10}
                    max={100}
                    marks={{
                      10: '10', 20: '20', 30: '30', 40: '40', 50: '50', 60: '60', 70: '70', 80: '80', 90: '90', 100: '100',
                    }}
                  />
                </Form.Item>
              </Col>

            </Row>
          </Col>
          <Col span={24}>
            <Row style={{ justifyContent: 'end', fontSize: '2vw' }} gutter={[10, 0]}>
              <Col span={5}>
                <Button
                  block="true"
                  danger
                  onClick={removeFilters}
                >
                  Effacer les filtres

                </Button>
              </Col>
              <Col span={4}>
                <Button
                  block="true"
                  htmlType="submit"
                >
                  Appliquer

                </Button>
              </Col>

            </Row>
          </Col>

        </Row>

      </Form>
    </CSSTransition>
  );
}

export default SellFilter;
