/* eslint-disable no-param-reassign */
import { useAuth } from 'contexts/AuthContext';
import { useLocalization } from 'contexts/LocalizationContext';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Button, Card, Row, Col, Typography, Form,
  Modal,
} from 'antd';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import { useState, useEffect } from 'react';
import {
  FilterFilled, DownloadOutlined, CloseCircleFilled,
  SaveOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import styles from '../assets/styles/TableAddonsBox.module.css';
import 'assets/styles/transitions.css';

const { Title } = Typography;

function TableAddonsBox(props) {
  // context
  const [form] = Form.useForm();
  const { user } = useAuth();
  const { sId } = useParams();
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  const notify = useNotificationCenter()();
  // Props
  const {
    handleSetFilteredData, activityName, dataSource, title,
    showExportButton, showFilterButton, FilterForm, year, filterFormHidden,
    handleFilterFormHidden, exportExtras, exportName, showCloseMonthButton,
  } = props;

  function exportCurrentTable() {
    const resp = dataSource.exportCurrentTable(undefined, exportExtras);
    resp.then((response) => {
      // check if the response is empty
      if (response.data.type === 'application/json') {
        notify({
          msg: locObject.translate('nodata_export'), type: 'error', desc: '', duration: 10,
        });
      } else {
        // use a virtual <a> element to trigger the navigator download Event
        const hiddenElement = document.createElement('a');
        const blob = new Blob([response.data]);
        hiddenElement.href = URL.createObjectURL(blob);
        // save the file with the current date
        const date = moment().format('YYYY-MM-SS HH-MM-SS');
        hiddenElement.download = `${exportName}-${date}.xlsx`;
        hiddenElement.click();
      }
    });
  }

  // States

  const [filterIsOn, setFilterIsOn] = useState(false);
  const [lastOperationDateData, setLastOperationDateData] = useState(null);
  const [lastOperationDate, setLastOperationDate] = useState(null);

  function getLastDateTypeOperation(type) {
    const response = dataSource.getLastDateTypeOperation(dataSource.company, type);
    response.then(({ data }) => {
      const date = new Date(data.lastDate);
      date.setDate(date.getDate() - 10);
      date.setMonth(date.getMonth() + 1);
      setLastOperationDate(date);
      setLastOperationDateData(`${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`);
    });
  }

  function onValidate(type) {
    const company = sId ?? user.company;
    const newCloseObject = {
      date: moment(new Date(lastOperationDate)).format('YYYY-MM-DD'),
      type,
      company,
    };

    const response = dataSource.create(newCloseObject);
    response.then((res) => {
      window.location.reload();
    }).catch((err) => {
      console.log(err);
    });
  }

  function showCloseMonthModal() {
    Modal.confirm({
      title: (
        <span>
          {locObject.translate('close_month_modal_title_part_1')}
          {' '}
          <strong>{lastOperationDateData}</strong>
          {' '}
          {locObject.translate('close_month_modal_title_part_2')}
        </span>
      ),
      okText: locObject.translateWithReplace('close_month_modal_ok', {
        placeholder: 'xxxx',
        value: lastOperationDateData,
      }),
      cancelText: locObject.translate('close_month_modal_cancel'),
      onOk() {
        onValidate(7);
        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
      width: 520,
    });
  }

  useEffect(() => {
    const storedFilterData = localStorage.getItem(activityName);
    if (storedFilterData !== '{}' && storedFilterData !== undefined && storedFilterData !== null) {
      setFilterIsOn(true);
    }

    const originalOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (...args) {
      if (args[1].includes('/transformation') && !args[1].includes('?company=') && !args[1].includes('last-operation')) {
        // eslint-disable-next-line react/no-this-in-sfc
        this.addEventListener('load', () => {
          getLastDateTypeOperation('TRANSFORMATION');
          getLastDateTypeOperation('CLOSE');
        });
      }
      return originalOpen.apply(this, args);
    };

    const handleBeforeUnload = (event) => {
      // TODO: Add translations
      const message = 'Êtes-vous sûr de vouloir quitter cette page ?';
      event.returnValue = message;
      return message;
    };

    // window.addEventListener('hashchange', (event) => { console.log('test'); });

    // window.addEventListener('beforeunload', handleBeforeUnload);

    // return () => {
    //   showCloseMonthModal();
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  }, []);

  // Hooks

  function handleFilterForm(filterValues) {
    handleSetFilteredData(filterValues);
    handleFilterFormHidden(true);
  }

  function cancelModifications(modified) {
    setFilterIsOn(false);
    const resp = new Promise((resolve, reject) => {
      if (modified === true) {
        localStorage.setItem(activityName, JSON.stringify({ year }));
        handleSetFilteredData(null);
      }
      handleFilterFormHidden(true);
      resolve();
    });
    return resp;
  }

  function removeFilters() {
    const deleted = cancelModifications(true);
    deleted.then(() => {
      form.resetFields();
    });
  }

  function handleShowForm() {
    handleFilterFormHidden(false);
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <Card className="d-100" size="small" bordered={false} hidden={filterFormHidden.isButtonHidden}>
        <Row>
          <Col flex="auto">
            <Row justify="end" gutter={[10, 2]}>

              {showExportButton && (
              <Col>
                <Button
                  onClick={exportCurrentTable}
                  type="default"
                  shape="default"
                >
                  <DownloadOutlined />
                  {locObject.translate('export')}
                </Button>
              </Col>
              )}
              {showFilterButton && (

              <Col>
                {filterIsOn === true ? (
                  <Button
                    danger
                    onClick={removeFilters}
                  >
                    {locObject.translate('removeFiltersButtonText')}
                  </Button>
                ) : (
                  <Button
                    onClick={handleShowForm}
                    className={filterIsOn ? styles.filterButtonClicked : styles.filterButton}
                    type="default"
                    shape="default"
                  >
                    <FilterFilled />
                    {locObject.translate('filterButtonText')}
                  </Button>
                )}
              </Col>
              )}
              {showCloseMonthButton && (
              <Col>
                <Button
                  onClick={showCloseMonthModal}
                  type="primary"
                  shape="default"
                >
                  <SaveOutlined />
                  {locObject.translate('close_current_month')}
                </Button>
              </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card>
      <Row style={{ fontSize: '2vw' }} gutter={[0, 20]} hidden={filterFormHidden.isFormHidden}>
        <Col span={24}>
          <Card>
            <div style={{ display: 'flex' }}>
              <Row className="d-50">
                <Title level={5}>{title}</Title>
              </Row>
              <Row justify="end" className="d-50">
                <Col
                  span={1}
                  style={{ fontSize: '2em', color: 'var(--primary-color)' }}
                  onClick={() => cancelModifications(false)}
                >
                  <CloseCircleFilled />
                </Col>
              </Row>
            </div>
            {FilterForm && (
            <FilterForm
              setFilterIsOn={setFilterIsOn}
              onSubmitData={handleFilterForm}
              onCancelModifications={cancelModifications}
              user={user}
              dataSource={dataSource}
              form={form}
            />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
TableAddonsBox.defaultProps = {
  showExportButton: true,
  showFilterButton: true,
};

TableAddonsBox.propTypes = {
  showExportButton: PropTypes.bool,
  showFilterButton: PropTypes.bool,

};
export default TableAddonsBox;
