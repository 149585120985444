import PropTypes from 'prop-types';
import { useAuth } from 'contexts/AuthContext';

/**
 * Wrapper around a component that requires auth to be displayed.
 *
 * Redirects to login page if someone tries to render the children without being authenticated.
 */
function RequireAuth({ children }) {
  const { user } = useAuth();
  if (user === null) {
    window.location.replace('/login');
  }
  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequireAuth;
