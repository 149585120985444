import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import api from 'services/api';

/**
 * Wrapper around a component that requires auth to be displayed.
 *
 * Redirects to nonAuth page if someone tries to render the children without being authorized.
 */

// do not allow a reporting user to enter any data
function checkDataEntryRole(user) {
  if (user.roles.includes('ROLE_DATA_ENTERING_USER')) {
    return true;
  }
  return false;
}

function RequireDataEntry({ children }) {
  const [loading, setloading] = useState(true);
  const [u, setU] = useState([]);
  const nav = useNavigate();
  const { user } = useAuth();
  const { sId, federationId } = useParams();
  useEffect(() => {
    async function fetch() {
      const result = await api.get(`/users/${user.id}`);
      if (result.data.roles.includes('ROLE_SUPER_ADMIN') === false && result.data.roles.includes('ROLE_FED_ADMIN') === false && result.data.roles.includes('ROLE_MULTISCUTCHING_ADMIN') === false) {
        return nav('/unauthorized');
      }
      setU(result.data);
      setloading(false);
      return true;
    }
    fetch();
  }, [user]);

  if (loading === true) {
    return <p>Loading ..</p>;
  }
  if (u.roles.includes('ROLE_SUPER_ADMIN')) {
    return children;
  }
  if (u.company.federation.id === federationId && !u.roles.includes('ROLE_SUPER_ADMIN')) {
    return children;
  }
  return children;
}

RequireDataEntry.propTypes = {
  children: PropTypes.node.isRequired,
};

export { RequireDataEntry, checkDataEntryRole };
