// initial Data table stylesheet
import 'assets/styles/datatable.css';

function TransformationTd(props) {
  const { column, className } = props;
  const Editor = column.editor;

  return (
    <td
      className={`master-td ${className}`}
    >
      <Editor
        {...props}
      />
    </td>
  );
}

export default TransformationTd;
