export default function intlNumberFormater(
  val,
  withDecimals = true,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
) {
  const formatterDecimals = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits,
  });
  const formatterWithoutDecimals = new Intl.NumberFormat('fr-FR');
  return withDecimals === true
    ? formatterDecimals.format(val)
    : formatterWithoutDecimals.format(val);
}
