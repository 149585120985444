function TwoLinesIcon({ className }) {
  const rectStyle = {
    fill: 'rgb(2, 53, 103)',
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 95 133"
      className={className}
    >
      <path
        fill="#006D5E"
        d="M3418.53 2715.96H3430.052V2894.545H3418.53z"
        transform="translate(-3610.39 -6512.53) matrix(1.23983 0 0 2.11231 9013.31 4000.25) matrix(.16052 0 0 .35146 -4906.55 234.81)"
      />
      <path
        fill="#006D5E"
        d="M3418.53 2715.96H3430.052V2894.545H3418.53z"
        transform="translate(-3610.39 -6512.53) matrix(1.23983 0 0 2.11231 9013.31 4000.25) matrix(0 .13258 -.42553 0 -3125.99 797.436)"
      />
    </svg>
  );
}
export default TwoLinesIcon;
