import {
  createContext, useContext, useReducer, useEffect,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

const CountriesContext = createContext([]);

const initialState = {
  values: [],
  isLoading: false,
  isError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fetch_start':
      return {
        ...state,
        isLoading: true,
      };
    case 'fetch_success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        values: action.payload,
      };
    case 'fetch_error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

function CountriesProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchCountries() {
      dispatch({ type: 'fetch_start' });

      try {
        const result = await api.get('/countries/');
        dispatch({ type: 'fetch_success', payload: result.data });
      } catch (error) {
        dispatch({ type: 'fetch_error' });
      }
    }

    fetchCountries();
  }, []);

  return (
    <CountriesContext.Provider value={state}>
      {children}
    </CountriesContext.Provider>
  );
}

CountriesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useCountries() {
  const value = useContext(CountriesContext);
  if (isUndefined(value)) {
    throw new Error('useCountries must be used inside an CountriesProvider component');
  }

  return value;
}

export {
  CountriesProvider, useCountries,
};
