/* eslint-disable radix */
import { useEffect, useState, useMemo } from 'react';
import {
  Card, Col, DatePicker, Row, Empty, Space, Typography,
} from 'antd';
import { CheckCircleOutlined, UndoOutlined, CloseCircleOutlined } from '@ant-design/icons';
import WebServices from 'services/webServices';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { useParameteres } from 'contexts/ParameteresContext';
import { useFederations } from 'contexts/FederationsContext';
import { useLocalization } from 'contexts/LocalizationContext';
import '../../../assets/styles/home.css';
import moment from 'moment';

const { Text } = Typography;

export default function ScutchingActivityAdmin() {
  const { user } = useAuth();
  const { sId } = useParams();
  const loc = useLocalization().locObject;
  const parameters = useParameteres();
  const federationContext = useFederations();
  const services = useMemo(() => new WebServices({}, api, true, 'home', sId ?? user.company), []);
  const [scutchingActivitiesData, setScutchingActivitiesData] = useState([]);
  const [monthDate, setMonthDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const isSuperAdmin = user.roles.includes('ROLE_SUPER_ADMIN');
  const options = { year: 'numeric', month: 'long' };

  function fetchData(monthStart, fed) {
    services.getScutchingActivitiesHomePageDetails(monthStart, fed).then(({ data }) => setScutchingActivitiesData(data));
  }

  useEffect(() => {
    if (parameters.values.data) {
      const statDate = `${parameters.values.data.find((param) => param.name === 'statistics.date').value}-01`;
      const date = new Date(statDate).toLocaleString('fr-FR', options);
      setCurrentDate(date.charAt(0).toUpperCase() + date.slice(1));

      setMonthDate(`${parameters.values.data.find((param) => param.name === 'statistics.date').value}-01`);
    }
  }, [parameters]);

  useEffect(() => {
    if (monthDate && !isSuperAdmin) {
      fetchData(monthDate, federationContext.values.items[0].id);
    } else if (monthDate && isSuperAdmin) {
      fetchData(monthDate, null);
    }
  }, [monthDate, user]);

  function handleMonthDate(mmt) {
    const date = new Date(mmt).toLocaleString('fr-FR', options);

    setMonthDate(mmt.startOf('month').format('YYYY-MM-DD'));
    setCurrentDate(date.charAt(0).toUpperCase() + date.slice(1));
  }

  return (
    <Card
      size="small"
      style={{ border: 'none' }}
      title={(
        <Card
          type="primary"
          shape="default"
          className="hp-export-btn"
          style={{ backgroundColor: '#C6DBC8', borderColor: '#C6DBC8' }}
        />
      )}
      headStyle={{ backgroundColor: 'var(--light-secondary)' }}
    >
      <Space direction="vertical" className="w-100" size="small">
        <Space direction="vertical" className="w-100">
          <Row justify="center">
            <Col span={22}>
              <Card bordered={false}>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div style={{ display: 'flex', alignContent: 'center' }}>
                    <CheckCircleOutlined style={{ fontSize: '150%', color: '#00b000' }} />
                    <Text style={{ marginLeft: '5px' }}>{loc.translate('home_scutching_activities_closed_month')}</Text>
                  </div>
                  <div style={{ display: 'flex', alignContent: 'center' }}>
                    <UndoOutlined style={{ fontSize: '150%', color: '#b08700' }} />
                    <Text style={{ marginLeft: '5px' }}>{loc.translate('home_scutching_activities_month_with_activities')}</Text>
                  </div>
                  <div style={{ display: 'flex', alignContent: 'center' }}>
                    <CloseCircleOutlined style={{ fontSize: '150%', color: '#b00000' }} />
                    <Text style={{ marginLeft: '5px' }}>{loc.translate('home_scutching_activities_month_without_activities')}</Text>
                  </div>
                </div>
              </Card>
              <Card size="small" className="tool-bar">
                <Space direction="horizontal" className="p-5 tool-bar" size="large">
                  <Text strong>{loc.translate('home_scutching_activities_month')}</Text>
                  {monthDate !== null ? (<DatePicker picker="month" onChange={handleMonthDate} defaultValue={moment(monthDate)} allowClear={false} dateFormat="MM/dd/yyyy" />) : ('')}
                </Space>
              </Card>
              <div className="w-100">
                <Row className="w-100 stats-table-thead">
                  <Col className="stats-table-td-headers scutching-activities-left" span={12} key="companyName">{loc.translate('home_company_name_title')}</Col>
                  <Col className="stats-table-td-headers scutching-activities-left" span={12} key="monthState">{currentDate}</Col>
                </Row>
                {scutchingActivitiesData != null ? (
                  <>
                    {scutchingActivitiesData.map((e) => (
                      <Row className="stats-table-tr">
                        <Col className="stats-table-td" span={12} key={e.id}>{e.id}</Col>
                        <Col className="stats-table-td" span={12} key={e.activity_type}>
                          {e.activity_type === 2 ? (
                            <div style={{ width: '100px', display: 'flex', justifyContent: 'space-around' }}>
                              <CheckCircleOutlined style={{ fontSize: '150%', color: '#00b000' }} />
                              <UndoOutlined style={{ fontSize: '150%', color: '#b0b0b0' }} />
                              <CloseCircleOutlined style={{ fontSize: '150%', color: '#b0b0b0' }} />
                            </div>
                          ) : ('')}
                          {e.activity_type === 1 ? (
                            <div style={{ width: '100px', display: 'flex', justifyContent: 'space-around' }}>
                              <CheckCircleOutlined style={{ fontSize: '150%', color: '#b0b0b0' }} />
                              <UndoOutlined style={{ fontSize: '150%', color: '#b08700' }} />
                              <CloseCircleOutlined style={{ fontSize: '150%', color: '#b0b0b0' }} />
                            </div>
                          ) : ('')}
                          {e.activity_type === 0 ? (
                            <div style={{ width: '100px', display: 'flex', justifyContent: 'space-around' }}>
                              <CheckCircleOutlined style={{ fontSize: '150%', color: '#b0b0b0' }} />
                              <UndoOutlined style={{ fontSize: '150%', color: '#b0b0b0' }} />
                              <CloseCircleOutlined style={{ fontSize: '150%', color: '#b00000' }} />
                            </div>
                          ) : ('')}
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      padding: '5vw',
                    }}
                  >
                    <Col span={24}>
                      <Empty description={loc.translate('noData')} imageStyle={{ height: '15vw' }} />
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Space>
      </Space>
    </Card>
  );
}
