import { useEffect, useRef, useState } from 'react';
import {
  Form, Button, Input, Select, Row, Col, Space, Alert, DatePicker, InputNumber, Typography,
} from 'antd';
import { getValue } from 'components/formatters/ObjectFormatter';
import { useLocalization } from 'contexts/LocalizationContext';
import { useProductTypes } from 'contexts/ProductTypesContext';
import { CSSTransition } from 'react-transition-group';
import { CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import checkNumber from 'components/validators/positifNumber';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useProductIntervals } from 'contexts/ProductIntervals';
import { checkAdmin } from 'components/RequireAdmin';
import { useParameteres } from 'contexts/ParameteresContext';
import { useAuth } from 'contexts/AuthContext';
import DropDownLazy from 'components/editors/DropDownLazy';
import { formatString } from '../../services/shared';

const { Text } = Typography;
function NewSellForm(props) {
  const {
    type, company, dataSource, onSubmitData, accounts, form, handleNewDataHidden,
  } = props;

  // states

  const [userHasAccount, setUserHasAccount] = useState(true);
  const [account, setAccount] = useState({ balance: 0, productID: '', productName: '' });
  const [latestExchangeRate, setLatestExchangeRate] = useState({ price: 0, quote: '$' });
  const [regionId, setRegionId] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [productIntervals, setProductIntervals] = useState();
  const [alert, setAlert] = useState({ show: false, msg: '', type: 'error' });
  const [lastOperationDate, setLastOperationDate] = useState(null);
  const [lastDateData, setLastDateData] = useState(null);
  const [showInformationMessage, setshowInformationMessage] = useState({ visible: false, type: '', msg: '' });
  const [newDate, setNewDate] = useState({ dt: undefined, dateString: undefined });
  const [monthAlreadyClose, setMonthAlreadyClose] = useState(false);
  // Contexts
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  const productTypesContext = useProductTypes();
  const productIntervalsContext = useProductIntervals();
  const parameters = useParameteres();
  const { user } = useAuth();
  const nav = useNavigate();
  // there should be at least one fiber stock to begin a second transformation

  function getLastDateCloseMonth(operationType) {
    const response = dataSource.getLastDateCloseMonth(dataSource.company, operationType);
    response.then(({ data }) => {
      setLastDateData(data);
    });
  }

  useEffect(() => {
    getLastDateCloseMonth('CLOSE');
    async function getLastOperationDate() {
      const response = await dataSource.getLastDateTypeOperation(company);
      const { data } = response;
      setLastOperationDate(moment(data.lastDate));
    }
    getLastOperationDate();
  }, [user.id]);

  useEffect(() => {
    if (accounts.isLoading !== true) {
      if (accounts.values.length <= 0) {
        setUserHasAccount(false);
        setAlert({ show: true, msg: locObject.translate('transformation_noProducts_err'), type: 'warning' });
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (newDate && (lastDateData !== null && lastDateData.length !== 0)) {
      const currentDate = newDate.dt.format('YYYY-MM');
      const dtLastCloseDate = moment(new Date(lastDateData[0].date), 'YYYY-MM');
      if (currentDate <= dtLastCloseDate.format('YYYY-MM')) {
        setshowInformationMessage({ visible: true, type: 'error', msg: locObject.translate('month_alread_close_sales') });
        setMonthAlreadyClose(true);
      } else {
        setshowInformationMessage({ visible: false });
      }
    }
  }, [newDate]);

  function refreshExchangeRate(dt, dateString) {
    setNewDate({ dt, dateString });
    setMonthAlreadyClose(false);
    const response = dataSource.getExchangeRateByDate(
      dt?.format('YYYY-MM-DD') ?? moment().format('YYYY-MM-DD'),
    );
    response.then((resp) => {
      setLatestExchangeRate(resp.data);
      form.setFieldsValue({ date: dt });
    });
  }

  useEffect(() => {
    refreshExchangeRate();
  }, []);

  function onChooseProduct(val, element) {
    setUserHasAccount(false);
    setRegionId(element.id);
    const response = dataSource.getAccountBalance(element.key);
    response.then(({ data }) => {
      if (data.balance.amount <= 0) {
        setUserHasAccount(false);
        setAlert({ show: true, msg: locObject.translate('transformation_noSold_err'), type: 'warning' });
      } else {
        setUserHasAccount(true);
        setAlert({ show: false });
        setAccount({
          balance: data.balance.amount, productID: element.product, productName: element.accnm, year: element.year,
        });
        const productTypeId = productTypesContext.values.find((p) => p.enumType === element.enm).id ?? false;
        const productPricesIntervals = productIntervalsContext.values.find((p) => p.id === productTypeId);
        const [int1, int2] = productPricesIntervals?.price ?? [0, 0];
        setProductIntervals({ min: Number(int1), max: Number(int2) });
      }
    });
  }

  function handleCancelModifications() {
    form.resetFields(['productType', 'client', 'amount', 'price', 'perKg']);
    handleNewDataHidden(true);
  }
  function validatePrice(pr) {
    const stock = form.getFieldValue('amount');
    setAlert({ show: false });
    if (stock === '' || !stock) {
      setAlert({ show: true, msg: locObject.translate('sales_quantity_mandatory_err'), type: 'error' });
      return true;
    }
    if (!pr || pr === '') {
      return true;
    }
    if (!productIntervals) {
      setAlert({ show: true, msg: locObject.translate('sales_newDeclaration_entryProduct_err'), type: 'error' });
    }
    if (pr > productIntervals.max || Number(pr) < productIntervals.min) {
      return locObject.translateWithReplace(
        'sales_price_range_warrning',
        {
          placeholder: 'x1',
          value: account.productName,
        },
        {
          placeholder: 'x2',
          value: productIntervals.min,
        },
        {
          placeholder: 'x3',
          value: productIntervals.max,
        },
      );
    }
    return true;
  }
  function refreshPrice(pr, amount) {
    const price = form.getFieldValue(['price']);
    const perKg = (pr ?? price) / amount;
    if (pr && pr !== '' && amount !== '' && amount) {
      form.setFieldsValue({ perKg: perKg.toFixed(1) });
      form.validateFields(['perKg']);
    } else {
      form.validateFields(['perKg']);
    }
  }
  function handleChangeQuantity(e) {
    form.setFieldsValue({ price: '' });
    form.setFieldsValue({ perKg: '' });
    if (e.target.value !== '' && e.target.value) {
      const amount = Number(e.target.value);
      refreshPrice(null, amount);
    }
  }

  function checkAllowedDates(current) {
    if (checkAdmin(user) === true) {
      return false;
    }
    if (current) {
      // transformation allowed month
      const m = Number(parameters.values.data?.find((param) => param.name === 'transformation.month')?.value);

      // allowed months range

      const todayDate = moment();
      const allowedDates = moment().subtract(m, 'month').startOf('month');
      if (!current.startOf('month').isBetween(allowedDates, todayDate)) {
        return true;
      }
      return false;
    }
    return false;
  }
  function calculateTotalSales(e) {
    const value = Number(e.target.value);
    const stock = Number(form.getFieldValue('amount'));
    const totalSales = Number(value) * stock;
    form.setFieldsValue({ price: totalSales.toFixed(1) });
  }
  function calculatePrice(e) {
    if (e.target.value === '') {
      form.setFieldsValue({ perKg: '' });
    } else {
      const pr = Number(e.target.value);
      const stock = Number(form.getFieldValue('amount'));
      refreshPrice(pr, stock);
    }
  }
  function shouldUpdate(prevValues, nextValues) {
    if (isSubmitted && nextValues.date !== prevValues.date) {
      form.setFieldsValue({ date: lastOperationDate });
      setIsSubmitted(false);
    }
    return true;
  }
  function calculatePriceInDollar() {
    const pr = form.getFieldValue('price');
    const amount = form.getFieldValue('amount');
    if (!pr || pr === '' || !amount || !latestExchangeRate || latestExchangeRate === 0) {
      return '';
    }
    return Number(pr) * Number(latestExchangeRate.price);
  }
  function onValidate(values) {
    setIsSubmitted(true);

    const {
      date, amount, client, price,
    } = values;
    setAlert({ show: false });
    const am = Number(amount);
    // In Stock (Straw)
    const inStock = {
      accountRegion: regionId,
      accountProduct: account.productID,
      accountYear: account.year,
      amount: -am,
      surface: 0,
      price,
    };
    // if (account.quality) {
    //   inStock.accountQuality = account.quality;
    // }

    const newTransformation = {
      date: date.format('YYYY-MM-DD'),
      type: type.id,
      movements: [inStock],
      company,
      client,
    };
    const response = onSubmitData(newTransformation);
    response.then((status) => {
      setIsSubmitted(false);
      // eslint-disable-next-line react/prop-types
      form.resetFields(['productType', 'client', 'amount', 'price', 'perKg']);
      setLastOperationDate(date);
    }).catch((err) => {
      setIsSubmitted(false);
    });
    return response;
  }
  // form.setFieldsValue({ date: lastOperationDate ?? lastOperationDate });

  return (
    <CSSTransition
      in
      timeout={500}
      appear
      classNames="form"
      unmountOnExit
    >
      <div>
        {alert.show === true && (
        <Alert
          type={alert.type}
          message={alert.msg}
          closable
        />
        )}
        <Form
          onFinish={onValidate}
          layout="vertical"
          colon={false}
          form={form}
          shouldUpdate={shouldUpdate}
          initialValues={{
            date: lastOperationDate ? lastOperationDate.format('YYYY-MM-DD') : moment(),
          }}
          onValuesChange={(changedValues, allValues) => {
            if ('date' in changedValues) {
              setLastOperationDate(changedValues.date);
            }
          }}
        >
          { showInformationMessage.visible === true && (
            <Alert
              style={{ marginTop: 10 }}
              message={showInformationMessage.msg}
              type={showInformationMessage.type !== '' ? showInformationMessage.type : 'info'}
              closable
              onClose={() => setshowInformationMessage({ visible: false })}
            />
          )}
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Form.Item
                name="date"
                valuePropName="value"
                label={<Text strong>{locObject.translate('date')}</Text>}
                required
                rules={[{
                  required: true,
                  message: locObject.translate('transformation_date_mandetory_err'),
                }]}
              >
                <DatePicker.MonthPicker
                  showTime={false}
                  allowClear
                  onChange={refreshExchangeRate}
                  disabledDate={checkAllowedDates}
                  showToday={false}
                  format="MMM YYYY"
                />
              </Form.Item>
            </Col>

            <Col sm={24} md={10}>
              <Form.Item
                label={<Text strong>{locObject.translate('product')}</Text>}
                labelAlign="left"
                name="productType"
                required
                rules={[{
                  required: true,
                  message: locObject.translate('sales_newDeclaration_entryProduct_err'),
                }]}
              >
                <Select
                  placeholder={locObject.translate('sales_newDeclaration_entryProduct_ph')}
                  loading={accounts.isLoading === true}
                  onChange={onChooseProduct}
                >
                  {accounts.values !== null ? accounts.values.map((acc) => (
                    <Select.Option key={acc.account.id} id={acc.account.region.id} product={acc.account.product.id} enm={acc.account.product.enumType} accnm={acc.account.product.translation[lang]} year={acc.account.year} quality={acc.account.quality?.id}>
                      <Space size="small">
                        {formatString(getValue(acc.account.product, lang), acc.account.region.country.translation[lang], acc.account.year, acc.balance.amount)}
                      </Space>
                    </Select.Option>
                  )) : ''}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={10}>
              <DropDownLazy
                disabled={!userHasAccount}
                isFormItem
                formItemName="client"
                title={locObject.translate('sales_newDeclaration_client_title')}
                placeholder={locObject.translate('sales_newDeclaration_client_title')}
                url="/client/"
                showEdit={{ visible: true, adminNavUrl: 'declare/sells/clients', userNavUrl: '/manage-clients' }}
                sorts={{ col: 'companyName', sense: 'ASC' }}

              />
            </Col>
          </Row>
          <Row gutter={[20, 10]}>
            <Col sm={24} md={4}>
              <Form.Item
                label={<Text strong>{locObject.translate('sales_newDeclaration_quantity_title')}</Text>}
                required
                name="amount"
                rules={[{
                  required: true,
                  validator: (_, value) => {
                    if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                    if (value > account.balance) {
                      return Promise.reject(new Error(locObject.translate('transformation_quantityfield_limits_err')));
                    }
                    return Promise.resolve();
                  },
                }]}
              >
                <Input
                  suffix="kg"
                  onChange={handleChangeQuantity}
                  disabled={account.balance === 0}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={6}>
              <Form.Item
                label={<Text strong>{locObject.translate('sales_newDeclaration_totalsales_title')}</Text>}
                name="price"
                required
                rules={[{ required: true, message: locObject.translate('fieldMandetoryError') }, {
                  required: true,
                  validator: (_, value) => {
                    if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                    return Promise.resolve();
                  },
                }]}

              >
                <Input
                  suffix="€"
                  disabled={account.balance === 0}
                  onChange={calculatePrice}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item
                name="perKg"
                label="€ / kg"
                rules={[{
                  required: true,
                  validator: (_, value) => {
                    if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                    return Promise.resolve();
                  },
                }, {
                  warningOnly: true,
                  validator: (_, value) => {
                    const valid = validatePrice(value);
                    return valid !== true ? Promise.reject(new Error(valid)) : Promise.resolve();
                  },
                },
                ]}
              >
                <Input
                  onChange={calculateTotalSales}
                  suffix="€/kg"
                  disabled={account.balance === 0}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Form.Item dependencies={['price']}>
                {() => (
                  <Form.Item label="CA USD">
                    <InputNumber
                      decimalSeparator=","
                      addonAfter="USD"
                      precision={2}
                      value={calculatePriceInDollar()}
                      readOnly
                      disabled={!userHasAccount}

                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col sm={24} md={{ span: 4 }}>
              <Form.Item label="1 € =">
                <InputNumber
                  value={latestExchangeRate?.price ?? 0}
                  decimalSeparator=","
                  addonAfter={latestExchangeRate?.quote ?? '$'}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={[10, 0]}>
            <Col>
              <Button
                icon={<CloseCircleFilled />}
                onClick={handleCancelModifications}
                danger
              >
                {locObject.translate('cancel')}

              </Button>
            </Col>
            <Col>
              <Button
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={isSubmitted}
                disabled={isSubmitted || monthAlreadyClose}
              >
                {locObject.translate('save')}

              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </CSSTransition>
  );
}
export default NewSellForm;
