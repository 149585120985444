import blnlFlag from 'assets/images/be_nl.png';
import FrFlagsIcon from 'assets/images/FrFlagsIcon';
import { Select, Image } from 'antd';
import { useLocalization } from 'contexts/LocalizationContext';

function LanguageMenu(props) {
  const { onLanguageChange, selectedLang } = props;
  const defaultLang = useLocalization().currentLang.toLowerCase();
  return (
    <Select
      key="lang-select"
      defaultValue={{ value: defaultLang }}
      size="large"
      style={{ display: 'flex' }}
      bordered={false}
      className="d-100"
      onChange={onLanguageChange}
    >
      <Select.Option value="fr">
        <FrFlagsIcon />
      </Select.Option>
      <Select.Option value="nl">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Image
            src={blnlFlag}
            preview={false}
            style={{
              height: '2.2em', width: '2.5em', objectFit: 'contain',
            }}
          />
        </div>
      </Select.Option>
    </Select>
  );
}

export default LanguageMenu;
