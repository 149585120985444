/* eslint-disable radix */
import { useEffect, useState } from 'react';
import {
  Form, Button, Input, Select, Row, Col, Space, Typography, Alert, Tag,
} from 'antd';
import { useLocalization } from 'contexts/LocalizationContext';
import { CSSTransition } from 'react-transition-group';
import { CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import checkNum from 'components/validators/number';
import { useProductTypes } from 'contexts/ProductTypesContext';
import { useParameteres } from 'contexts/ParameteresContext';
import { useClientRegions } from 'contexts/ClientRegionsContext';
import { useProductIntervals } from 'contexts/ProductIntervals';
import _ from 'lodash';
import intlNumberFormater from '../formatters/intlNumberFormater';

const { Text } = Typography;
let intervals;
function CorrectionForm(props) {
  const {
    type, company, date, dataSource, dispatch, onSubmitData, accounts, productsIds, onDataReceived, balances, onSubmit, monthAlreadyClose,
  } = props;
  // states
  const [surfaceChoice, setSurfaceChoice] = useState(0);
  const [surfaceAvailable, setSurfaceAvailable] = useState(0);
  const [regionId, setRegionId] = useState('');
  const [alert, setAlert] = useState({ show: false, msg: '', type: 'error' });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [regions, setRegions] = useState(null);
  // parameters.harvestYear - 5
  const [harvestYears, setHarvestYears] = useState(null);
  // Contexts
  const form = Form.useForm(null)[0];
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  const productTypes = useProductTypes();
  const parameters = useParameteres();
  const regionsContext = useClientRegions();
  const productIntervals = useProductIntervals();
  function handleChangeSurface(e) {
    setSurfaceChoice(Number(e.target.value));
  }
  useEffect(() => {
    if (
      parameters.isLoading === false
      && parameters.values.data
      && regionsContext.isLoading === false
      && regionsContext.values
    ) {
      // get harvest year
      const currentYear = new Date().getFullYear();
      // generate list y -5
      const generatedList = Array.from({ length: 8 }, (v, i) => currentYear - i);
      setHarvestYears(generatedList);

      const cRegions = regionsContext.values.filter(
        (reg) => reg.name === 'FR' || reg.name === 'BE' || reg.name === 'NL',
      );
      setRegions(cRegions);
    }
  }, [parameters.values.data, regionsContext.values]);

  function onValidate(outStock) {
    const {
      quantity, surface, year, country, product,
    } = outStock;
    if (date === 0 || !date) {
      return setAlert({
        show: true,
        msg: locObject.translate('transformation_date_mandetory_err'),
        type: 'error',
      });
    }
    // everything is Ok submitt the form
    setIsSubmitted(true);
    setAlert({ show: false });
    onDataReceived(false);
    const inStock = {
      accountRegion: country,
      accountProduct: productTypes.values.find((pt) => pt.enumType === product).id,
      accountYear: year,
      amount: parseFloat(quantity ?? 0),
      surface: surface ?? 0,
    };

    const totalMovements = [inStock];

    const newTransformation = {
      date: date.format('YYYY-MM-DD'),
      type,
      movements: totalMovements,
      company,
    };
    const response = onSubmitData(newTransformation);
    response
      .then(() => {
        dispatch({ type: 'non' });
        onSubmit(date);
        setIsSubmitted(false);
      })
      .catch(() => {
        setIsSubmitted(false);
      });
    return response;
  }
  function changeBalance(func, product) {
    const strawSum = balances !== null ? balances?.filter((st) => st.product_type === 'FLAX_STRAW') : { balance: 0 };
    // eslint-disable-next-line no-shadow
    const surfaceOfProduct = (product) => _.sumBy(product, (item) => Number(item.surface));
    setSurfaceAvailable(surfaceOfProduct(strawSum));
    if (productTypes.values.length > 0 && product.enumType === 'FLAX_STRAW') {
      const totalIntervals = productIntervals.values.find((p) => p.id === product.id);
      intervals = {
        min: Number(totalIntervals.quantity[0]),
        max: Number(totalIntervals.quantity[1]),
      };
    }
    const year = func('year');
    const country = func('country');
    const account = accounts?.values?.find(
      (acc) => acc.account.product.id === product.id
        && acc.account.year === year
        && acc.account.region.id === country,
    );
    if (!account) {
      return undefined;
    }
    return (
      <Space size="small">
        {product.translation[lang]}
        {account.balance.amount > 0 && (
        <Tag>
          {intlNumberFormater(account.balance.amount, false)}
          {' '}
          Kg
        </Tag>
        )}
        {account.balance.surface > 0 && (
        <Tag>
          {account.balance.surface}
          {' '}
          Ha
        </Tag>
        )}
      </Space>
    );
  }

  function validateFields(changedFields) {
    if (changedFields.find((f) => f.name[0] === 'quantity')) {
      form.validateFields(['surface']);
    }
  }
  return (
    <CSSTransition in timeout={500} appear classNames="form" unmountOnExit>
      <div>
        {alert.show && <Alert type={alert.type} message={alert.msg} />}
        <Form
          onFinish={onValidate}
          layout="vertical"
          colon={false}
          form={form}
          onFieldsChange={validateFields}
        >
          <Row gutter={[10, 10]}>
            <Col sm={24} md={4}>
              <Form.Item
                label={(
                  <Text strong>
                    {locObject.translate('transformation_correction_harvestYear_title')}
                  </Text>
                )}
                required
                name="year"
              >
                <Select
                  placeholder={locObject.translate('transformation_correction_entryProduct_ph')}
                >
                  {harvestYears !== null
                    ? harvestYears.map((year) => (
                      <Select.Option value={year} key={year}>
                        {year}
                      </Select.Option>
                    ))
                    : ''}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={5}>
              <Form.Item
                label={(
                  <Text strong>
                    {locObject.translate('transformation_correction_country_title')}
                  </Text>
                )}
                required
                name="country"
              >
                <Select
                  placeholder={locObject.translate('transformation_correction_entryProduct_ph')}
                >
                  {regions !== null
                    ? regions.map((region) => (
                      <Select.Option value={region.id} key={region.id}>
                        {region.country.translation[lang]}
                      </Select.Option>
                    ))
                    : ''}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={15}>
              <Form.Item
                shouldUpdate={(prev, curr) => prev.year !== curr.year || prev.country !== curr.country}
                noStyle
              >
                {({ getFieldValue, setFieldsValue }) => (
                  <Form.Item
                    label={(
                      <Text strong>
                        {locObject.translate('transformation_correction_entryProduct_title')}
                      </Text>
                    )}
                    required
                    name="product"
                    wrapperCol={{ span: 13 }}
                  >
                    <Select
                      placeholder={locObject.translate('transformation_correction_entryProduct_ph')}
                    >
                      {productTypes.values !== null
                        ? productTypes.values.map((product) => (
                          <Select.Option
                            value={product.enumType}
                            key={product.id}
                            id={product.id}
                            prod={product.id}
                          >
                            {changeBalance(getFieldValue, product, setFieldsValue)
                                ?? product.translation[lang]}
                          </Select.Option>
                        ))
                        : ''}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col sm={24} md={3}>
              <Form.Item
                label={(
                  <Text strong>
                    {locObject.translate('transformation_correction_quantity_title')}
                  </Text>
                )}
                name="quantity"
                labelAlign="left"
                dependencies={['surface', 'product']}
                required
                rules={[
                  ({ getFieldValue }) => ({
                    required: true,
                    warningOnly: true,
                    validator: (a, value) => {
                      if (
                        !value
                        || value === ''
                        || getFieldValue('surface') === ''
                        || !getFieldValue('surface')
                        || getFieldValue('product') !== 'FLAX_STRAW'
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input suffix="kg" />
              </Form.Item>
            </Col>
            <Col sm={24} md={3}>
              <Form.Item
                shouldUpdate={(prev, curr) => prev.product !== curr.product}
              >
                {({ getFieldValue }) => (getFieldValue('product') === 'FLAX_STRAW' ? (
                  <Form.Item
                    label={(
                      <Text strong>
                        {locObject.translate('transformation_correction_surface_title')}
                      </Text>
                      )}
                    name="surface"
                    labelAlign="left"
                    required
                    rules={[
                      {
                        required: true,
                        validator: (a, value) => {
                          if (!value || value === '') {
                            return Promise.resolve();
                          }
                          if (
                            Number(value) + surfaceAvailable < 0
                          ) {
                            return Promise.reject(
                              new Error(
                                locObject.translate('transformation_surface_limit_err'),
                              ),
                            );
                          }
                          if (
                            (Number(value) < 0 && Number(getFieldValue('quantity') > 0))
                          ) {
                            return Promise.reject(
                              new Error(
                                locObject.translate('transformation_surface_negative_err'),
                              ),
                            );
                          }
                          if (
                            (Number(value) > 0 && Number(getFieldValue('quantity') < 0))
                          ) {
                            return Promise.reject(
                              new Error(
                                locObject.translate('transformation_surface_positive_err'),
                              ),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input suffix="Ha" onChange={handleChangeSurface} value={surfaceChoice} />
                  </Form.Item>
                ) : (
                  ''
                ))}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={[10, 0]}>
            <Col>
              <Button icon={<CloseCircleFilled />} onClick={() => dispatch({ type: 'non' })} danger>
                {locObject.translate('cancel')}
              </Button>
            </Col>
            <Col>
              <Button
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={isSubmitted}
                disabled={isSubmitted || monthAlreadyClose}
              >
                {locObject.translate('save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </CSSTransition>
  );
}
export default CorrectionForm;
