import {
  createContext, useContext, useReducer, useEffect,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { hideIfNotAdmin } from 'services/shared';
import userEvent from '@testing-library/user-event';
import { useParams } from 'react-router-dom';
import { useUserContext } from './UserContext';
import { useAuth } from './AuthContext';

const ClientsContext = createContext([]);

const initialState = {
  values: [],
  isLoading: false,
  isError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fetch_start':
      return {
        ...state,
        isLoading: true,
      };
    case 'fetch_success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        values: action.payload,
      };
    case 'fetch_error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

function ClientsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();
  const { sId } = useParams();
  useEffect(() => {
    async function fetchClients() {
      dispatch({ type: 'fetch_start' });
      const assignedCompany = sId ?? user.company;

      try {
        const result = await api.get(`/client/?company=${assignedCompany}`);
        dispatch({ type: 'fetch_success', payload: result.data.items });
      } catch (error) {
        dispatch({ type: 'fetch_error' });
      }
    }
    fetchClients();
  }, []);

  return (
    <ClientsContext.Provider value={state}>
      {children}
    </ClientsContext.Provider>
  );
}

ClientsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useClients() {
  const value = useContext(ClientsContext);

  if (isUndefined(value)) {
    throw new Error('useClients must be used inside an ClientsProvider component');
  }

  return value;
}

export {
  ClientsProvider, useClients,
};
