import { useState, useMemo, useEffect } from 'react';
import DataTable from 'components/DataTable';
import api from 'services/api';
import {
  Card, Row, Button, Col, Alert, Space,
} from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import DeleteButton from 'components/editors/DeleteButton';
import TextEditor from 'components/editors/TextEditor';
import { ObjectFormatter } from 'components/formatters/ObjectFormatter';
import { useAuth } from 'contexts/AuthContext';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import { useNavigate, useParams } from 'react-router-dom';
import Localization from 'services/localizationService';
import RolesDropDown from 'components/editors/RolesDropDown';
import { hideIfNotAdmin } from 'services/shared';
import WebServices from 'services/webServices';
import { useLocalization } from 'contexts/LocalizationContext';

function rolesList() {
  const allRoles = [
    { id: 2, key: 'ROLE_MULTISCUTCHING_ADMIN', name: 'Multi-teillages' },
    { id: 3, key: 'ROLE_DATA_ENTERING_USER', name: 'Saisie + consultation' },
    { id: 4, key: 'ROLE_REPORTING_USER', name: 'Consultation seule' },
  ];
  return { values: allRoles };
}
const columns = (locObject) => [
  {
    id: 'lastName',
    name: 'Nom',
    translationKey: 'mangeUsers_table_lastName',
    editor: TextEditor,
    sortable: false,
    editable: true,
    filterable: true,

  },
  {
    id: 'firstName',
    name: 'Prénom',
    translationKey: 'mangeUsers_table_firstName',
    editor: TextEditor,
    sortable: false,
    editable: true,
    filterable: true,

  },
  {
    id: 'company',
    name: 'Teillage',
    translationKey: 'mangeUsers_table_scutching',
    editor: TextEditor,
    sortable: false,
    editable: false,
    style: { width: '600px' },
    requiredObjectIndex: 'companyName',
    formatter: ObjectFormatter,
    filterable: true,

  },
  {
    id: 'roles',
    name: 'Droits',
    translationKey: 'mangeUsers_table_role',
    editor: RolesDropDown,
    prefetchContext: rolesList,
    sortable: false,
    editable: true,
    filterable: true,
    formatter: (v1, v2, value) => locObject.translate(value),
  },
  {
    id: 'email',
    name: 'Email',
    translationKey: 'mangeUsers_table_email',
    editor: TextEditor,
    sortable: false,
    editable: true,
    filterable: true,

  },
  {
    id: 'delete',
    name: '',
    editor: DeleteButton,
    sortable: false,
  },

];

const crud = {
  get: '/users/',
  update: '/users',
  bulkDelete: '',
  delete: '/users',
  create: '/users',
};

function MultiscutchingUserCompanyManagement() {
  // Contexts
  const nav = useNavigate();
  const { user } = useAuth();
  const { sId } = useParams();
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  const dataSource = useMemo(() => new WebServices(crud, api, true, 'users-manage', sId ?? user.company), []);
  const notify = useNotificationCenter()();
  // states
  const [showErrorMessege, setShowErrorMessege] = useState({ visible: false, msg: '' });
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState({ total: 0, perPage: 0 });
  const [sorts, setSorts] = useState(null);

  const isAdminLink = !user.roles.includes('ROLE_MULTISCUTCHING_ADMIN') ? '' : '/admin';
  function checkRoles(usr) {
    if (usr.id === user.id) {
      return false;
    }
    if (user.roles.includes('ROLE_SUPER_ADMIN')) {
      return true;
    }
    if (user.roles.includes('ROLE_FED_ADMIN')) {
      return !usr.roles.includes('ROLE_SUPER_ADMIN');
    }
    if (user.roles.includes('ROLE_MULTISCUTCHING_ADMIN')) {
      return !usr.roles.includes('ROLE_FED_ADMIN');
    }
    if (user.roles.includes('ROLE_DATA_ENTERING_USER')) {
      return usr.roles.includes('ROLE_REPORTING_USER');
    }
    return false;
  }
  async function fetchData() {
    try {
      if (dataSource.company !== null) { dataSource.company = null; }
      const response = await dataSource.getUsersByCompanyId(sId);
      const dt = response.data.items.filter((usr) => checkRoles(usr));
      const promises = dt.map(async (e) => {
        if (e.roles.includes('ROLE_MULTISCUTCHING_ADMIN')) {
          const res = await dataSource.getMultiscutchingCompanyName(e.id);
          if (e.company !== null) {
            e.company.companyName = res.data.join(', ');
          }
        }
      });
      await Promise.all(promises);
      setData(dt);
      setPagination({ total: response.data.totalItemCount, perPage: response.data.totalItemCount });
      return response.data.items;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  function handleCommit(values) {
    const {
      id, email, firstName, jobTitle, language, lastName, roles,
    } = values;
    const row = {
      email, firstName, jobTitle, language, lastName,
    };

    if (hideIfNotAdmin(user) === false) {
      row.roles = [rolesList().values.find((r) => r.id === roles || r.key === (Array.isArray(roles) ? roles[0] : roles)).key];
    }
    const response = dataSource.update(id, row);
    const accepted = new Promise((resolve, reject) => {
      response.then((res) => {
        if (res === true) {
          const resp = fetchData(dataSource.currentPage);
          resp.then(() => {
            notify({ msg: locObject.translate('modifications_success_msg'), type: 'success', desc: '' });
            resolve();
          });
        } else {
          setShowErrorMessege({ msg: `${locObject.translate('modifications_failed_msg')} ${res}`, visible: true });
          reject(id);
        }
      });
    });
    return accepted;
  }

  function handleDelete(rowId, company) {
    const response = dataSource.delete(rowId, company);
    response.then((res) => {
      if (res === true) {
        setData(data.filter((r) => r.id !== rowId));
        return notify({ msg: locObject.translate('modifications_success_msg'), type: 'success', desc: '' });
      }
      return setShowErrorMessege({ msg: `${locObject.translate('modifications_failed_msg')} ${res}`, visible: true });
    });
  }
  function handleBulkDelete(selectedRows) {
    const rowsIds = selectedRows.map((row) => row.id);
    const response = dataSource.bulkDelete({ lines: rowsIds, company: user.company });
    response.then((res) => {
      if (res === true) {
        fetchData(dataSource.currentPage);

        return notify({ msg: locObject.translate('modifications_success_msg'), type: 'success', desc: '' });
      }
      return setShowErrorMessege({ msg: `${locObject.translate('modifications_failed_msg')} ${res}`, visible: true });
    });
  }
  function sortTable(sort) {
    const response = dataSource.get(dataSource.currentPage, sort);
    response.then((d) => {
      setData(d);
      setSorts(dataSource.sorts);
    });
  }

  return (
    <Space size="large" direction="vertical" className="d-100 h-100">
      {/* <Row>
        <Col>
          <Button
            onClick={() => nav(`${isAdminLink}/parameters/new-user`)}
            type="default"
            shape="default"
          >
            <PlusCircleFilled />
            {locObject.translate('newUser_title')}
          </Button>
        </Col>
      </Row> */}
      <Card className="d-100 h-100">
        {showErrorMessege.visible === true && (
        <Alert message={showErrorMessege.msg} type="error" closable onClose={() => setShowErrorMessege({ visible: false })} />
        )}
        <DataTable
          columns={columns(locObject)}
          dataSource={data}
          fetchData={fetchData}
          handleCommit={handleCommit}
          handleDelete={handleDelete}
          handleBulkDelete={handleBulkDelete}
          sortTable={sortTable}
          paginationPerPage={pagination.perPage}
          paginationTotalResult={pagination.total}
          showBulkActions={false}
          isEditable
          isPaginatble
          removeSort={dataSource.removeSort}
          sorts={sorts}
          translateColumns
        />

      </Card>
    </Space>

  );
}

export default MultiscutchingUserCompanyManagement;
