/* eslint-disable radix */
import {
  Card,
} from 'antd';
import { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { useLocalization } from 'contexts/LocalizationContext';
import GeneralStatsAdmin from './homepages/admin/StockProductionStatsAdmin';
import SurfaceStatsAdmin from './homepages/admin/SurfaceStatsAdmin';
import ScutchingActivityAdmin from './homepages/admin/ScutchingActivityAdmin';
import ContributionAdmin from './homepages/admin/ContributionAdmin';
import '../assets/styles/home.css';

export default function HomeAdmin() {
  const { user } = useAuth();
  const loc = useLocalization().locObject;
  const [children, setChildren] = useState(<GeneralStatsAdmin />);
  const [isActive, setIsActive] = useState(1);

  return (
    <>
      <Card className="nav-stats">
        <button type="submit" className={isActive === 1 ? 'home-btn active-btn' : 'home-btn'} onClick={() => { setChildren(<GeneralStatsAdmin />); setIsActive(1); }}>{loc.translate('stocks_production_title')}</button>
        <button type="submit" className={isActive === 2 ? 'home-btn active-btn' : 'home-btn'} onClick={() => { setChildren(<SurfaceStatsAdmin />); setIsActive(2); }}>{loc.translate('surfaces_title')}</button>
        {user.roles.includes('ROLE_FED_ADMIN') ? (<button type="submit" className={isActive === 3 ? 'home-btn active-btn' : 'home-btn'} onClick={() => { setChildren(<ScutchingActivityAdmin />); setIsActive(3); }}>{loc.translate('scutching_activities_title')}</button>) : (null)}
        {user.roles.includes('ROLE_FED_ADMIN') && !user.roles.includes('ROLE_SUPER_ADMIN') ? (<button type="submit" className={isActive === 4 ? 'home-btn active-btn' : 'home-btn'} onClick={() => { setChildren(<ContributionAdmin />); setIsActive(4); }}>{loc.translate('contributions_title')}</button>) : (null)}
      </Card>
      {children}
    </>
  );
}
