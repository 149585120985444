import { Spin } from 'antd';
import isUndefined from 'lodash/isUndefined';
import React, {
  useContext, useState, useEffect, Suspense,
} from 'react';
import api from 'services/api';
import Localization from 'services/localizationService';
import { useAuth } from './AuthContext';

const LocalizationContext = React.createContext('Localization');

export default function LocalizationProvider({ children, coreLocFunction }) {
  const [updateLanguage, setUpdateLanguage] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [loc, setLoc] = useState({});

  async function fetchParameteres() {
    try {
      let lang;
      if (user) {
        const { data } = await api.get(`/users/${user.id}`);
        lang = updateLanguage?.toLowerCase() ?? data.language.toLowerCase();
      } else {
        lang = updateLanguage ?? 'fr';
      }
      coreLocFunction(lang);

      // Dynamic import to reduce the bundle size
      if (lang.toLowerCase() === 'fr') {
        const strFr = await import('locale/strings_fr');
        setLoc({ updateFunc: setUpdateLanguage, locObject: new Localization(lang, strFr), currentLang: lang });
      } else {
        const strNl = await import('locale/strings_nl');
        setLoc({ updateFunc: setUpdateLanguage, locObject: new Localization(lang, strNl), currentLang: lang });
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    setLoading(true);
    fetchParameteres().then(() => {
      setLoading(false);
    });
  }, [updateLanguage, user]);

  if (loading === true) {
    return (<Spin spinning />);
  }
  return (
    <LocalizationContext.Provider value={loc}>
      {children}
    </LocalizationContext.Provider>
  );
}

function useLocalization() {
  const locStrings = useContext(LocalizationContext);
  if (isUndefined(locStrings)) {
    throw new Error('useLocalization must be used inside an LocalizationProvider component');
  }
  return locStrings;
}

export { LocalizationProvider, useLocalization };
