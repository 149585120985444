import { CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import {
  Form, Input, Select, Row, Col, Button, Space, Alert, Typography,
} from 'antd';
import { useLocalization } from 'contexts/LocalizationContext';
import checkNumber from 'components/validators/positifNumber';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import { useProductIntervals } from 'contexts/ProductIntervals';
import { useProductTypes } from 'contexts/ProductTypesContext';
import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAuth } from 'contexts/AuthContext';
import intlNumberFormater from 'components/formatters/intlNumberFormater';

const { Option } = Select;
const { Text } = Typography;
let strawId = '';
let strawIntervals = '';

// fixed straw years list from 2018
function NewStraw(props) {
  const [form] = Form.useForm();
  const {
    date, type, company, onSubmitData, dataSource, dispatch, onSubmit, monthAlreadyClose,
  } = props;
  // Contexts
  const { user } = useAuth();
  const productTypes = useProductTypes();
  const productIntervals = useProductIntervals();
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`; // States
  const [regions, setRegions] = useState();
  const [availableCountries, setAvailableCountries] = useState();
  const [region, setRegion] = useState();
  const [availableRegions, setAvailableRegions] = useState();
  const [availableYears, setAvailableYears] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [regoinSearch, setRegionSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [accountBalace, setAccountBalance] = useState();
  const [alert, setAlert] = useState({ show: false, msg: '', type: 'error' });
  const [isSubmitted, setIsSubmitted] = useState(false);

  // get account balance

  useEffect(() => {
    if (productTypes.values.length > 0) {
      strawId = productTypes.values.find((p) => p.enumType === 'FLAX_STRAW').id ?? false;
      const strawTotalIntervals = productIntervals.values.find((p) => p.id === strawId);
      strawIntervals = {
        min: Number(strawTotalIntervals.quantity[0]),
        max: Number(strawTotalIntervals.quantity[1]),
      };
      const response = dataSource.getAlldeclared(null, company);
      response.then((resp) => {
        setAccountBalance(resp.data.totalCulture);
      });
      const resp = dataSource.getAvailableYears(user.company);
      resp.then((r) => {
        setAvailableYears(Object.values(r.data));
      });
    }
  }, []);

  function changeRegion(val, element) {
    setSelectedCountry(val);
    const regionId = dataSource.getClientRegion(element.value);
    regionId.then((resp) => {
      setRegion(resp.data[0].id);
    });
    setIsLoading(true);
    const selectedRegions = availableRegions.filter(
      (reg) => reg.country.id === (element?.cid ?? val),
    );
    setRegions(selectedRegions);
    setRegionSearch();
    setSelectedRegion();
    setIsLoading(false);
  }
  function handleAvailableYearChanged(val) {
    setRegion();
    setSelectedRegion();
    setSelectedCountry();
    setAvailableCountries();
    const countriesResp = dataSource.getStrawCountries(val);
    const regionsResp = dataSource.getStrawRegions(val);
    countriesResp.then((resp) => {
      setAvailableCountries(resp.data);
    });
    regionsResp.then((resp) => {
      setAvailableRegions(resp.data);
    });
  }
  function onValidate(values) {
    setIsSubmitted(true);
    if (date === 0) {
      setIsSubmitted(false);
      return setAlert({ show: true, msg: locObject.translate('transformation_date_mandetory_err'), type: 'error' });
    }
    if (!selectedCountry) {
      setIsSubmitted(false);
      return setAlert({
        show: true,
        msg: locObject.translate('countryMandetoryError'),
        type: 'error',
      });
    }

    const newStrowObject = {
      date: date.format('YYYY-MM-DD'),
      type,
      movements: [
        {
          accountRegion: region,
          accountProduct: 1,
          accountYear: values.strawYear,
          amount: Number(values.amount),
          surface: Number(values.surface),
        },
      ],
      company,
    };

    const response = onSubmitData(newStrowObject);
    response
      .then((status) => {
        setAlert({ show: false });
        form.resetFields();
        setSelectedRegion();
        setIsSubmitted(false);
        dispatch({ type: 'non' });
        onSubmit(date);
      })
      .catch(() => {
        setIsSubmitted(false);
      });
    setIsLoading(false);
    return true;
  }
  return (
    <CSSTransition in timeout={500} classNames="form" appear unmountOnExit>
      <Space size="middle" direction="vertical" className="d-100">
        {alert.show && <Alert type={alert.type} message={alert.msg} />}
        <Form layout="vertical" onFinish={onValidate} name="0" form={form} labelWrap>
          <Row gutter={[10, 20]}>
            <Col sm={12} xl={6} xxl={4}>
              <Form.Item
                label={<Text strong>{locObject.translate('transformation_strawForm_volume')}</Text>}
                name="amount"
                required
                dependencies={['surface']}
                rules={[
                  {
                    require: true,
                    validator: (_, value) => {
                      if (checkNumber(value) !== true) {
                        return Promise.reject(new Error(checkNumber(value)));
                      }
                      return Promise.resolve();
                    },
                  },
                  ({ getFieldValue }) => ({
                    required: true,
                    warningOnly: true,
                    validator: (_, value) => {
                      if (
                        !value
                        || value === ''
                        || getFieldValue('surface') === ''
                        || !getFieldValue('surface')
                      ) {
                        return Promise.resolve();
                      }
                      if (
                        Number(value) / getFieldValue('surface') < Number(strawIntervals.min)
                        || Number(value) / getFieldValue('surface') > Number(strawIntervals.max)
                      ) {
                        return Promise.reject(
                          new Error(
                            `NB : Une valeur normale est comprise entre ${intlNumberFormater(
                              strawIntervals.min,
                              false,
                            )} et ${intlNumberFormater(strawIntervals.max, false)} kg/ha`,
                          ),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input suffix="kg" />
              </Form.Item>
            </Col>
            <Col sm={12} xl={6} xxl={4}>
              <Form.Item
                name="surface"
                label={(
                  <Text strong>
                    {locObject.translate('transformation_firstTransformation_surfaceField_label')}
                  </Text>
                )}
                dependencies={['amount']}
                required
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (checkNumber(value) !== true) {
                        return Promise.reject(new Error(checkNumber(value)));
                      }

                      if (!value || value === '') {
                        return Promise.resolve();
                      }
                      if (accountBalace >= value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(`Le montant saisi est > surfaces déclarées : ${accountBalace}`),
                      );
                    },
                  },
                ]}
              >
                <Input suffix="ha" />
              </Form.Item>
            </Col>
            <Col sm={12} xl={6} xxl={4}>
              <Form.Item
                name="strawYear"
                label={(
                  <Text strong>
                    {locObject.translate('transformation_firstTransformation_yearField_label')}
                  </Text>
                )}
                required
                rules={[
                  {
                    required: true,
                    message: locObject.translate('year_straw'),
                  },
                ]}
              >
                <Select onChange={handleAvailableYearChanged}>
                  {availableYears.map((y) => (
                    <Option key={y} value={y}>
                      {y}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} xl={6} xxl={4}>
              <Form.Item
                label={<Text strong>{locObject.translate('country')}</Text>}
                name="country"
                labelAlign="left"
                required
                preserve={false}
                rules={[{ required: true, message: locObject.translate('countryMandetoryError') }]}
              >
                <Select
                  placeholder={locObject.translate('countryMandetoryError')}
                  size="middle"
                  onChange={changeRegion}
                  disabled={!availableCountries}
                  value={selectedCountry}
                  allowClear
                >
                  {availableCountries?.map((country) => (
                    <Option key={country.id} value={country.enumType} cid={country.id}>
                      {country.translation[lang]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={[10, 20]}>
            <Col>
              <Button
                danger
                onClick={() => dispatch({ type: 'non' })}
                icon={<CloseCircleFilled />}
                htmlType="submit"
              >
                {locObject.translate('cancel')}
              </Button>
            </Col>
            <Col>
              <Button
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={isSubmitted}
                disabled={isSubmitted || monthAlreadyClose}
              >
                {locObject.translate('save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </CSSTransition>
  );
}

export default NewStraw;
