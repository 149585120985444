function checkNum(val) {
  const specialChars = /[`!@#$%^&*()_+=[\]{};':"\\|,<>/?~]/;
  const minus = /[+-]?(\d*\.\d+|\d+\.\d*|\d+)/;
  const letters = /[A-Za-z]+/;
  const msg = 'Veuillez entrer un nombre et utiliser le point (.) pour séparer la partie entière et la partie décimale';
  if (minus.test(val) === false) {
    return msg;
  }
  if (letters.test(val)) {
    return msg;
  }
  if (specialChars.test(val)) {
    return msg;
  }
  return true;
}

export default checkNum;
