import DataSource from 'services/webServices';
import jwtDecode from 'jwt-decode';

import api from './api';
import TokenService from './tokenService';

/**
 * Return the current user based on the stored token (if any)
 * @returns {Object|null}
 */

function getCurrentUser() {
  const token = TokenService.getToken();
  if (token && token !== '') {
    return jwtDecode(token);
  }
  return null;
}
function handleLoginSuccess(data, remember) {
  TokenService.setToken(data.token);
  TokenService.setRefreshToken(data.refreshToken, remember);
}

/**
 * @param {string} email
 * @param {string} password
 * @returns {{ token: String }}
 */
async function login(email, password, remember) {
  const { data } = await api.post('/auth/login', { email, password });

  handleLoginSuccess(data, remember);

  return data;
}

/**
 * @param {string} type Provider constant: "microsoft"|"google"
 * @param {string} idToken ID Token returned by the authority after OAuth login
 * @returns {{ token: string }}
 */
async function oidcLogin(provider, idToken) {
  const { data } = await api.post('/auth/oidc/login', { provider, idToken });

  handleLoginSuccess(data);

  return data;
}

async function logout() {
  TokenService.removeToken();
  TokenService.removeRefreshToken();
}

async function refresh() {
  const refreshToken = TokenService.getRefreshToken();
  if (refreshToken) {
    const { data } = await api.post('/auth/token/refresh', { refreshToken });
    handleLoginSuccess(data);
  }
}

const AuthService = {
  getCurrentUser,
  login,
  oidcLogin,
  logout,
  refresh,
};

export default AuthService;
