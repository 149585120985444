/* eslint-disable react/destructuring-assignment */
import intlNumberFormater from './intlNumberFormater';

export default function NumberFormatter(row, col, v = null, withDecimals = true) {
  const value = String(v ?? row[col.id]).replace(',', '.');
  const number = Number(value ?? row[col.id]);
  const precNum = intlNumberFormater(number, withDecimals ?? true, 3, 2);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) {
    return (
      <div className="d-100 cell-text number">
        {v}
      </div>
    );
  }
  if (number === 0) {
    return '';
  }
  return (
    <div className="d-100 cell-text number">
      {precNum}
    </div>
  );
}
