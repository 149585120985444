import {
  Row, Col, Typography, Image, Result, Button, Layout,
} from 'antd';
import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import 'assets/styles/unauthorized.css';
import { useLocalization } from 'contexts/LocalizationContext';

const { Title } = Typography;

function Unauthorized() {
  const loc = useLocalization();
  const { locObject } = loc;
  return (
    <Layout className="auth-container">
      <Row justify="center" align="center">
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Result
            title={403}
            icon={<Image alt="celc logo" src={logo} preview={false} height="50vh" width="45vw" />}
            subTitle={<Title level={3}>{locObject.translate('unauthorized_page')}</Title>}
            extra={<Link to="/"><Button type="primary">{locObject.translate('Accueil')}</Button></Link>}
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default Unauthorized;
