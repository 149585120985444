import {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Empty, Select,
} from 'antd';
import { hideIfNotAdmin } from 'services/shared';
import { useAuth } from 'contexts/AuthContext';
import { useLocalization } from 'contexts/LocalizationContext';

function RolesDropDown(props) {
  const { user } = useAuth();
  const {
    keyGetter,
    editorOnFocus,
    column,
    commitCellValues,
    isReadOnly,
    editorHasValue,
    tableRow,
    enterEditingMod,
    valueNotChaged,
    isEditable,
  } = props;

  /**
   * @val Is the input value
   * @touches Capture touches from touches devices as like mobile ..
   */
  const [touches, setTouches] = useState([]);
  // handle prefetch

  const initVal = tableRow[column.id];
  const initialValues = column.prefetchContext().values;
  const [val, setVal] = useState(initVal);

  const loc = useLocalization().locObject;
  const editable = column.editable ?? isEditable;
  const isRoleModifiable = hideIfNotAdmin(user) !== true && hideIfNotAdmin({ roles: val }) === true;
  function handleit(value, element) {
    editorHasValue(column.id, element.id);
    setVal(element.rolekey);
  }
  function touchStart(e) {
    setTouches(touches.concat(e));
    if (touches.length === 1) {
      setTouches([]);
    }
  }
  function handleOnKeyDown(e) {
    if (initVal === val) {
      return valueNotChaged();
    }
    return commitCellValues();
  }

  function handleLostFocus() {
    if (initVal === val) {
      return valueNotChaged();
    }
    return commitCellValues();
  }
  function onEdit() {
    if (isRoleModifiable === true) {
      enterEditingMod();
    }
  }

  let controller = (editable === true && isRoleModifiable === true
    ? (
      <Button
        type="button"
        onDoubleClick={onEdit}
        className="editor-read-only d-100 h-100"
        onTouchStartCapture={touchStart}
        onKeyDown={handleOnKeyDown}
        onBlur={handleLostFocus}
      >
        <p className="text-left">
          {column.formatter(null, null, initVal)}
        </p>
      </Button>
    )
    : (
      <span
        className="editor-read-only d-100 h-100"
        style={{ cursor: 'not-allowed' }}
      >
        {loc.translate(tableRow[column.id])}
      </span>
    )

  );
  if (isReadOnly === false) {
    controller = (
      <Select
        autoFocus
        id={keyGetter}
        className="d-100"
        bordered={false}
        data-column={column.id}
        onKeyDown={handleOnKeyDown}
        onBlur={handleLostFocus}
        onChange={handleit}
        onFocus={editorOnFocus}
        defaultValue={column.formatter(null, null, initVal)}
        defaultOpen
      >
        {Array.isArray(initialValues)
    && initialValues.map((v) => (
      <Select.Option key={v.id} id={v.id} value={v.name} rolekey={v.key}>
        {v.name}
      </Select.Option>
    ))}
        {!Array.isArray(initialValues) && (
        <Select.Option key={column.id}>
          <Empty />
        </Select.Option>
        )}
      </Select>
    );
  }
  return controller;
}

// default function
function defaultBehaviour() {}

RolesDropDown.defaultProps = {
  editorOnFocus: defaultBehaviour,
};
RolesDropDown.propTypes = {
  editorOnFocus: PropTypes.func,
};

export default RolesDropDown;
