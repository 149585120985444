function checkNumber(val) {
  const regex = /^([0-9]{1,})(([.]{1})([0-9]{1,}))?$/;
  const num = String(val);
  if (regex.test(num) === false && val !== '' && val) {
    return 'Veuillez entrer un nombre positif et utiliser le point (.) pour séparer la partie entière et la partie décimale';
  }
  return true;
}

export default checkNumber;
