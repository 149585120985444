import {
  CaretRightFilled, CaretDownFilled,
} from '@ant-design/icons';
import 'assets/styles/datatable.css';
// using the same check states to say that the button has been clicked
function CollapseButton(props) {
  const { collapsed } = props;

  if (collapsed === true) {
    return <CaretRightFilled />;
  }
  return (
    <div>
      <CaretDownFilled />
    </div>
  );
}
export default CollapseButton;
