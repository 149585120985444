import { EditFilled } from '@ant-design/icons';
import {
  Form, Select, Space, Spin, Typography,
} from 'antd';
import { checkAdmin } from 'components/RequireAdmin';
import { useAuth } from 'contexts/AuthContext';
import {
  useMemo, useEffect, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';
import WebServices from 'services/webServices';
import PropTypes from 'prop-types';
import { useLocalization } from '../../contexts/LocalizationContext';

const { Option } = Select;
const { Text } = Typography;
function DropDownLazy(props) {
  const {
    isFormItem, disabled, url, required, style, showEdit, title, onChange, placeholder, optionProps,
    formItemName, hidden, filters, sorts, multiField,
  } = props;
  const { user } = useAuth();
  const { sId } = useParams();
  const nav = useNavigate();
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const services = useMemo(() => new WebServices({ get: url }, api, true), [filters]);
  const [state, setState] = useState([]);

  async function fetchClients(page, filterchanged) {
    const assignedCompany = sId ?? user.company;
    Object.assign(filters, { company: assignedCompany });
    const result = services.get(page ?? 1, sorts ?? null, filters);
    result.then((data) => {
      let serverData = state;
      if (filterchanged === true) {
        serverData = [];
      }
      if (Array.isArray(data)) {
        serverData = serverData.concat(data);
      } else {
        serverData.push(data);
      }
      setState(serverData);
    }).catch(() => {
      setState([]);
    });
  }

  useEffect(() => {
    fetchClients(1, true);
  }, [filters]);

  function handleScroll(e) {
    const scroll = e.target.scrollHeight - e.target.scrollTop;
    if (scroll !== 0 && e.target.clientHeight === scroll && services.currentPage !== Math.ceil(services.totalPages) && services.totalPages > 1) {
      fetchClients(services.currentPage + 1);
    }
  }
  function handleOnChange(val) {
    onChange(val, state);
  }
  function toManagementPage() {
    if (checkAdmin(user) === true) {
      nav(showEdit.adminNavUrl);
    } else {
      nav(showEdit.userNavUrl);
    }
  }
  if (isFormItem === true) {
    return (
      <Form.Item
        label={(
          <Space size="large">
            <Text strong={required}>{title}</Text>
            <EditFilled onClick={toManagementPage} hidden={!showEdit} />
          </Space>

           )}
        labelAlign="left"
        name={formItemName}
        valuePropName="value"
        hidden={hidden}
        required={required}
      >
        {multiField ? (
          <Select
            showSearch
            disabled={disabled}
            autoClearSearchValue
            filterOption={(input, option) => String(option.children.toLowerCase()).startsWith(input.at(0).toLocaleLowerCase())}
            placeholder={`${locObject.translate('choice_one')}${placeholder ?? title}`}
            size="middle"
            loading={state.isLoading}
            onPopupScroll={handleScroll}
            onChange={handleOnChange}
            mode="multiple"
          >
            {state?.map((cl) => (
              <Option key={cl.id} value={cl.id}>
                {cl.companyName}
              </Option>
            ))}
            {state?.isLoading === true && (
            <Option key="loading">
              <Spin size="small" className="d-100" />
            </Option>
            )}
          </Select>
        ) : (
          <Select
            showSearch
            disabled={disabled}
            autoClearSearchValue
            filterOption={(input, option) => String(option.children.toLowerCase()).startsWith(input.at(0).toLocaleLowerCase())}
            placeholder={`${locObject.translate('choice_one')}${placeholder ?? title}`}
            size="middle"
            loading={state.isLoading}
            onPopupScroll={handleScroll}
            onChange={handleOnChange}
          >
            {state?.map((cl) => (
              <Option key={cl.id} value={cl.id}>
                {cl.companyName}
              </Option>
            ))}
            {state?.isLoading === true && (
            <Option key="loading">
              <Spin size="small" className="d-100" />
            </Option>
            )}
          </Select>
        )}
      </Form.Item>
    );
  }
  return (

    <Select
      showSearch
      disabled={disabled}
      autoClearSearchValue
      filterOption={(input, option) => String(option.children.toLowerCase()).startsWith(input.at(0).toLocaleLowerCase())}
      placeholder={`${locObject.translate('choice_one')}${placeholder ?? title}`}
      size="middle"
      loading={state.isLoading}
      onPopupScroll={handleScroll}
      onChange={handleOnChange}
      hidden={hidden}
    >
      {state.values.map((cl) => (
        <Option key={cl.id} value={cl.id} {...optionProps}>
          {cl.companyName}
        </Option>
      ))}
      {state.isLoading === true && (
      <Option key="loading">
        <Spin size="small" />
      </Option>
      )}
    </Select>
  );
}
DropDownLazy.defaultProps = {
  title: '',
  placeholder: '',
  formItemName: '',
  filters: {},
  required: false,
  hidden: false,
  onChange: () => true,
  disabled: false,
  showEdit: { visible: false, adminNavUrl: '#', userNavUrl: '#' },
};
DropDownLazy.propTypes = {
  title: PropTypes.string,
  filters: PropTypes.shape({}),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  formItemName: PropTypes.string,
  isFormItem: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hidden: PropTypes.bool,
  url: PropTypes.string.isRequired,
  showEdit: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    userNavUrl: PropTypes.string.isRequired,
    adminNavUrl: PropTypes.string.isRequired,
  }),
};
export default DropDownLazy;
