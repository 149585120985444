import { useEffect, useState } from 'react';
import {
  Form, Button, Input, Select, Row, Col, Space, Typography, Alert,
} from 'antd';
import { getValue } from 'components/formatters/ObjectFormatter';
import { useLocalization } from 'contexts/LocalizationContext';
import { CSSTransition } from 'react-transition-group';
import { CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import checkNumber from 'components/validators/positifNumber';
import { formatString, toNum } from '../../services/shared';

const {
  Text,
} = Typography;

function SecondTransformation(props) {
  const {
    type, company, date, dataSource, dispatch, onSubmitData, accounts, productsIds, onDataReceived, onSubmit, monthAlreadyClose,
  } = props;

  // states

  const [userHasAccount, setUserHasAccount] = useState(false);
  const [account, setAccount] = useState({ balance: 0, productID: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [regionId, setRegionId] = useState('');
  const [alert, setAlert] = useState({ show: false, msg: '', type: 'error' });
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Contexts
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`; // Products ids
  const {
    rsfId, ssfId, sssfId,
  } = productsIds;

  // There should be at least one fiber stock to begin a second transformation

  useEffect(() => {
    if (accounts.values.length === 0) {
      setUserHasAccount(false);
      setAlert({ show: true, msg: loc.translate('transformation_noProducts_err'), type: 'warning' });
    }
  }, [type]);

  function onChooseProduct(val, element) {
    setUserHasAccount(false);
    setRegionId(element.id);
    const response = dataSource.getAccountBalance(element.key);
    response.then(({ data }) => {
      if (data.balance.amount <= 0) {
        setUserHasAccount(false);
        setAlert({ show: true, msg: locObject.translate('transformation_noSold_err'), type: 'warning' });
      } else {
        setUserHasAccount(true);
        setAlert({ show: false });
        setAccount({ balance: data.balance.amount, productID: element.product, year: element.year });
      }
    });
  }
  function onValidate(values) {
    const outStock = {};
    Object.assign(outStock, values);
    delete outStock.productType;
    const [
      p1, p2, quan,
    ] = Object.values(outStock);
    // at least one transformation out stock should be specified
    const sum = (toNum(p1) ?? 0) + (toNum(p2) ?? 0);
    if (!p1 && !p2) {
      return setAlert({ show: true, msg: locObject.translate('transformation_secondTransformation_atlestOne_err'), type: 'error' });
    }
    if (sum <= 0) {
      return setAlert({ show: true, msg: locObject.translate('transformation_secondTransformation_biggerThanZero_err'), type: 'error' });
    }
    if (sum > toNum(quan)) {
      return setAlert({ show: true, msg: locObject.translate('transformation_secondTransformation_totalQuantity_err'), type: 'error' });
    }

    if (date === 0 || !date) {
      return setAlert({ show: true, msg: locObject.translate('transformation_date_mandetory_err'), type: 'error' });
    }

    // everything is Ok submitt the form
    setIsSubmitted(true);
    setAlert({ show: false });
    setIsLoading(true);
    onDataReceived(false);
    // In Stock (Straw)

    const inStock = {
      accountRegion: regionId,
      accountProduct: account.productID,
      accountYear: account.year,
      amount: -parseFloat(quan),
      surface: 0,
    };

    // Out Stock

    const totalMovements = Object.entries(outStock).filter(([k, v]) => k !== 'quantity' && v && parseFloat(v) !== 0).map(([key, val]) => ({
      accountRegion: regionId,
      accountProduct: key,
      accountYear: account.year,
      amount: parseFloat(val),
      surface: 0,
    }));
    totalMovements.push(inStock);

    const newTransformation = {
      date: date.format('YYYY-MM-DD'),
      type,
      movements: totalMovements,
      company,
    };
    const response = onSubmitData(newTransformation);
    response.then(() => {
      setIsSubmitted(false);
      dispatch({ type: 'non' });
      onSubmit(date);
    }).catch(() => {
      setIsSubmitted(false);
    });
    return response;
  }
  return (
    <CSSTransition
      in
      timeout={500}
      appear
      classNames="form"
      unmountOnExit
    >
      <div>
        {alert.show && (
        <Alert
          type={alert.type}
          message={alert.msg}
        />
        )}
        <Form
          onFinish={onValidate}
          labelWrap
          layout="vertical"
          colon={false}
        >
          <Row className="d-100 h-100" gutter={[20, 10]}>
            <Col sm={24} md={12}>
              <Row gutter={[2, 10]}>
                <Col span={20}>
                  <Form.Item
                    required
                    label={<Text strong>{locObject.translate('productType')}</Text>}
                    labelAlign="left"
                    name="productType"
                  >
                    <Select
                      placeholder={locObject.translate('transformation_secondTransformation_entryProduct_ph')}
                      loading={accounts.isLoading === true}
                      onChange={onChooseProduct}
                    >
                      {accounts.values.filter((acc) => acc.account.product && (acc.account.product.id === rsfId || acc.account.product.id === sssfId)).map((acc) => (
                        <Select.Option key={acc.account.id} id={acc.account.region.id} year={acc.account.year} product={acc.account.product.id}>
                          <Space size="small">
                            {formatString(getValue(acc.account.product, lang), acc.account.region.country.translation[lang], acc.account.year, acc.balance.amount)}
                          </Space>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={12}>

                  <Form.Item
                    label={<Text strong>{locObject.translate('transformation_secondTransformation_quantityfield_title')}</Text>}
                    name="quantity"
                    dependencies={['productType']}
                    rules={[{ required: true, message: locObject.translate('transformation_secondTransformation_quantityfield_mandetory_err') }, ({ getFieldValue }) => ({
                      required: true,
                      validator(_, value) {
                        if ((account.balance === 0 && !getFieldValue('productType')) || getFieldValue('productType') === '') {
                          return Promise.reject(new Error(locObject.translate('transformation_secondTransformation_entryProduct_ph')));
                        }
                        if (value > account.balance && account.balance !== 0) {
                          return Promise.reject(new Error(`${locObject.translate('transformation_quantityfield_limits_err')} ${account.balance} kg`));
                        }
                        if (checkNumber(value) !== true) {
                          return Promise.reject(new Error(checkNumber(value)));
                        }
                        return Promise.resolve();
                      },
                    }),
                    ]}
                    labelAlign="left"
                  >
                    <Input
                      disabled={!userHasAccount}
                      suffix="kg"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col sm={24} md={12}>
              <Row gutter={[2, 10]}>
                <Col span={24}>
                  {locObject.translate('semiScutchedShortFiber')}
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={sssfId}
                    dependencies={[ssfId]}
                    rules={[{
                      required: true,
                      validator: (_, value) => {
                        if (checkNumber(value) !== true) {
                          return Promise.reject(new Error(checkNumber(value)));
                        }
                        return Promise.resolve();
                      },
                    },
                    ]}

                  >
                    <Input
                      disabled={!userHasAccount}
                      suffix="kg"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[2, 10]}>
                <Col span={24}>
                  {locObject.translate('scutchedShortFiber')}

                </Col>
                <Col span={12}>
                  <Form.Item
                    name={ssfId}
                    dependencies={[sssfId]}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          if (checkNumber(value) !== true) {
                            return Promise.reject(new Error(checkNumber(value)));
                          }
                          return Promise.resolve();
                        },
                      },

                    ]}

                  >
                    <Input
                      disabled={!userHasAccount}
                      suffix="kg"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    icon={<CloseCircleFilled />}
                    onClick={() => dispatch({ type: 'non' })}
                    danger
                  >
                    {locObject.translate('cancel')}

                  </Button>
                </Col>
                <Col>
                  <Button
                    icon={<SaveOutlined />}
                    htmlType="submit"
                    loading={isSubmitted}
                    disabled={isSubmitted || monthAlreadyClose}
                  >
                    {locObject.translate('save')}

                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </CSSTransition>
  );
}
export default SecondTransformation;
