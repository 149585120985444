import { Typography } from 'antd';

const { Text } = Typography;
function SimpleTextEditor(props) {
  const { value, column, ind } = props;
  let { style, groupWithDecimal } = column;

  if (Array.isArray(groupWithDecimal)) {
    groupWithDecimal = groupWithDecimal[ind];
  }
  if (column.groupStyle) {
    style = column.groupStyle[ind];
  }
  if (column.groupFormatter) {
    if (column.groupFormatter[ind]) {
      return <div>{column.groupFormatter[ind](null, column, value, groupWithDecimal)}</div>;
    }
  }
  if (column.formatter) {
    return <div>{column.formatter(null, column, value, column.withDecimals)}</div>;
  }
  return (
    <Text style={style}>
      {value}
    </Text>
  );
}

export default SimpleTextEditor;
