import { useAuth } from 'contexts/AuthContext';
import React, { useState, useEffect } from 'react';
import { useLocalization } from 'contexts/LocalizationContext';
import {
  MenuOutlined, SettingFilled, UserOutlined, LogoutOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  Space, Menu, Modal,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import WebServices from 'services/webServices';
import api from 'services/api';
import LanguageMenu from './LanguageMenu';

const { SubMenu } = Menu;

const crudTransformation = {
  get: '/transformation/',
  update: '/transformation',
  delete: '/transformation',
  create: '/transformation/',
};

function NavMenu(props) {
  const { navLinks, currentLocation, updateLang } = props;
  const { sId } = useParams();
  const { user, logout } = useAuth();
  const dataSourceTransformation = new WebServices(crudTransformation, api, true, 'transformation', sId ?? user.company);

  // contexts
  const notify = useNotificationCenter()();
  const loc = useLocalization();
  const { locObject } = loc;
  const services = new WebServices({}, api, true, '', user.company);
  const [sellId, setSellId] = useState(4);
  const [selectedNavItem, setSelectedNavItem] = useState('');
  const [lastOperationDateData, setLastOperationDateData] = useState(null);
  const [lastOperationDate, setLastOperationDate] = useState(null);
  const [lastCloseOperationDate, setLastCloseOperationDate] = useState(null);

  function switchLanguage(lang) {
    updateLang(lang);
  }
  useEffect(() => {
    const response = services.getTransformationTypes();
    response.then((r) => {
    // when types ready get the sales
      const sellTransformationTypeId = r.data.find((t) => t.enumType === 'SELL');
      setSellId(sellTransformationTypeId.id);
    });
    const selected = navLinks.find((l) => l.link === currentLocation?.link);
    setSelectedNavItem(selected);
  }, []);

  function getLastDateTypeOperation(type) {
    const response = dataSourceTransformation.getLastDateTypeOperation(dataSourceTransformation.company, type);
    response.then(({ data }) => {
      const date = new Date(data.lastDate);
      date.setDate(date.getDate() - 10);
      date.setMonth(date.getMonth() + 1);
      setLastOperationDate(date);
      setLastOperationDateData(`${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`);
    });
  }

  function getLastDateCloseMonth(operationType) {
    const response = dataSourceTransformation.getLastDateTypeOperation(dataSourceTransformation.company, operationType);
    response.then(({ data }) => {
      setLastCloseOperationDate(data.lastDate);
    });
  }

  useEffect(() => {
    const selected = navLinks.find((l) => l.link === currentLocation?.link)?.link;
    setSelectedNavItem(selected);
    if (user.roles.includes('ROLE_DATA_ENTERING_USER')
      && !user.roles.includes('ROLE_MULTISCUTCHING_ADMIN')
    ) {
      getLastDateTypeOperation('CLOSE');
      getLastDateCloseMonth('CLOSE');
    }
  }, [currentLocation]);

  function exportCurrentTable(name, extras, fileName) {
    const resp = services.exportCurrentTable(name, extras);
    resp.then((response) => {
      // check if the response is empty
      if (response.data.type === 'application/json') {
        notify({
          msg: locObject.translate('nodata_export'), type: 'error', desc: '', duration: 10,
        });
      } else {
        // use a virtual <a> element to trigger the navigator download Event
        const hiddenElement = document.createElement('a');
        const blob = new Blob([response.data]);
        hiddenElement.href = URL.createObjectURL(blob);
        // save the file with the current date
        const date = moment().format('YYYY-MM-SS HH-MM-SS');
        hiddenElement.download = `${fileName}-${date}.xlsx`;
        hiddenElement.click();
      }
    });
  }

  function onValidate(type) {
    const { company } = user;
    const newCloseObject = {
      date: moment(new Date(lastOperationDate)).format('YYYY-MM-DD'),
      type,
      company,
    };

    const response = dataSourceTransformation.create(newCloseObject);
    response.then((res) => {
      logout();
    }).catch((err) => {
      console.log(err);
    });
  }

  function showCloseMonthModal() {
    Modal.confirm({
      title: `${locObject.translate('close_month_question')} ${lastOperationDateData} ?`,
      okText: locObject.translate('logout_close_month_question_button_confirm'),
      cancelText: locObject.translate('logout_close_month_question_button_cancel'),
      onOk() {
        onValidate(7);
      },
      onCancel() {
        logout();
      },
      width: 550,
    });
  }

  function handleLogout() {
    const lastDayPreviousMonth = moment(new Date())
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');

    if (user.roles.includes('ROLE_DATA_ENTERING_USER')
      && !user.roles.includes('ROLE_MULTISCUTCHING_ADMIN')
      && lastCloseOperationDate !== lastDayPreviousMonth
    ) {
      showCloseMonthModal();
    } else {
      logout();
    }
  }

  return (
    <Menu
      mode="horizontal"
      theme="dark"
      className="nav-menu"
      overflowedIndicator={<MenuOutlined />}
      selectedKeys={[selectedNavItem]}
      triggerSubMenuAction="click"
    >
      {navLinks.filter((p) => p.isMenuItem).map((page, ind) => (
        <Menu.Item

          // eslint-disable-next-line react/jsx-props-no-multi-spaces, react/no-array-index-key
          key={page.link + ind}
        >
          <Link to={page.link}>
            <Space>
              {locObject.translate(page.title)}
            </Space>
          </Link>

        </Menu.Item>
      ))}
      <SubMenu key="export" title={locObject.translate('nav_export')}>
        <Menu.Item
          key="exp1"
          onClick={() => exportCurrentTable(
            'annual-surface-declarations/',
            undefined,
            locObject.translate('nav_surfaces'),
          )}
        >
          {locObject.translate('nav_surfaces')}

        </Menu.Item>
        <Menu.Item
          key="exp2"
          onClick={() => exportCurrentTable(
            'transformation/',
            undefined,
            locObject.translate('nav_transformations'),
          )}
        >
          {locObject.translate('nav_transformations')}

        </Menu.Item>
        <Menu.Item
          key="exp3"
          onClick={() => exportCurrentTable(
            'transformation/',
            `?type=${sellId}`,

            locObject.translate('nav_sales'),
          )}
        >
          {locObject.translate('nav_sales')}

        </Menu.Item>
      </SubMenu>
      <Menu.Item key="lang" className="lang-dropdown">
        <LanguageMenu onLanguageChange={switchLanguage} />
      </Menu.Item>
      <SubMenu
        popupOffset={[0, 20]}
        icon={(
          <div>
            <UserOutlined
              style={{
                fontSize: '2.5em',
                border: '1px solid',
                borderRadius: '30px',
                backgroundColor: 'white',
                color: '#003365',
              }}
            />
          </div>
)}
        key="parameters"
        style={{ display: 'flex', alignItems: 'center' }}
        className="avatar-menu"
      >
        <Menu.Item icon={<SettingFilled />} key={3}>
          <Link to={navLinks?.find((l) => l.link.includes('parameters')).link}>
            {locObject.translate('nav_parameters')}
          </Link>
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key={4} onClick={handleLogout}>
          {locObject.translate('nav_disconnect')}
        </Menu.Item>
      </SubMenu>
    </Menu>

  );
}

export default NavMenu;
