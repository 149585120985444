import PropTypes from 'prop-types';
import { useAuth } from 'contexts/AuthContext';
import { useEffect } from 'react';

function TokenExpirationHandler({ children }) {
  const { logout } = useAuth();

  useEffect(() => {
    function handleTokenExpired() {
      logout();
      window.location.replace('/login');
    }

    document.addEventListener('token_expired', handleTokenExpired);

    // Cleanup
    return () => {
      document.removeEventListener('token_expired', handleTokenExpired);
    };
  });

  return children;
}

TokenExpirationHandler.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TokenExpirationHandler;
