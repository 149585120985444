/* eslint-disable no-underscore-dangle */
import axios from 'axios';

import config from '../config';
import TokenService from './tokenService';

const api = axios.create({ baseURL: config.apiBaseUrl });
const unsupportedRefreshUrls = ['/auth/login', '/auth/oidc/login', '/auth/token/refresh'];

// Automatically add authentication header based on stored token (if any)
api.interceptors.request.use((axiosConfig) => {
  const token = TokenService.getToken();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    axiosConfig.headers.Authorization = `Bearer ${token}`;
  }
  return axiosConfig;
});

// get refresh token
api.interceptors.response.use(
  // success don't change anything
  (res) => (res),
  async (err) => {
    const originalConfig = err.config;
    const tokenExpired = new Event('token_expired');

    if (unsupportedRefreshUrls.includes(originalConfig.url) || !err.response) {
      return Promise.reject(err);
    }
    if (err.response.status === 401 && TokenService.getRefreshToken() && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const response = await api.post('/auth/token/refresh', {
          refreshToken: TokenService.getRefreshToken(),
        });

        const { token, refreshToken } = response.data;
        TokenService.setToken(token);
        TokenService.setRefreshToken(refreshToken);
        console.clear();
        return api(originalConfig);
      } catch (_error) {
        document.dispatchEvent(tokenExpired);
        return Promise.reject(_error);
      }
    }

    return Promise.reject(err);
  },
);

export default api;
