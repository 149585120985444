import {
  createContext, useContext, useReducer, useEffect,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

const ProductTypesContext = createContext([]);

const initialState = {
  values: [],
  isLoading: false,
  isError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fetch_start':
      return {
        ...state,
        isLoading: true,
      };
    case 'fetch_success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        values: action.payload,
      };
    case 'fetch_error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

function ProductTypesProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchProductTypes() {
      dispatch({ type: 'fetch_start' });

      try {
        const result = await api.get('/product-type/');
        dispatch({ type: 'fetch_success', payload: result.data });
      } catch (error) {
        dispatch({ type: 'fetch_error' });
      }
    }

    fetchProductTypes();
  }, []);

  return (
    <ProductTypesContext.Provider value={state}>
      {children}
    </ProductTypesContext.Provider>
  );
}

ProductTypesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useProductTypes() {
  const value = useContext(ProductTypesContext);

  if (isUndefined(value)) {
    throw new Error('useProductTypes must be used inside an ProductTypesProvider component');
  }

  return value;
}

export {
  ProductTypesProvider, useProductTypes,
};
