import {
  Link,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import {
  Layout,
  Breadcrumb,
  Image,
  Space,
  Menu,
  Button,
  Modal,
} from 'antd';
import { configPagesAdmin, configPagesUser } from 'config/pages';

import { CountriesProvider } from 'contexts/CountriesContext';
import { RegionsProvider } from 'contexts/RegionsContext';
import { useEffect, useState } from 'react';
import { ParameteresProvider } from 'contexts/ParameteresContext';
import logo from 'assets/images/logo-light.png';

import NavMenu from 'components/NavMenu';
import { HomeFilled, SaveOutlined } from '@ant-design/icons';
import { hideIfNotAdmin } from 'services/shared';
import { useLocalization } from 'contexts/LocalizationContext';
import { FederationsProvider } from 'contexts/FederationsContext';
import { CompanyInfoProvider } from 'contexts/CompanyInfoContext';
import moment from 'moment/moment';
import WebServices from '../services/webServices';
import api from '../services/api';

const { Header, Content, Footer } = Layout;

const crudTransformation = {
  get: '/transformation/',
  update: '/transformation',
  delete: '/transformation',
  create: '/transformation/',
};
function MainLayout() {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const loc = useLocalization();
  const { locObject, updateFunc, currentLang } = loc;
  const userIsAdmin = user.roles.includes('ROLE_MULTISCUTCHING_ADMIN');
  const navLinks = userIsAdmin ? configPagesAdmin : configPagesUser;
  const [navHistory, setNavHistory] = useState([['home', navLinks[0].link]]);
  const [currentLocation, setCurrentLocation] = useState({ title: 'home' });
  const year = new Date().getFullYear();
  const [lastOperationDateData, setLastOperationDateData] = useState(null);
  const [lastOperationDate, setLastOperationDate] = useState(null);
  const [lastCloseOperationDate, setLastCloseOperationDate] = useState(null);
  const [lastDayPreviousMonth, setLastDayPreviousMonth] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { sId } = useParams();
  const dataSourceTransformation = new WebServices(crudTransformation, api, true, 'transformation', sId ?? user.company);

  function updateBreadCrumbs(location) {
    let hist = navHistory;
    let pg = navLinks.find((lin) => lin.link === location);
    if (userIsAdmin && !pg) {
      // Scutching management links
      const title = navLinks.find((link) => location.match(link.link) && link.link !== '/admin' && link.link !== '/admin/manage-scutching')?.title ?? '';
      pg = { title, link: pathname };
      setCurrentLocation(pg);
    } else {
      setCurrentLocation(pg);
    }
    const alreadyIn = navHistory.find(([k, v]) => v === location);
    if (pg) {
      if (alreadyIn === undefined) {
        hist.push([pg.title, pg.link]);
      } else {
        const index = navHistory.findIndex(([k, v]) => k === pg.title);
        const reordered = hist.slice(0, index + 1);
        hist = [];
        reordered.forEach((arr) => {
          hist.push(arr);
        });
      }
    }
    setNavHistory(hist);
  }

  function onValidate(type) {
    const company = sId ?? user.company;
    const newCloseObject = {
      date: moment(new Date(lastOperationDate)).format('YYYY-MM-DD'),
      type,
      company,
    };

    const response = dataSourceTransformation.create(newCloseObject);
    response.then((res) => {
      window.location.reload();
    }).catch((err) => {
      console.log(err);
    });
  }

  function showCloseMonthModal() {
    if (user.roles.includes('ROLE_DATA_ENTERING_USER')
      && !user.roles.includes('ROLE_MULTISCUTCHING_ADMIN')
      && lastCloseOperationDate !== lastDayPreviousMonth
    ) {
      Modal.confirm({
        title: (
          <span>
            {locObject.translate('close_month_modal_title_part_1')}
            {' '}
            <strong>{lastOperationDateData}</strong>
            {' '}
            {locObject.translate('close_month_modal_title_part_2')}
          </span>
        ),
        okText: locObject.translateWithReplace('close_month_modal_ok', {
          placeholder: 'xxxx',
          value: lastOperationDateData,
        }),
        cancelText: locObject.translate('close_month_modal_cancel'),
        onOk() {
          onValidate(7);
          Modal.destroyAll();
        },
        onCancel() {
          Modal.destroyAll();
        },
        width: 520,
      });
    }
  }
  function getLastDateTypeOperation(type) {
    const response = dataSourceTransformation.getLastDateTypeOperation(dataSourceTransformation.company, type);
    response.then(({ data }) => {
      const date = new Date(data.lastDate);
      date.setDate(date.getDate() - 10);
      date.setMonth(date.getMonth() + 1);
      setLastOperationDate(date);
      setLastOperationDateData(`${date.toLocaleString(currentLang, { month: 'long' })} ${date.getFullYear()}`);
    });
  }

  function getLastDateCloseMonth(operationType) {
    const response = dataSourceTransformation.getLastDateTypeOperation(dataSourceTransformation.company, operationType);
    response.then(({ data }) => {
      setLastCloseOperationDate(data.lastDate);
    });
  }

  function getLastDayPreviousMonth() {
    const lastDayPreviousMonthData = moment(new Date())
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
    setLastDayPreviousMonth(lastDayPreviousMonthData);
  }

  useEffect(() => {
    updateBreadCrumbs(pathname);
  }, [pathname]);

  useEffect(() => {
    if (user.roles.includes('ROLE_DATA_ENTERING_USER')
      && !user.roles.includes('ROLE_MULTISCUTCHING_ADMIN')
    ) {
      getLastDateTypeOperation('CLOSE');
      getLastDateCloseMonth('CLOSE');
      getLastDayPreviousMonth();
    }
  }, [user.roles]);

  useEffect(() => {
    if (lastDayPreviousMonth !== null && lastCloseOperationDate !== null) {
      setIsLoading(false);
    }
  }, [lastDayPreviousMonth, lastCloseOperationDate]);

  document.title = `Flaxdata | ${locObject.translate(currentLocation?.title)}`;
  return (
    <Layout className="h-min-vh-100">
      {!isLoading && lastDayPreviousMonth !== lastCloseOperationDate && (
        <div
          style={{
            paddingLeft: '50px',
            paddingRight: '50px',
            backgroundColor: '#faad14',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50px',
          }}
        >
          <div
            style={{
              display: 'flex',
              columnGap: '20px',
            }}
          >
            {lastOperationDateData && (
              <Button
                type="default"
                shape="default"
                onClick={showCloseMonthModal}
              >
                <SaveOutlined />
                {locObject.translate('close_current_month_banner')}
                {' '}
                {lastOperationDateData}
              </Button>
            )}
          </div>
        </div>
      )}
      <Header>
        <div className="logo">
          <Image src={logo} preview={false} style={{ height: '60px', width: '65px', marginTop: '-5.5px' }} />
        </div>
        <NavMenu navLinks={navLinks} currentLocation={currentLocation} updateLang={updateFunc} />
      </Header>
      <Content style={{ paddingLeft: '50px', paddingRight: '50px' }}>
        <Layout className="title-container">
          <Breadcrumb className="breadcrumb-container">
            {navHistory.map(([title, link]) => (
              <Breadcrumb.Item key={link} className={currentLocation?.link === link ? 'bread-active' : 'breadcrumb'}>
                <Link to={link}>
                  <Space direction="horizontal" size="small" style={{ position: 'relative', zIndex: '10' }}>
                    {link === '/' || link === '/admin' ? <HomeFilled /> : ''}
                    {locObject.translate(title)}
                  </Space>
                </Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Layout>
        {user ? (
          <ParameteresProvider>
            <CountriesProvider>
              <RegionsProvider>
                <FederationsProvider>
                  <Outlet />
                </FederationsProvider>
              </RegionsProvider>
            </CountriesProvider>
          </ParameteresProvider>
        ) : (
          <Outlet />
        )}
      </Content>
      <Footer className="nav-footer nav-menu">
        <Menu
          className="justify-center-left"
          mode="horizontal"
          theme="dark"
        >
          <Menu.Item key={1}>
            CELC ©
            {year}
          </Menu.Item>
          <Menu.Item key={2}>
            <Link to={navLinks.find((l) => l.title === 'breadcrumb_privacy_policy').link}>
              {locObject.translate('footer_legal_text')}
            </Link>
          </Menu.Item>
          <Menu.Item key={3}>
            <Link to={navLinks.find((l) => l.title === 'breadcrumb_reglement').link}>
              {locObject.translate('footer_reglement_text')}
            </Link>
          </Menu.Item>
        </Menu>
      </Footer>
    </Layout>
  );
}

export default MainLayout;
