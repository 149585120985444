import {
  createContext, useContext, useReducer, useEffect,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { useLocalization } from './LocalizationContext';

const CountriesRegionsContext = createContext([]);

const initialState = {
  values: [],
  isLoading: false,
  isError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fetch_start':
      return {
        ...state,
        isLoading: true,
      };
    case 'fetch_success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        values: action.payload,
      };
    case 'fetch_error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

function CountriesRegionsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentLang } = useLocalization();
  const lang = `label_${currentLang}`;
  useEffect(() => {
    async function fetchCountriesRegions() {
      dispatch({ type: 'fetch_start' });

      try {
        const regionsResult = await api.get('/regions/');
        const countriesResult = await api.get('/countries/');
        const result = regionsResult.data.map(
          (r) => ` ${countriesResult.data.find((c) => c.id === r.country.id).translation[lang]} - ${
            r.name
          }`,
        );
        dispatch({ type: 'fetch_success', payload: result });
      } catch (error) {
        dispatch({ type: 'fetch_error' });
      }
    }

    fetchCountriesRegions();
  }, []);

  return (
    <CountriesRegionsContext.Provider value={state}>{children}</CountriesRegionsContext.Provider>
  );
}

CountriesRegionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useCountriesRegions() {
  const value = useContext(CountriesRegionsContext);

  if (isUndefined(value)) {
    throw new Error(
      'useCountriesRegions must be used inside an CountriesRegionsProvider component',
    );
  }

  return value;
}

export { CountriesRegionsProvider, useCountriesRegions };
