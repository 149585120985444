import {
  createContext, useContext, useReducer, useEffect,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

const ProductIntervalsContext = createContext([]);

const initialState = {
  values: [],
  isLoading: false,
  isError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fetch_start':
      return {
        ...state,
        isLoading: true,
      };
    case 'fetch_success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        values: action.payload,
      };
    case 'fetch_error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

function ProductIntervalsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchProductIntervals() {
      dispatch({ type: 'fetch_start' });

      try {
        const result = await api.get('/product-interval/');
        dispatch({ type: 'fetch_success', payload: result.data });
      } catch (error) {
        dispatch({ type: 'fetch_error' });
      }
    }

    fetchProductIntervals();
  }, []);

  return (
    <ProductIntervalsContext.Provider value={state}>
      {children}
    </ProductIntervalsContext.Provider>
  );
}

ProductIntervalsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useProductIntervals() {
  const value = useContext(ProductIntervalsContext);
  if (isUndefined(value)) {
    throw new Error('useProductIntervals must be used inside an ProductIntervalsProvider component');
  }

  return value;
}

export {
  ProductIntervalsProvider, useProductIntervals,
};
