import { useState } from 'react';
import {
  Form, Button, Input, Select, Row, Col, Space, Alert, Typography,
} from 'antd';
import { getValue } from 'components/formatters/ObjectFormatter';
import { useLocalization } from 'contexts/LocalizationContext';
import { CSSTransition } from 'react-transition-group';
import { CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import checkNumber from 'components/validators/positifNumber';
import intlNumberFormater from 'components/formatters/intlNumberFormater';
import { formatString } from '../../services/shared';

const { Text } = Typography;
function CardingForm(props) {
  const {
    type, company, date, dataSource, dispatch, onSubmitData, accounts, productsIds,
  } = props;

  // states

  const [account, setAccount] = useState({ balance: 0, productID: '' });
  const [regionId, setRegionId] = useState('');
  const [inStockAmount, setInStockAmount] = useState(0);
  const [alert, setAlert] = useState({ show: false, msg: '', type: 'error' });
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Contexts
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  // Products ids

  const { lin, clfId, dlfId } = productsIds;
  const intCompany = parseInt(company, 10);
  function handleInStockChange(e) {
    setInStockAmount(Number(e.target.value));
  }
  function onChooseProduct(val, element) {
    setRegionId(element.id);
    const response = dataSource.getAccountBalance(element.key);
    response.then(({ data }) => {
      setAccount({
        balance: data.balance.amount, productID: element.product, year: element.year, quality: element.quality,
      });
    });
  }
  function onSubmit(outStock) {
    const [
      ln, prodcutType, quantity, tm,
    ] = Object.values(outStock);

    if (Number(ln) <= 0) {
      return setAlert({ show: true, msg: locObject.translate('transformation_carding_noZeroLin_error'), type: 'error' });
    }
    if (date === 0 || !date) {
      return setAlert({ show: true, msg: locObject.translate('transformation_date_mandetory_err'), type: 'error' });
    }
    // everything is Ok submitt the form
    setIsSubmitted(true);
    setAlert({ show: false });

    // In Stock (Straw)

    const inStock = {
      accountRegion: regionId,
      // accountQuality: account.quality,
      accountProduct: account.productID,
      accountYear: account.year,
      amount: -parseFloat(quantity),
      surface: 0,
    };

    // Out Stock
    const totalMovements = [{
      accountRegion: regionId,
      accountProduct: lin,
      accountYear: account.year,
      amount: parseFloat(ln),
      surface: 0,
    }];
    totalMovements.push(inStock);

    const newTransformation = {
      date: date.format('YYYY-MM-DD'),
      type,
      movements: totalMovements,
      company,
      info: tm,
    };
    const response = onSubmitData(newTransformation);
    response.then(() => {
      dispatch({ type: 'non' });
      setIsSubmitted(false);
    }).catch(() => {
      setIsSubmitted(false);
    });
    return response;
  }
  return (
    <CSSTransition
      in
      timeout={500}
      appear
      classNames="form"
      unmountOnExit
    >
      <div>
        {alert.show && (
        <Alert
          type={alert.type}
          message={alert.msg}
        />
        )}
        <Form
          onFinish={onSubmit}
          layout="vertical"
          colon={false}
        >
          <Row className="d-100 h-100" gutter={[20, 10]}>
            <Col sm={24} md={12}>
              <Row gutter={[2, 10]}>
                <Col span={20}>
                  <Form.Item
                    label={<Text strong className="d-100">{locObject.translate('productType')}</Text>}
                    labelAlign="left"
                    name="productType"
                    required
                  >
                    <Select
                      placeholder={locObject.translate('transformation_carding_entryProduct_ph')}
                      loading={accounts.isLoading === true}
                      onChange={onChooseProduct}
                    >
                      {accounts?.values?.filter((acc) => (acc.account.company.id === intCompany && acc.account.product && (acc.account.product.id === clfId || acc.account.product.id === dlfId))).map((acc) => (
                        <Select.Option key={acc.account.id} id={acc.account.region.id} product={acc.account.product.id} year={acc.account.year} quality={acc.account.quality?.id}>
                          <Space size="small">
                            {formatString(getValue(acc.account.product, lang), acc.account.region.country.translation[lang], acc.account.year, acc.balance.amount, acc.account.quality?.translation[lang])}
                          </Space>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <Form.Item
                    name="quantity"
                    label={<Text strong>{locObject.translate('transformation_carding_quantityfield_title')}</Text>}
                    dependencies={['productType']}
                    rules={[{ required: true, message: locObject.translate('transformation_carding_quantityfield_mandetory_err') }, ({ getFieldValue }) => ({
                      required: true,
                      validator(_, value) {
                        if ((account.balance === 0 && !getFieldValue('productType')) || getFieldValue('productType') === '') {
                          return Promise.reject(new Error(locObject.translate('transformation_carding_entryProduct_err')));
                        }
                        if (value > account.balance && account.balance !== 0) {
                          return Promise.reject(new Error(`${locObject.translate('transformation_quantityfield_limits_err')} ${intlNumberFormater(account.balance)} kg`));
                        }
                        if (checkNumber(value) !== true) {
                          return Promise.reject(new Error(checkNumber(value)));
                        }
                        return Promise.resolve();
                      },
                    }),
                    ]}
                    labelAlign="left"
                  >
                    <Input
                      value={inStockAmount}
                      onChange={handleInStockChange}
                      suffix="kg"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col sm={24} md={12}>
              <Row gutter={[2, 10]}>
                <Col span={12}>
                  <Form.Item
                    required
                    label={<Text strong>{locObject.translate('transformation_carding_linenField_label')}</Text>}
                    name={lin}
                    rules={[{
                      required: true,
                      message: locObject.translate('fieldMandetoryError'),
                    }, {
                      validator: (_, value) => {
                        if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                        if (value > inStockAmount) {
                          return Promise.reject(new Error(locObject.translate('transformation_carding_quantity_error')));
                        }
                        return Promise.resolve();
                      },
                    }]}
                  >
                    <Input
                      suffix="kg"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[2, 10]}>
                <Col span={12}>
                  <Form.Item
                    label={<Text strong>{locObject.translate('transformation_carding_qualityField_label')}</Text>}
                    required
                    name="tm"
                    rules={[{
                      required: true,
                      message: locObject.translate('fieldMandetoryError'),
                    }, {
                      require: true,
                      validator: (_, value) => {
                        if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                        if (value <= 0) {
                          return Promise.reject(new Error(locObject.translate('biggerThanZero_err')));
                        }
                        return Promise.resolve();
                      },
                    }]}

                  >
                    <Input
                      suffix="Nm"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    icon={<CloseCircleFilled />}
                    onClick={() => dispatch({ type: 'non' })}
                    danger
                  >
                    {locObject.translate('cancel')}

                  </Button>
                </Col>
                <Col>
                  <Button
                    icon={<SaveOutlined />}
                    htmlType="submit"
                    loading={isSubmitted}
                    disabled={isSubmitted}
                  >
                    {locObject.translate('save')}

                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </CSSTransition>
  );
}
export default CardingForm;
