import { HomeFilled } from '@ant-design/icons';

const configPagesUser = [
  {
    title: 'home', link: '/', isMenuItem: true, isActive: false, icon: HomeFilled,
  },
  {
    title: 'nav_surfaces', link: '/declare/annual-surfaces', isMenuItem: true, isActive: false,
  },
  { title: 'breadcrumb_import', link: '/declare/annual-surfaces/import', isMenuItem: false },
  {
    title: 'nav_transformations', link: '/declare/transformation', isMenuItem: true, isActive: false,
  },
  {
    title: 'nav_sales', link: '/declare/sells', isMenuItem: true, isActive: false,
  },
  { title: 'breadcrumb_parameters', link: '/parameters', isMenuItem: false },
  { title: 'breadcrumb_manage_regions', link: '/parameters/manage-regions', isMenuItem: false },
  { title: 'breadcrumb_manage_varieties', link: '/parameters/manage-variety', isMenuItem: false },
  { title: 'breadcrumb_manage_farmers', link: '/manage-farmers', isMenuItem: false },
  { title: 'breadcrumb_manage_clients', link: '/manage-clients', isMenuItem: false },
  { title: 'breadcrumb_manage_users', link: '/parameters/manage-users', isMenuItem: false },
  { title: 'breadcrumb_new_user', link: '/parameters/new-user', isMenuItem: false },
  { title: 'breadcrumb_privacy_policy', link: '/privacy-policy', isMenuItem: false },
  { title: 'breadcrumb_reglement', link: '/reglement', isMenuItem: false },
];
const configPagesAdmin = [
  {
    title: 'home', link: '/admin', isMenuItem: true, icon: HomeFilled,
  },
  { title: 'breadcrumb_parameters', link: '/admin/parameters', isMenuItem: false },
  { title: 'admin_nav_scutching', link: '/admin/manage-scutching', isMenuItem: true },
  { title: 'breadcrumb_admin_fragment_farmers', link: /(farmers)$/, isMenuItem: false },
  { title: 'breadcrumb_admin_fragment_clients', link: /(clients)$/, isMenuItem: false },
  { title: 'breadcrumb_admin_fragment_surfaces', link: '/admin/manage-scutching/surfaces', isMenuItem: false },
  { title: 'breadcrumb_admin_fragment_transformation', link: '/admin/manage-scutching/transformation', isMenuItem: false },
  { title: 'breadcrumb_admin_fragment_sales', link: '/admin/manage-scutching/sells', isMenuItem: false },
  { title: 'breadcrumb_admin_fragment_users', link: '/admin/manage-scutching/users', isMenuItem: false },
  { title: 'breadcrumb_admin_new_scutching', link: '/admin/manage-scutching/new-scutching', isMenuItem: false },
  { title: 'admin_nav_reports', link: '#', isMenuItem: false },
  { title: 'breadcrumb_manage_regions', link: '/admin/parameters/manage-regions', isMenuItem: false },
  { title: 'breadcrumb_manage_varieties', link: '/admin/parameters/manage-variety', isMenuItem: false },
  { title: 'breadcrumb_manage_users', link: '/admin/parameters/manage-users', isMenuItem: false },
  { title: 'breadcrumb_new_user', link: '/admin/parameters/new-user', isMenuItem: false },
  { title: 'breadcrumb_privacy_policy', link: '/admin/privacy-policy', isMenuItem: false },
  { title: 'breadcrumb_reglement', link: '/admin/reglement', isMenuItem: false },
];

export { configPagesUser, configPagesAdmin };
