import {
  Row, Col, Empty, Typography, Image,
} from 'antd';
import logo from 'assets/images/logo.png';
import { useLocalization } from 'contexts/LocalizationContext';

const { Title } = Typography;
function NotFound() {
  const loc = useLocalization();
  const { locObject } = loc;
  return (
    <Row justify="center" align="center" style={{ width: '100vw', height: '100vh' }}>
      <Col>
        <Image src={logo} preview={false} height="12vh" width="12vw" style={{ objectFit: 'contain' }} />
      </Col>
      <Col style={{ display: 'flex', alignItems: 'center' }}>
        <Empty imageStyle={{ width: '50vw', height: '35vh' }} description={<Title level={3}>{locObject.translate('page_not_found')}</Title>} />
      </Col>
    </Row>
  );
}

export default NotFound;
