import { Col, Row } from 'antd';

function getNestedData(nestedData, nestedKeys) {
  const nestedArray = nestedKeys.split(':');
  let result = nestedData;
  if (nestedArray.length > 1) {
    nestedArray.forEach((nested) => { result = result[nested] ?? 0; });
    return result;
  }
  return nestedData[nestedKeys] ?? 0;
}
export default function NestedDataTableRow(props) {
  const {
    dt, metaObject, period, tableType,
  } = props;
  const tdData = getNestedData(dt, metaObject.key);
  const withDecimals = metaObject.formatOptions?.withDecimals ?? false;
  return (
    <Row className="stats-table-tr">
      <Col key={`tt${metaObject.title}`} span={6} className="stats-table-td">{metaObject.title}</Col>
      <Col key={metaObject.unit} span={3} className="stats-table-td">{metaObject.unit}</Col>
      {tableType !== 'surfaces' ? (
        <Col key="frmt-col" span={3} className="data stats-table-td">{metaObject.formatter ? metaObject.formatter(tdData.total ?? 0, withDecimals) : tdData.total ?? 0}</Col>
      ) : ('')}
      {period.map((year) => (
        <Col span={3} className="data stats-table-td" key={`data-${year}`}>
          {metaObject.formatter ? metaObject.formatter(tdData[year] ?? 0, withDecimals) : tdData[year] ?? 0}
        </Col>
      ))}
      {tableType !== 'surfaces' ? (
        <Col
          key="frmt-col-r"
          span={3}
          className="data stats-table-td"
        >
          {metaObject.formatter ? metaObject.formatter(tdData.prior ?? 0, withDecimals) : tdData.prior ?? 0}
        </Col>
      ) : ('')}
    </Row>
  );
}
