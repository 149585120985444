/**
 *
 * @param {Object} row table row bieng rendered
 * @param {Object} column table column used to get the cell value and the other colmn options.
 * @returns value extracted from the object
 * @getValue a function to extract the translated object
 */
function getValue(data, index) {
  if (data === null || !data) {
    return '';
  }
  if (data.translation) {
    return data.translation[index];
  }
  return data[index];
}

// the child in this formatter is the region because i had this JSON object {Region: Country:{}}
function ObjectFormatter(
  row,
  column,
  value,
) {
  if (row === null || column === null) {
    return getValue(value, [column.requiredObjectIndex]);
  }
  if (typeof column.child !== 'undefined') {
    return getValue(row[column.child][column.id], [column.requiredObjectIndex]);
  }
  return getValue(row[column.id], [column.requiredObjectIndex]);
}

export { getValue, ObjectFormatter };
