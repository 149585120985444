import {
  createContext, useContext, useReducer, useEffect,
  useState,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

const ParameteresContext = createContext([]);

const initialState = {
  values: [],
  isLoading: false,
  isError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fetch_start':
      return {
        ...state,
        isLoading: true,
      };
    case 'fetch_success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        values: action.payload,
        updateFunc: action.updateFunc,
      };
    case 'fetch_error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

function ParameteresProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [updateContext, setUpdateContext] = useState(undefined);
  async function fetchParameteres() {
    dispatch({ type: 'fetch_start' });

    try {
      const result = await api.get('/parameters/');
      dispatch({ type: 'fetch_success', payload: result, updateFunc: setUpdateContext });
    } catch (error) {
      dispatch({ type: 'fetch_error' });
    }
  }

  useEffect(() => {
    fetchParameteres();
  }, [updateContext]);

  return (
    <ParameteresContext.Provider value={state}>
      {children}
    </ParameteresContext.Provider>
  );
}

ParameteresProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useParameteres() {
  const value = useContext(ParameteresContext);
  if (isUndefined(value)) {
    throw new Error('useParameteres must be used inside an ParameteresProvider component');
  }

  return value;
}

export {
  ParameteresProvider, useParameteres,
};
