import React, { useEffect, useState } from 'react';
import {
  Pagination, Card, Skeleton, Empty, Row, Col, Popconfirm, Typography,
} from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import TwoLinesIcon from 'assets/images/TwoLinesIcon';
import { useLocalization } from 'contexts/LocalizationContext';

// Styles
import PropTypes from 'prop-types';
import '../assets/styles/datatable.css';

// Table components

import TableRow from './TableRow';
import TableHeader from './TableHeader';

const { Text } = Typography;
function DataTable(props) {
  const {
    columns,
    paginationPerPage,
    paginationTotalResult,
    handleCommit,
    isPaginatble,
    translateColumns,
    dataSource,
    fetchData,
    sortTable,
    handleBulkDelete,
    showBulkActions,
    rowRenderer,
    groupsRows,
  } = props;

  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  // States
  const [ds, setDS] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsCounter, setSelectedRowsCounter] = useState(0);
  const [parentChecked, setParentChecked] = useState(false);
  const [page, setPage] = useState(1);
  // init rows
  useEffect(() => {
    setDS(dataSource);
  }, [dataSource]);

  function onBulkDelete() {
    handleBulkDelete(selectedRows);
    setSelectedRows([]);
    setParentChecked(false);
  }

  // update selected rows counter
  useEffect(() => {
    setSelectedRowsCounter(selectedRows.length);
  }, [selectedRows]);
  function handleRowCommit(row, rowArrayIndex) {
    const accepted = handleCommit(row, rowArrayIndex);
    return accepted;
  }
  function handleSort(columnId, sortSense) {
    setPage(1);
    sortTable({ col: columnId, sense: sortSense });
  }
  function paginate(p) {
    setPage(p);
    fetchData(p);
  }
  useEffect(() => {
    if (parentChecked === true) {
      return setSelectedRows(dataSource);
    }
    return setSelectedRows([]);
  }, [parentChecked]);
  // default empty table
  let rows = (
    <>
      <Row
        style={{
          backgroundColor: 'var(--secondary-color)',
          fontWeight: 'bold',
          height: '50px',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignContent: 'center',
          paddingLeft: '10px',
          paddingRight: '10xp',
        }}
      >
        {columns
          .filter((c) => c.name !== 'delete' && c.name !== 'edit')
          .map((column) => (
            <Col span={24 / columns.length} key={column.id ?? column.key}>
              {translateColumns === true ? locObject.translate(column.translationKey) : `${column.name}`}
            </Col>
          ))}
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          padding: '5vw',
        }}
      >
        <Col span={24}>
          <Empty description={locObject.translate('noData')} imageStyle={{ height: '15vw' }} />
        </Col>
      </Row>
    </>
  );
  let tableRow = (row, rowArrayIndex) => (
    <TableRow
      key={row.id}
      row={row}
      rowArrayIndex={rowArrayIndex}
      setSelectedRows={setSelectedRows}
      selectedRows={selectedRows}
      parentChecked={parentChecked}
      handleRowCommit={handleRowCommit}
      {...props}
    />
  );

  // check if there is a row render function
  if (rowRenderer) {
    tableRow = (row, rowArrayIndex) => rowRenderer(row, props, rowArrayIndex, handleCommit);
  }
  if (ds) {
    if (typeof ds !== 'string' && ds.length > 0) {
      rows = ds.map((row, rowArrayIndex) => tableRow(row, rowArrayIndex));
      return (
        <div>
          <table className="dt-table">
            <thead>
              <tr>
                {columns.map((column) => (
                  <TableHeader
                    key={column.id ?? column.key}
                    column={column}
                    onSort={handleSort}
                    setParentChecked={setParentChecked}
                    parentChecked={parentChecked}
                    {...props}
                  />
                ))}
              </tr>
              {groupsRows}
            </thead>
            <tbody className="d-100">{rows}</tbody>
            <tfoot />
          </table>

          <Card
            className="dt-table-footer"
            hidden={isPaginatble === false && showBulkActions === false}
          >
            {isPaginatble && (
              <Pagination
                onChange={paginate}
                pageSize={paginationPerPage}
                total={paginationTotalResult}
                showSizeChanger={false}
                current={page}
                responsive
                showTotal={(total) => `${total} ${locObject.translate('results')}`}
                showQuickJumper
                size="small"
                className="dt-table-pagination"
              />
            )}
            {showBulkActions && (
              <div className="dt-table-footer-left-area">
                <TwoLinesIcon className="bulk-delete-left-arrow" />
                <Popconfirm
                  onConfirm={onBulkDelete}
                  title={locObject.translate('transformation_delete_button_messege')}
                >
                  <DeleteFilled className="bulk-delete-trash" />
                </Popconfirm>
                {selectedRowsCounter > 0 && (
                  <Text italic className="selected-row-counter">
                    {selectedRowsCounter}
                    {' '}
                    sélectionnées
                  </Text>
                )}
              </div>
            )}
          </Card>
          <Card className="dt-table-footer" hidden={(isPaginatble !== false && showBulkActions === false) || (isPaginatble !== false && showBulkActions !== false)}>
            <p style={{ float: 'right' }}>{`${paginationTotalResult} ${locObject.translate('results')}`}</p>
          </Card>
        </div>
      );
    }
    return rows;
  }
  // wait for the data
  return <Skeleton loading active />;
}
DataTable.defaults = {
  // dataSoure: PropTypes.shape.isRequired,
  showBulkActions: true,
  isEditable: true,
  isPaginatble: true,
};

DataTable.propTypes = {};

export default DataTable;
