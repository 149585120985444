import { useState, useEffect } from 'react';
import '../assets/styles/datatable.css';

function TableData(props) {
  const {
    isEditable, keyGetter, column, tableRow, commitRowValues, rowEditing, setParentToNotSelected, cellCustomStyle,
  } = props;
  const Editor = column.editor;
  // check if the column editable
  const editable = column.editable ?? true;
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [tdSelected, setTdSelected] = useState('');
  // Verify coming Data :

  function onCellEdit() {
    if (isEditable !== true) {
      return setIsReadOnly(true);
    }
    return setIsReadOnly(false);
  }

  useEffect(() => {
    if (setParentToNotSelected === true) {
      setIsReadOnly(true);
    }
  }, [setParentToNotSelected]);

  function valueNotChaged() {
    setIsReadOnly(true);
  }
  function commitChanges() {
    // ignore action if the user is using the edit button
    if (rowEditing === false) {
      commitRowValues();
      setIsReadOnly(true);
    }
    setTdSelected('');
  }
  function onBlur(changed) {
    if ((isReadOnly === false && changed !== undefined) || changed === true) {
      return commitChanges();
    }
    setTdSelected('');
    return setIsReadOnly(true);
  }
  if (editable === true) {
    return (
      <td id={keyGetter}>
        <div
          className={`dt-table-data d-100 h-100 ${tdSelected}`}
          role="textbox"
          onFocus={() => setTdSelected('dt-table-data-selected')}
          onBlur={() => setTdSelected('')}
        >
          <Editor
            commitCellValues={commitChanges}
            keyGetter={keyGetter}
            enterEditingMod={onCellEdit}
            editorLostFocus={onBlur}
            isReadOnly={isReadOnly}
            valueNotChaged={valueNotChaged}
            commitRowValues={commitRowValues}
            {...column.editorProps}
            {...props}
          />
        </div>
      </td>
    );
  }
  return (
    <td id={keyGetter}>
      <div
        className={`dt-table-data d-100 h-100 ${tdSelected}`}
        role="textbox"
        onFocus={() => setTdSelected('dt-table-data-selected')}
        onBlur={() => setTdSelected('')}
      >
        <Editor
          keyGetter={keyGetter}
          isReadOnly
          isEditable={false}
          column={column}
          tableRow={tableRow}
          {...column.editorProps}
          valueNotChaged={valueNotChaged}
        />
      </div>
    </td>
  );
}

TableData.propTypes = {

};
export default TableData;
