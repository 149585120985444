import { useAuth } from 'contexts/AuthContext';
/**
 * Wrapper around a component that requires auth to be displayed.
 *
 * Redirects to nonAuth page if someone tries to render the children without being authorized.
 */

function checkSuperAdmin(user) {
  if (user.roles.includes('ROLE_SUPER_ADMIN')) {
    return true;
  }
  return false;
}
function RequireSuperAdmin({ children, listKey }) {
  const { user } = useAuth();

  if (user.roles.includes('ROLE_SUPER_ADMIN')) {
    return children;
  }
  return '';
}

// RequireSuperAdmin.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export { RequireSuperAdmin, checkSuperAdmin };
