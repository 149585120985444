import { Col, Row, Empty } from 'antd';
import '../assets/styles/homeStatsTable.css';
import { useLocalization } from 'contexts/LocalizationContext';
import StatsTableNumberFormatter from './formatters/StatsTableNumberFormatter';

export default function HomeRegionStatsDataTable(props) {
  const loc = useLocalization();
  const { locObject } = loc;
  const {
    columns, data, metaData, period,
  } = props;

  const finalData = [];

  if (data) {
    Object.keys(data).forEach((key) => {
      finalData.push({ title: key, data: data[key] });
    });
  }

  return (
    <div className="w-100">
      <Row className="w-100 stats-table-thead">
        {columns.map((thd, ind) => (ind === 0
          ? <Col className="stats-table-td" span={6} key={thd}>{thd}</Col>
          : <Col className="stats-table-td-headers" span={3} key={thd}>{thd}</Col>))}
      </Row>
      {finalData.length !== 0 ? (
        <>
          {finalData.map((d) => (
            <Row className="stats-table-tr">
              <Col key={`tt${d.title}`} span={6} className="stats-table-td" style={d.title.includes('Total') || d.title.includes('TOTAL') ? { fontWeight: 'bold' } : null}>{d.title}</Col>
              <Col key="ha" style={d.title.includes('Total') || d.title.includes('TOTAL') ? { fontWeight: 'bold' } : null} span={3} className="stats-table-td">ha</Col>
              {period.map((year) => (
                <Col style={d.title.includes('Total') || d.title.includes('TOTAL') ? { fontWeight: 'bold', width: '10%' } : null} span={3} className="data stats-table-td" key={`data-${year}`}>
                  {StatsTableNumberFormatter(d.data[year], true)}
                </Col>
              ))}
            </Row>
          ))}
        </>
      ) : (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            padding: '5vw',
          }}
        >
          <Col span={24}>
            <Empty description={locObject.translate('noData')} imageStyle={{ height: '15vw' }} />
          </Col>
        </Row>
      )}
    </div>
  );
}
