import { useEffect, useState } from 'react';
import {
  Form, Button, Input, Select, Row, Col, Space, Typography, Alert, InputNumber,
} from 'antd';
import { getValue } from 'components/formatters/ObjectFormatter';
import { useLocalization } from 'contexts/LocalizationContext';
import { CSSTransition } from 'react-transition-group';
import { CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import { useProductIntervals } from 'contexts/ProductIntervals';
import checkNumber from 'components/validators/positifNumber';
import intlNumberFormater from 'components/formatters/intlNumberFormater';
import { formatString, toNum } from '../../services/shared';

const {
  Text,
} = Typography;
const { Option } = Select;

let longFibersIntervals;
let shortFibersIntervals;
let strawIntervals;
function FirstTransformation(props) {
  const [form] = Form.useForm();
  const {
    type, company, date, dataSource, dispatch, onSubmitData, accounts, qualities, productsIds, onSubmit, monthAlreadyClose,
  } = props;

  // states

  const [userHasStrawAccount, setUserHasStrawAccount] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [calcSurfaces, setCalcSurfaces] = useState();
  const [surfaceHaSuggession, setSurfaceHaSuggession] = useState(0);
  const [account, setAccount] = useState({ balance: 0, productID: '' });
  const [regionId, setRegionId] = useState('');
  const [outStockRegionId, setOutStockRegionId] = useState(0);
  const [strawAmount, setStrawAmount] = useState(0);
  const [productsAlert, setProductsAlert] = useState({ productType: '', type: '' });
  const [quantityAlert, setQuantityAlert] = useState({ msg: '', type: '' });
  const [productIntervalAlert, setProductIntervalAlert] = useState({ fiberType: '', msg: '' });
  const [alert, setAlert] = useState({ show: false, msg: '', type: 'error' });
  const [surfaceValidation, setSurfaceValidation] = useState({ status: 'success', help: '' });
  // Contexts
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`; const productIntervals = useProductIntervals();

  // products ids
  const {
    strawId, clfId, dlfId, rsfId, ssfId, sssfId,
  } = productsIds;

  useEffect(() => {
    if (productIntervals.values.length > 0) {
      const longFibersTotalIntervals = productIntervals.values.find((p) => p.id === clfId);
      const shortFibersTotalIntervals = productIntervals.values.find((p) => p.id === rsfId);
      const strawTotalIntervals = productIntervals.values.find((p) => p.id === strawId);
      const [int1, int2] = shortFibersTotalIntervals.quantity;
      const [int3, int4] = longFibersTotalIntervals.quantity;
      shortFibersIntervals = { min: Number(int1), max: Number(int2) };
      longFibersIntervals = { min: Number(int3), max: Number(int4) };
      strawIntervals = { min: Number(strawTotalIntervals.quantity[0]), max: Number(strawTotalIntervals.quantity[1]) };
    }
    //
  }, [type]);

  // if there is no FLEX_STRAW then the user can't make the first transformation

  useEffect(() => {
    setUserHasStrawAccount(strawId !== '' && strawId !== false);
  }, [strawId]);

  function validateSurface(surfaceHa, straw) {
    setSurfaceValidation({ status: 'success', help: '' });
    if (surfaceHa === '') {
      setSurfaceValidation({ status: 'success', help: '' });
      return true;
    }
    if (checkNumber(surfaceHa) !== true) {
      return setSurfaceValidation({ status: 'error', help: checkNumber(surfaceHa) });
    }
    if (straw / Number(surfaceHa) < strawIntervals.min || straw / Number(surfaceHa) > strawIntervals.max) {
      return setSurfaceValidation({
        status: 'warning',
        help:
  <span>
    {locObject.translateWithReplace(
      'transformation_surface_range_warning',
      {
        placeholder: 'x1',
        value: intlNumberFormater(strawIntervals.min, false),
      },
      {
        placeholder: 'x2',
        value: intlNumberFormater(strawIntervals.max, false),
      },

    )}
  </span>,
      });
    }
    return true;
  }
  function handleSurfaceChange(e) {
    validateSurface(e.target.value, strawAmount);
    setCalcSurfaces(e.target.value);
    form.validateFields([clfId, dlfId, rsfId, ssfId, sssfId]);
  }
  function calculateSurfaces(e) {
    setStrawAmount(Number(e.target.value));
    const calculatedSurfaces = (e.target.value === '' ? undefined : Number(e.target.value)) / Number(account.yield);
    setSurfaceHaSuggession(Number.isNaN(calculatedSurfaces) ? '' : `Suggestion: ${intlNumberFormater(calculatedSurfaces)}`);
    setCalcSurfaces(Number.isNaN(calculatedSurfaces) ? '' : calculatedSurfaces);
  }
  function onChooseProduct(val, element) {
    setUserHasStrawAccount(false);
    setCalcSurfaces(0);
    form.resetFields(['quantity', 'surface']);
    setSurfaceValidation({ status: 'success', help: '' });
    setRegionId(element.id);
    const response = dataSource.getAccountRatio(element.key);
    const responseBalance = dataSource.getAccountBalance(element.key);
    const reposnseOutStockRegion = dataSource.getClientRegion(element.enm);
    response.then(({ data }) => {
      const acc = {
        balance: 0, yield: 0, productID: '', year: 2022,
      };
      if (data.yield <= 0) {
        setUserHasStrawAccount(false);
        setAlert({ show: true, msg: locObject.translate('transformation_noSold_err'), type: 'warning' });
      } else {
        setUserHasStrawAccount(true);
        setAlert({ show: false });
        acc.yield = Number(data.yield);
        acc.productID = element.product;
      }

      responseBalance.then((resp) => {
        if (resp.data.balance.amount <= 0) {
          setUserHasStrawAccount(false);
          setAlert({ show: true, msg: locObject.translate('transformation_noSold_err'), type: 'warning' });
        } else {
          setUserHasStrawAccount(true);
          setAlert({ show: false });
          acc.balance = resp.data.balance.amount;
          acc.year = element.year;
          setAccount(acc);
        }
      });
      reposnseOutStockRegion.then((resp) => {
        setOutStockRegionId(resp.data[0].id);
      });
    });
  }

  function calculateRation(productAmount) {
    if (Number.isNaN(productAmount) === false && Number.isNaN(strawAmount) === false) {
      const ration = (productAmount / strawAmount) * 100;
      return ration;
    }
    return '';
  }
  function productsValidator(interval, fiberType, p1, p2, p3) {
    if ((!p1 || p1 === '') && (!p2 || p2 === '') && (!p3 || p3 === '')) {
      setProductIntervalAlert({ fiberType: '' });

      return true;
    }
    const productsSum = Number(p1 ?? 0) + Number(p2 ?? 0) + Number(p3 ?? 0);
    let wrongRange = false;
    if (productsSum / calcSurfaces > Number(interval.max)) { wrongRange = true; }
    if (productsSum / calcSurfaces < Number(interval.min)) { wrongRange = true; }
    setProductIntervalAlert({ fiberType: '' });
    if (wrongRange === true) {
      setProductIntervalAlert({
        fiberType,
        msg: locObject.translateWithReplace(
          fiberType === 'short' ? 'transformation_shortFibers_range_warning' : 'transformation_longFibers_range_warning',
          {
            placeholder: 'x1',
            value: intlNumberFormater(interval.min, false),
          },
          {
            placeholder: 'x2',
            value: intlNumberFormater(interval.max, false),
          },
        ),
      });
    }
    return !wrongRange;
  }
  function onValidate(values) {
    const outStock = {};
    Object.assign(outStock, values);
    delete outStock.productType;
    const [
      p1, p2, p3, p4, p5, quan, quality,
    ] = Object.values(outStock);
    // at least one transformation out stock should be specified
    const sum = (toNum(p1) ?? 0) + (toNum(p2) ?? 0) + (toNum(p3) ?? 0) + (toNum(p4) ?? 0) + (toNum(p5) ?? 0);
    if (!p1 && !p2 && !p3 && !p4 && !p5) {
      return setAlert({ show: true, msg: locObject.translate('transformation_firstTransformation_atlestOne_err'), type: 'error' });
    }
    if (sum <= 0) {
      return setAlert({ show: true, msg: locObject.translate('transformation_firstTransformation_biggerThanZero_err'), type: 'error' });
    }
    if (sum > toNum(quan)) {
      setProductsAlert({ productType: 'all', type: 'error' });
      return setAlert({ show: true, msg: locObject.translate('transformation_firstTransformation_totalQuantity_err'), type: 'error' });
    }
    if (Number(calcSurfaces) <= 0) {
      setProductsAlert({ productType: 'all', type: 'error' });
      return setAlert({ show: true, msg: locObject.translate('transformation_surface_zero_err'), type: 'error' });
    }
    if (date === 0 || !date) {
      return setAlert({ show: true, msg: locObject.translate('transformation_date_mandetory_err'), type: 'error' });
    }

    // everything is Ok submitt the form

    setIsSubmitted(true);
    setProductsAlert({ productType: 'all', type: 'sucess' });
    setAlert({ show: false });
    setIsLoading(true);

    // In Stock (Straw)

    const inStock = {
      accountRegion: Number(regionId),
      accountProduct: Number(account.productID),
      accountYear: account.year,
      amount: -parseFloat(strawAmount),
      surface: -Number(calcSurfaces),
    };

    // Out Stock
    const totalMovements = Object.entries(outStock).filter(([k, v]) => v !== undefined && v !== '' && k !== 'quantity' && k !== 'quality' && parseFloat(v) !== 0).map(([key, val]) => {
      const row = {
        accountRegion: Number(outStockRegionId),
        accountProduct: Number(key),
        accountYear: account.year,
        amount: parseFloat(val),
        surface: 0,
      };
      // if (Number(key) === Number(clfId)) {
      //   row.accountQuality = Number(quality);
      // }
      return row;
    });

    totalMovements.push(inStock);
    const newTransformation = {
      date: date.format('YYYY-MM-DD'),
      type,
      movements: totalMovements,
      company,
    };
    const response = onSubmitData(newTransformation);
    response.then((status) => {
      setIsLoading(false);
      setIsSubmitted(false);
      setProductIntervalAlert({ fiberType: '' });
      setAccount({ balance: 0, productID: '' });
      form.resetFields();
      dispatch({ type: 'non' });
      onSubmit(date);
      return true;
    }).catch((err) => {
      setIsLoading(false);
      setIsSubmitted(false);
    });
    setIsLoading(false);
    return true;
  }
  return (
    <CSSTransition
      in
      timeout={500}
      appear
      classNames="form"
      unmountOnExit
    >
      <Space size="middle" direction="vertical" className="d-100">
        {alert.show === true && (
        <Alert
          type={alert.type}
          message={alert.msg}
        />
        )}
        <Form
          onFinish={onValidate}
          name="0"
          labelAlign="left"
          form={form}
          colon={false}
          layout="vertical"
        >
          <Row className="d-100" gutter={[10, 20]} wrap align="top">
            <Col sm={24} md={12}>
              <Row gutter={[0, 10]} justify="start">
                <Col span={19}>
                  <Form.Item
                    required
                    label={<Text strong>{locObject.translate('productType')}</Text>}
                    name="productType"
                  >
                    <Select
                      placeholder={locObject.translate('transformation_firstTransformation_entryProduct_ph')}
                      loading={accounts.isLoading === true}
                      onChange={onChooseProduct}
                    >
                      {accounts.values !== null ? accounts.values.filter((acc) => (acc.account.product.id === strawId)).map((acc) => (
                        <Select.Option key={acc.account.id} id={acc.account.region.id} product={acc.account.product.id} year={acc.account.year} enm={acc.account.region.country.enumType}>
                          {formatString(getValue(acc.account.product, lang), acc.account.region.country.translation[lang], acc.account.year, acc.balance.amount)}
                        </Select.Option>
                      )) : ''}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Row gutter={[0, 10]}>
                    <Col span={20} style={{ display: 'flex', alignItems: 'center' }}>
                      <Form.Item
                        name="quantity"
                        label={<Text strong>{locObject.translate('transformation_firstTransformation_quantityfield_title')}</Text>}
                        dependencies={['productType']}
                        rules={[{ required: true, message: locObject.translate('transformation_firstTransformation_quantityfield_mandetory_err') }, ({ getFieldValue }) => ({
                          required: true,
                          validator(e, value) {
                            if (!value || value === '') {
                              validateSurface('');
                              setCalcSurfaces('');
                              setProductIntervalAlert({ fiberType: '' });
                              return Promise.resolve();
                            }
                            if ((account.balance === 0 && !getFieldValue('productType')) || getFieldValue('productType') === '') {
                              return Promise.reject(new Error(locObject.translate('transformation_firstTransformation_entryProduct_ph')));
                            }
                            if (value > account.balance && account.productID !== '') {
                              return Promise.reject(new Error(`${locObject.translate('transformation_quantityfield_limits_err')}  ${account.balance} kg`));
                            }
                            if (checkNumber(value) !== true) {
                              return Promise.reject(new Error(checkNumber(value)));
                            }
                            return Promise.resolve();
                          },
                        }),
                        ]}
                      >
                        <Input
                          value={strawAmount}
                          disabled={!userHasStrawAccount}
                          onChange={calculateSurfaces}
                          suffix="kg"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[0, 10]}>
                    <Col span={24}>
                      <Form.Item validateStatus={surfaceValidation.status} help={surfaceValidation.help} label={<Text strong>{locObject.translate('transformation_firstTransformation_surfaceField_label')}</Text>} required>
                        <Input
                          onChange={handleSurfaceChange}
                          disabled={!userHasStrawAccount}
                          suffix="ha"
                          placeholder={surfaceHaSuggession}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Col>
            <Col sm={24} md={24} lg={12}>
              <div className="long-fibers-container d-100">
                <Row gutter={[2, 10]} className="d-100">
                  <Col xs={24} sm={24} md={13}>
                    <Form.Item
                      label={<Text strong>{locObject.translate('classedLongFiber')}</Text>}
                      name={clfId}
                      required={false}
                      dependencies={[dlfId]}
                      rules={[{
                        required: true,
                        validator: (_, value) => {
                          if (!value || value === '') {
                            return Promise.resolve();
                          }

                          if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                          return Promise.resolve();
                        },
                      },
                      ({ getFieldValue }) => ({
                        warningOnly: true,
                        validator(_, value) {
                          const valid = productsValidator(longFibersIntervals, 'long', value, getFieldValue(dlfId));
                          return valid !== true ? Promise.reject(new Error()) : Promise.resolve();
                        },
                      }),

                      ]}
                    >
                      <Input
                        disabled={!userHasStrawAccount || (calcSurfaces === 0 && surfaceHaSuggession === 0)}
                        suffix="kg"
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={6} lg={5}>
                    <Form.Item label={<Text strong>Qualité</Text>} name="quality">
                      <Select loading={qualities.loading}>
                        {qualities.values?.map((quality) => (
                          <Option key={quality.id} qid={quality.id}>{quality.translation[lang]}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} sm={24} md={6} lg={5}>
                    <Form.Item dependencies={[clfId]} label="  ">
                      {({ getFieldValue }) => (
                        <InputNumber disabled={!userHasStrawAccount} readOnly addonAfter="%" precision={1} decimalSeparator="," value={calculateRation(getFieldValue(clfId))} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[2, 10]}>
                  <Col xs={24} sm={24} md={13}>
                    <Form.Item
                      label={<Text strong>{locObject.translate('nonClassedLongFiber')}</Text>}
                      name={dlfId}
                      dependencies={[clfId]}
                      required={false}
                      rules={[{
                        required: true,
                        validator: (_, value) => {
                          if (!value || value === '') {
                            return Promise.resolve();
                          }

                          if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                          return Promise.resolve();
                        },
                      },
                      ({ getFieldValue }) => ({
                        warningOnly: true,
                        validator(_, value) {
                          const valid = productsValidator(longFibersIntervals, 'long', value, getFieldValue(clfId));
                          return valid !== true ? Promise.reject(new Error()) : Promise.resolve();
                        },
                      }),

                      ]}
                    >
                      <Input
                        disabled={!userHasStrawAccount || (calcSurfaces === 0 && surfaceHaSuggession === 0)}
                        suffix="kg"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={5}>
                    <Form.Item dependencies={[dlfId]} label="  ">
                      {({ getFieldValue }) => (
                        <InputNumber disabled={!userHasStrawAccount} readOnly addonAfter="%" precision={1} decimalSeparator="," value={calculateRation(getFieldValue(dlfId))} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row hidden={productIntervalAlert.fiberType !== 'long'} className="products-alert">
                  <Col span={14}>
                    <Alert
                      message={productIntervalAlert.msg}
                      type="warning"
                    />
                  </Col>
                </Row>
              </div>
              <div className="short-fibers-container">

                <Row gutter={[2, 10]}>
                  <Col xs={24} sm={24} md={15}>
                    <Form.Item
                      label={<Text strong>{locObject.translate('rawShortFiber')}</Text>}
                      name={rsfId}
                      dependencies={[sssfId, ssfId]}
                      required={false}
                      rules={[{
                        required: true,
                        validator: (_, value) => {
                          if (!value || value === '') {
                            return Promise.resolve();
                          }

                          if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                          return Promise.resolve();
                        },
                      },
                      ({ getFieldValue }) => ({
                        warningOnly: true,

                        validator(_, value) {
                          const valid = productsValidator(shortFibersIntervals, 'short', value, getFieldValue(sssfId), getFieldValue(ssfId));
                          return valid !== true ? Promise.reject(new Error()) : Promise.resolve();
                        },
                      }),

                      ]}
                    >
                      <Input
                        disabled={!userHasStrawAccount || (calcSurfaces === 0 && surfaceHaSuggession === 0)}
                        suffix="kg"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={5}>
                    <Form.Item dependencies={[rsfId]} label="  ">
                      {({ getFieldValue }) => (
                        <InputNumber disabled={!userHasStrawAccount} readOnly addonAfter="%" precision={1} decimalSeparator="," value={calculateRation(getFieldValue(rsfId))} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[2, 10]}>
                  <Col xs={24} sm={24} md={15}>
                    <Form.Item
                      label={<Text strong>{locObject.translate('semiScutchedShortFiber')}</Text>}
                      name={sssfId}
                      dependencies={[rsfId, ssfId]}
                      required={false}
                      rules={[{
                        required: true,
                        validator: (_, value) => {
                          if (!value || value === '') {
                            return Promise.resolve();
                          }
                          if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                          return Promise.resolve();
                        },
                      },
                      ({ getFieldValue }) => ({
                        warningOnly: true,

                        validator(_, value) {
                          const valid = productsValidator(shortFibersIntervals, 'short', value, getFieldValue(rsfId), getFieldValue(ssfId));
                          return valid !== true ? Promise.reject(new Error()) : Promise.resolve();
                        },
                      }),

                      ]}
                    >
                      <Input
                        disabled={!userHasStrawAccount || (calcSurfaces === 0 && surfaceHaSuggession === 0)}
                        suffix="kg"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={5}>
                    <Form.Item dependencies={[sssfId]} label=" ">
                      {({ getFieldValue }) => (
                        <InputNumber disabled={!userHasStrawAccount} readOnly addonAfter="%" precision={1} decimalSeparator="," value={calculateRation(getFieldValue(sssfId))} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[2, 10]}>
                  <Col xs={24} sm={24} md={15}>
                    <Form.Item
                      label={<Text strong>{locObject.translate('scutchedShortFiber')}</Text>}
                      name={ssfId}
                      dependencies={[rsfId, sssfId]}
                      required={false}
                      rules={[{
                        required: true,
                        validator: (_, value) => {
                          if (!value || value === '') {
                            return Promise.resolve();
                          }
                          if (checkNumber(value) !== true) { return Promise.reject(new Error(checkNumber(value))); }
                          return Promise.resolve();
                        },
                      },
                      ({ getFieldValue }) => ({
                        warningOnly: true,
                        validator(_, value) {
                          const valid = productsValidator(shortFibersIntervals, 'short', value, getFieldValue(rsfId), getFieldValue(sssfId));
                          return valid !== true ? Promise.reject(new Error()) : Promise.resolve();
                        },
                      }),

                      ]}
                    >
                      <Input
                        disabled={!userHasStrawAccount || (calcSurfaces === 0 && surfaceHaSuggession === 0)}
                        suffix="kg"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={5}>
                    <Form.Item dependencies={[ssfId]} label="  ">
                      {({ getFieldValue }) => (
                        <InputNumber disabled={!userHasStrawAccount} readOnly addonAfter="%" precision={1} decimalSeparator="," value={calculateRation(getFieldValue(ssfId))} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row hidden={productIntervalAlert.fiberType !== 'short'} className="products-alert">
                  <Col span={14}>
                    <Alert
                      message={productIntervalAlert.msg}
                      type="warning"
                    />
                  </Col>
                </Row>
              </div>

            </Col>
          </Row>
          <Row justify="end" gutter={[10, 20]}>
            <Col>
              <Button
                icon={<CloseCircleFilled />}
                onClick={() => dispatch({ type: 'non' })}
                danger
              >
                {locObject.translate('cancel')}

              </Button>
            </Col>
            <Col>
              <Button
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={isSubmitted}
                disabled={isSubmitted || monthAlreadyClose}
              >
                {locObject.translate('save')}

              </Button>
            </Col>

          </Row>
        </Form>
      </Space>
    </CSSTransition>
  );
}
export default FirstTransformation;
