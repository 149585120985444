/* eslint-disable react/no-unstable-nested-components */
import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Select } from 'antd';
import { useEffect, useState } from 'react';

const options = (feds, scutchers, translator) => [
  {
    value: 999999,
    label: translator.translate('three_pays'),
    key: 'opt1',
  },
  {
    value: 999998,
    label: translator.translate('home_stats_stocks_table_france'),
    key: 'opt2',
  },
  {
    value: 999993,
    label: translator.translate('home_stats_stocks_table_be_nl'),
    key: 'opt3',
  },
  {
    key: 'fed',
    label: translator.translate('home_stats_stocks_table_federation'),
    options: feds?.map((fed) => ({ label: fed.shortName, value: fed.value, key: fed.value })) ?? [],
  },
  {
    key: 'scutchers',
    label: translator.translate('home_stats_filter_dropdown_companies'),
    options: scutchers?.map((stch) => ({ label: stch.companyName, value: stch.id, key: stch.id })) ?? [],
  },
];

const optionsMultiScutchers = (feds, scutchers, user, translator) => [
  {
    value: 999999,
    label: translator.translate('three_pays'),
    key: 'opt1',
  },
  {
    value: 999998,
    label: translator.translate('home_stats_stocks_table_france'),
    key: 'opt2',
  },
  {
    value: 999993,
    label: translator.translate('home_stats_stocks_table_be_nl'),
    key: 'opt3',
  },
  {
    key: 'fed',
    label: translator.translate('home_stats_stocks_table_federation'),
    options: feds?.map((fed) => ({ label: fed.shortName, value: fed.value, key: fed.value })) ?? [],
  },
  {
    value: 500000 + user.id,
    label: translator.translate('home_stats_filter_dropdown_concat_scutchers'),
    key: 'opt4',
  },
  {
    key: 'scutchers',
    label: translator.translate('home_stats_filter_dropdown_companies'),
    options: scutchers?.map((stch) => ({ label: stch.companyName, value: stch.id, key: stch.id })) ?? [],
  },
];

export default function StatsFilterDropDown({
  user, federations, services, filters, userFederation, valueChanged, defaultValue, translator,
}) {
  const [companies, setCompanies] = useState([]);
  const [feds, setFeds] = useState([]);
  const [loading, setLoading] = useState(false);
  function fetchData(page) {
    setLoading(true);
    if (user.roles.includes('ROLE_SUPER_ADMIN')) {
      // eslint-disable-next-line no-param-reassign
      delete filters.companyFederation;
    }
    services.get(page, undefined, filters, 'company-info').then((data) => {
      let currentCompanies = companies;
      if (!user.roles.includes('ROLE_FED_ADMIN') && user.roles.includes('ROLE_MULTISCUTCHING_ADMIN')) {
        services.getCompanyIdCurrentUser().then((companiesId) => {
          data.forEach((currentCompany) => {
            companiesId.forEach((companyId) => {
              if (currentCompany.id === companyId) {
                currentCompanies = currentCompanies.concat(currentCompany);
              }
            });
          });
          setCompanies(currentCompanies);
        });
      } else {
        setCompanies(data);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    // fetch scutchers
    fetchData(1);
    // filter federations
    if (user.roles.includes('ROLE_SUPER_ADMIN')) {
      setFeds(federations);
    } else {
      const fed = federations.find((f) => f.shortName === userFederation?.shortName);
      setFeds(fed ? [fed] : []);
    }
  }, [userFederation]);
  function handleScroll(e) {
    const scroll = e.target.scrollHeight - e.target.scrollTop;
    if (scroll !== 0 && e.target.clientHeight === scroll && services.currentPage !== Math.ceil(services.totalPages) && services.totalPages > 1) {
      fetchData(services.currentPage + 1);
    }
  }

  return (
    user.roles.includes('ROLE_MULTISCUTCHING_ADMIN') && !user.roles.includes('ROLE_FED_ADMIN') ? (
      <Select
        options={optionsMultiScutchers(feds, companies, user, translator)}
        onPopupScroll={handleScroll}
        onChange={valueChanged}
        style={{ width: 300 }}
        defaultValue={defaultValue}
        dropdownRender={(menu) => (
          <>
            {menu}
            <div hidden={!loading} style={{ textAlign: 'center' }}>
              <Divider />
              <LoadingOutlined />
            </div>
          </>
        )}
      />
    ) : (
      <Select
        options={options(feds, companies, translator)}
        onPopupScroll={handleScroll}
        onChange={valueChanged}
        style={{ width: 300 }}
        defaultValue={defaultValue}
        dropdownRender={(menu) => (
          <>
            {menu}
            <div hidden={!loading} style={{ textAlign: 'center' }}>
              <Divider />
              <LoadingOutlined />
            </div>
          </>
        )}
      />
    )
  );
}
