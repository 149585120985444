import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useEffect, useReducer } from 'react';
import UpDownIcon from 'assets/images/UpDownIcon';
import 'assets/styles/datatable.css';
import { Typography } from 'antd';
import { useLocalization } from 'contexts/LocalizationContext';

function toggleFilterState(state, action) {
  switch (action.count) {
    case 'non':
      return { Arrow: UpDownIcon, count: 'non' };
    case 0:
      action.onSort(action.column.id, 'ASC');
      return { Arrow: ArrowUpOutlined, count: 0 };
    case 1:
      action.onSort(action.column.id, 'DESC');
      return { Arrow: ArrowDownOutlined, count: 1 };
    case 2:
      action.onSort(action.column.id, '');
      return { Arrow: UpDownIcon, count: 2 };
    default:
      return { Arrow: UpDownIcon, count: 'non' };
  }
}
function TableHeader(props) {
  const {
    column, onSort, parentChecked, sorts, translateColumns, groupColumn,
  } = props;

  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;

  const initState = {
    Arrow: UpDownIcon,
    count: 'non',
    onSort,
    column,
  };
  const [FilterSense, dispatch] = useReducer(toggleFilterState, initState);
  function onFilter() {
    dispatch(
      FilterSense.count === 'non'
        ? { count: 0, onSort, column }
        : { count: FilterSense.count + 1, onSort, column },
    );
  }
  useEffect(() => {
    if (sorts !== null && sorts) {
      if (sorts.col !== column.id) {
        dispatch({ count: 'non' });
      }
    }
  }, [sorts]);

  const columnName = translateColumns === true ? locObject.translate(column.translationKey) ?? column.name : `${column.name}`;
  if (column.sortable) {
    return (
      <th className="dt-table-header" colSpan={column.groupColumn ?? 1}>
        <Typography.Text className="d-100 h-100 justify-center-left" style={column.style ?? {}}>
          {columnName}
          <FilterSense.Arrow onClick={onFilter} className="dt-table-header-filter-button" />
        </Typography.Text>
      </th>
    );
  }
  return (
    <th className={`dt-table-header ${column.headerStyle}`} style={column.style ?? {}} colSpan={column.groupColumn ?? 1}>
      {column.header && <column.header key={column.key} {...props} parentChecked={parentChecked} />}
      {!column.header && <span>{columnName}</span>}
    </th>
  );
}
TableHeader.defaults = {
  // dataSoure: PropTypes.shape.isRequired,
  sorts: new Map(),
};

export default TableHeader;
