import {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';

import AuthService from 'services/authService';

const AuthContext = createContext({
  user: null,
  login: noop,
  oidcLogin: noop,
  logout: noop,
  refresh: noop,
});

/**
 * Provide authenticated user + related actions
 */
function AuthProvider({ children }) {
  const [user, setUser] = useState(AuthService.getCurrentUser());

  const login = useCallback(async (email, password, remember) => {
    const data = await AuthService.login(email, password, remember);
    setUser(AuthService.getCurrentUser());
    return AuthService.getCurrentUser();
  }, []);

  const oidcLogin = useCallback(async (provider, idToken) => {
    const data = await AuthService.oidcLogin(provider, idToken);
    setUser(AuthService.getCurrentUser());

    return data;
  }, []);

  const logout = useCallback(() => {
    AuthService.logout();
    setUser(null);
  }, []);

  const refresh = useCallback(async () => {
    const data = await AuthService.refresh();
    setUser(AuthService.getCurrentUser());

    return data;
  }, []);

  const value = useMemo(() => ({
    user, login, oidcLogin, logout, refresh,
  }), [user, login, oidcLogin, logout, refresh]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useAuth() {
  const value = useContext(AuthContext);
  if (isUndefined(value)) {
    throw new Error('useAuth must be used inside an AuthProvider component');
  }
  return value;
}

export {
  AuthProvider, useAuth,
};
