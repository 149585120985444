import { useState } from 'react';
import TransformationTd from './TransformationTd';

function TransformationRow(props) {
  const {
    row, columns, handleDelete,
  } = props;
  const [subDetailsHidden, setSubDetailsHidden] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  function toggleSubDetails() {
    setSubDetailsHidden(!subDetailsHidden);
    setCollapsed(!collapsed);
  }
  function deleteRow(rowId) {
    // do some remove effects
    handleDelete(rowId ?? row.id);
  }

  row.masterDetails.outStock = row.masterDetails.accountYear === '' ? null : row.masterDetails.outStock;

  return (
    <>
      <tr onClick={toggleSubDetails} className="master-row">
        {columns.map((col) => (col.groupColumn ? col.id.map((c, ind) => (
          <TransformationTd
            key={c}
            value={row.masterDetails[c]}
            column={col}
            ind={ind}
            transId={row.id}
            deleteRow={deleteRow}
            collapsed={collapsed}
          />
        ))
          : (
            <TransformationTd
              key={col.id}
              value={row.masterDetails[col.id]}
              column={col}
              transId={row.id}
              typeRow={row.masterDetails.type}
              deleteRow={deleteRow}
              collapsed={collapsed}
            />
          )))}
      </tr>
      {row.subDetails.map((r) => (
        <tr key={r.id} id={row.id} hidden={subDetailsHidden} className="sub-row">
          {columns.map((col) => (col.groupColumn ? col.id.map((c, ind) => (
            <td key={c + r.id} className="grouped-sub-td" style={col.groupStyle ? col.groupStyle[ind] : {}}>
              {col.groupFormatter ? col.groupFormatter[ind](null, col, r[c], col.groupWithDecimal[ind]) : r[c]}
            </td>
          )) : (
            <td key={col.id + r.id} className="sub-td" style={col.style}>
              {col.formatter ? col.formatter(null, col, r[col.id], col.WithDecimal) : r[col.id]}
            </td>
          )))}
        </tr>
      ))}
    </>
  );
}

export default TransformationRow;
