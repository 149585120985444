import {
  createContext, useContext, useReducer, useEffect,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

const CompanyInfoContext = createContext([]);

const initialState = {
  values: [],
  isLoading: false,
  isError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fetch_start':
      return {
        ...state,
        isLoading: true,
      };
    case 'fetch_success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        values: action.payload,
      };
    case 'fetch_error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

function CompanyInfoProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchCompanyInfo() {
      dispatch({ type: 'fetch_start' });

      try {
        const result = await api.get('/company-info');
        dispatch({ type: 'fetch_success', payload: result.data });
      } catch (error) {
        dispatch({ type: 'fetch_error' });
      }
    }

    fetchCompanyInfo();
  }, []);

  return (
    <CompanyInfoContext.Provider value={state}>
      {children}
    </CompanyInfoContext.Provider>
  );
}

CompanyInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useCompanyInfo() {
  const value = useContext(CompanyInfoContext);

  if (isUndefined(value)) {
    throw new Error('useCompanyInfo must be used inside an CompanyInfoProvider component');
  }

  return value;
}

export {
  CompanyInfoProvider, useCompanyInfo,
};
