import { IdcardFilled, FileSearchOutlined } from '@ant-design/icons';

import {
  Alert, Badge, Space, Typography, Button,
} from 'antd';
import {
  Link, Outlet, useLocation, useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { FederationsProvider } from 'contexts/FederationsContext';
import { useLocalization } from 'contexts/LocalizationContext';
import { useEffect, useState } from 'react';
import { useAuth } from 'contexts/AuthContext';

const { Text } = Typography;
function AdminFragment() {
  const {
    showAlert, name, sId, federationId,
  } = useParams();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const { locObject } = useLocalization();
  const [rights, setRights] = useState(false);

  useEffect(() => { if (!user.roles.includes('ROLE_SUPER_ADMIN')) { setRights(true); } });

  return (
    <FederationsProvider>
      <Space direction="vertical" size="small" className="d-100 h-100">
        { showAlert && (
        <Alert
          type="error"
          message={(
            <Text level={5} className="d-100">
              <Space direction="horizontal" size="middle">
                {locObject.translate('manageScutching_admin_alert')}
                <Text strong>{name}</Text>
                {/* <Badge status="processing" color="red" /> */}
                <Link className="space-arround" to={`/admin/manage-scutching/surfaces/${sId}/${federationId}/true/${name}`}>
                  <Button icon={<FileSearchOutlined />}>
                    {locObject.translate('manageScutching_table_subRow_surfaces')}
                    <Badge status="processing" color="red" style={!pathname.includes('surfaces') ? { display: 'none' } : { marginLeft: '10px' }} />
                  </Button>
                </Link>
                <Link to={`/admin/manage-scutching/transformations/${sId}/${federationId}/true/${name}`}>
                  <Button icon={<FileSearchOutlined />}>
                    {locObject.translate('manageScutching_table_subRow_transformations')}
                    <Badge status="processing" color="red" style={!pathname.includes('transformation') ? { display: 'none' } : { marginLeft: '10px' }} />
                  </Button>

                </Link>
                <Link to={`/admin/manage-scutching/sells/${sId}/${federationId}/true/${name}`}>
                  <Button icon={<FileSearchOutlined />}>
                    {locObject.translate('manageScutching_table_subRow_sales')}
                    <Badge status="processing" color="red" style={!pathname.includes('sells') ? { display: 'none' } : { marginLeft: '10px' }} />
                  </Button>

                </Link>
                {rights ? (
                  <Link to={`/admin/manage-scutching/users/${sId}/${federationId}/true/${name}`}>
                    <Button icon={<FileSearchOutlined />}>
                      {locObject.translate('manageScutching_table_subRow_users')}
                      <Badge status="processing" color="red" style={!pathname.includes('users') ? { display: 'none' } : { marginLeft: '10px' }} />
                    </Button>
                  </Link>
                ) : ('')}
              </Space>
            </Text>
)}
          showIcon
          icon={(
            <IdcardFilled style={{
              fontSize: '3vw',
            }}
            />
)}
        />
        )}
        <Outlet />
      </Space>
    </FederationsProvider>
  );
}
export default AdminFragment;
