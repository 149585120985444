import {
  Card, Descriptions, Space, Typography,
} from 'antd';
import { useLocalization } from '../contexts/LocalizationContext';

const { Text, Title, Paragraph } = Typography;
function Reglement() {
  const { locObject } = useLocalization();
  const customStyle = {
    lineHeight: '2.5',
  };
  return (
    <Card>
      <Space direction="vertical" size="middle">
        <Title level={3}>{locObject.translate('reglement_title')}</Title>
        <ol>
          <li>
            <Paragraph strong>{locObject.translate('reglement_1_title')}</Paragraph>
            <Text>
              {locObject.translate('reglement_1_1_paragraph')}
              <br />
              <br />
              <ul>
                <li>
                  {locObject.translate('reglement_1_1_paragraph_point')}
                  <ul>
                    <li>
                      {locObject.translate('reglement_1_1_paragraph_point_1')}
                    </li>
                    <li>
                      {locObject.translate('reglement_1_1_paragraph_point_2')}
                    </li>
                    <li>
                      {locObject.translate('reglement_1_1_paragraph_point_3')}
                    </li>
                    <li>
                      {locObject.translate('reglement_1_1_paragraph_point_4')}
                    </li>
                    <li>
                      {locObject.translate('reglement_1_1_paragraph_point_5')}
                    </li>
                  </ul>
                </li>
              </ul>
            </Text>
            <Text>
              {locObject.translate('reglement_1_2_paragraph')}
            </Text>
          </li>
          <br />
          <li>
            <Paragraph strong>
              {locObject.translate('reglement_2_title')}
            </Paragraph>
            <Text>
              {locObject.translate('reglement_2_paragraph')}

            </Text>
          </li>
          <br />
          <li>
            <Paragraph strong>
              {locObject.translate('reglement_3_title')}
            </Paragraph>
            <Text>
              {locObject.translate('reglement_3_1_paragraph')}
              <br />
              <br />
              <ul>
                <li>
                  <strong>{locObject.translate('reglement_3_point_1_titre')}</strong>
                  {locObject.translate('reglement_3_point_1_text')}
                </li>
                <li>
                  <strong>{locObject.translate('reglement_3_point_2_titre')}</strong>
                  {locObject.translate('reglement_3_point_2_text')}
                </li>
                <li>
                  <strong>{locObject.translate('reglement_3_point_3_title')}</strong>
                  {locObject.translate('reglement_3_point_3_text')}
                </li>
              </ul>
            </Text>
          </li>
          <li>
            <Paragraph strong>
              {locObject.translate('reglement_4_title')}
            </Paragraph>
            <Text>
              {locObject.translate('reglement_4_1_paragraph')}
              <br />
              <br />
              <ul>
                <li>
                  {locObject.translate('reglement_4_point_1')}
                </li>
                <li>
                  {locObject.translate('reglement_4_point_2')}
                </li>
              </ul>
            </Text>
            <Text>
              {locObject.translate('reglement_4_2_paragraph')}
            </Text>
          </li>
          <br />
          <li>
            <Paragraph strong>
              {locObject.translate('reglement_5_title')}
            </Paragraph>
            <Text>
              {locObject.translate('reglement_5_paragraph')}

            </Text>
            <br />
            <br />
            <Text italic style={customStyle}>
              {locObject.translate('reglement_5_address_coordinate')}
              <br />
              {locObject.translate('reglement_5_address')}
              <br />
              {locObject.translate('reglement_5_address_the_head_social')}
            </Text>
            <br />
            <br />
            <Text>
              {locObject.translate('reglement_5_2_paragraph')}
            </Text>
          </li>
          <br />
          <li>
            <Paragraph strong>
              {locObject.translate('reglement_6_title')}
            </Paragraph>
            <Text>
              {locObject.translate('reglement_6_1_paragraph')}

            </Text>
            <br />
            <br />
            <Text>
              {locObject.translate('reglement_6_2_paragraph')}

            </Text>
          </li>
          <br />
          <li>
            <Paragraph strong>
              {locObject.translate('reglement_7_title')}
            </Paragraph>
            <Text>
              {locObject.translate('reglement_7_1_paragraph')}

            </Text>
            <br />
            <br />
            <Text>
              {locObject.translate('reglement_7_2_paragraph')}

            </Text>
            <br />
            <br />
            <Text>
              {locObject.translate('reglement_7_3_paragraph')}

            </Text>
            <br />
            <br />
            <Text>
              {locObject.translate('reglement_7_4_paragraph')}

            </Text>
          </li>
        </ol>
      </Space>

    </Card>
  );
}

export default Reglement;
