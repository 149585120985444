/* eslint-disable radix */
import { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Card, Col, DatePicker, Row, Space, Typography,
} from 'antd';
import ContributionsStatsDataTable from 'components/ContributionsStatsDataTable';
import WebServices from 'services/webServices';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { useParameteres } from 'contexts/ParameteresContext';
import { useFederations } from 'contexts/FederationsContext';
import moment from 'moment';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import { useLocalization } from 'contexts/LocalizationContext';
import { DownloadOutlined } from '@ant-design/icons';
import '../../../assets/styles/home.css';

const { Text } = Typography;

export default function ContributionAdmin() {
  const { user } = useAuth();
  const { sId } = useParams();
  const loc = useLocalization().locObject;
  const parameters = useParameteres();
  const federationContext = useFederations();
  const services = useMemo(() => new WebServices({}, api, true, 'home', sId ?? user.company), []);
  const [period, setPeriod] = useState(null);
  const [currentFederation, setCurrentFederation] = useState(null);
  const [longFiberSalesStatsData, setLongFiberSalesStatsData] = useState(null);
  const [surfaceSalesStatsData, setSurfaceSalesStatsData] = useState(null);
  const [datesVerficationStatus, setDatesVerficationStatus] = useState('');
  const notify = useNotificationCenter()();
  const [dates, setDates] = useState({ start: null, end: null });

  const dateRange = {
    start: dates.start ? moment(dates.start).format('DD/MM/YYYY') : moment(dates.end).startOf('month').format('DD/MM/YYYY'),
    end: dates.end ? moment(dates.end).endOf('month').format('DD/MM/YYYY') : moment(dates.start).endOf('month').format('DD/MM/YYYY'),
  };

  const tableColumns = (translator, year, statsMonth) => [
    translator.translateWithReplace('home_stats_stocks_table_mois', { placeholder: 'xxxx', value: statsMonth.start }, { placeholder: 'yyyy', value: statsMonth.end }),
    ' ', translator.translate('home_stats_table_total'), `R${year[0]}`, `R${year[1]}`, `R${year[2]}`, `R${year[3]}`, `R${year[4]}`, translator.translate('home_stats_sales_table_prior'),
  ];

  function fetchData(fed, startDate, endDate) {
    setLongFiberSalesStatsData(null);
    setSurfaceSalesStatsData(null);
    services.getContributionsHomePageDetails(fed, startDate, endDate).then(({ data }) => {
      if (data[1]) {
        setLongFiberSalesStatsData(data[1]);
      }
      if (data[2]) {
        setSurfaceSalesStatsData(data[2]);
      }
    });
  }

  useEffect(() => {
    if (parameters.values.data) {
      const date = parameters.values.data.find((param) => param.name === 'statistics.date').value;
      const y = parameters.values.data.find((param) => param.name === 'harvest.year').value;

      setDates({ start: `${date}-01`, end: null });

      const generatedList = [0, 1, 2, 3, 4].map((item) => parseInt(y) - item);
      setPeriod(generatedList);

      if (federationContext.values.items) {
        setCurrentFederation(federationContext.values.items[0].id);
      }
    }
  }, [parameters, federationContext]);

  useEffect(() => {
    fetchData(currentFederation, dates.start, dates.end);
  }, [dates]);

  function changeStartDate(mmt) {
    if (mmt !== null) {
      const date = mmt.startOf('month').format('YYYY-MM-DD');
      if (mmt.isAfter(dates.end)) {
        setDatesVerficationStatus('error');
      } else {
        setDates({ start: date, end: dates.end });
        setDatesVerficationStatus('');
      }
    } else {
      const date = parameters.values.data.find((param) => param.name === 'statistics.date').value;
      setDates({ start: `${date}-01`, end: null });
    }
  }
  function changeEndDate(mmt) {
    if (mmt !== null) {
      const date = mmt.startOf('month').format('YYYY-MM-DD');
      if (mmt.isBefore(dates.start)) {
        setDatesVerficationStatus('error');
      } else {
        setDates({ start: dates.start, end: date });
        setDatesVerficationStatus('');
      }
    } else {
      setDates({ start: dates.start, end: null });
    }
  }

  function exportContributionsStatsPage() {
    services.exportContributionsStatsHomePage(currentFederation, dates.start, dates.end).then((response) => {
      // check if the response is empty
      if (response.data.type === 'application/json') {
        notify({
          msg: loc.translate('nodata_export'), type: 'error', desc: '', duration: 10,
        });
      } else {
        // use a virtual <a> element to trigger the navigator download Event
        const hiddenElement = document.createElement('a');
        const blob = new Blob([response.data]);
        hiddenElement.href = URL.createObjectURL(blob);
        // save the file with the current date
        const date = moment().format('YYYY-MM-SS HH-MM-SS');
        hiddenElement.download = `cotisations-stats-${date}.xlsx`;
        hiddenElement.click();
      }
    });
  }

  return (
    <Card
      size="small"
      style={{ border: 'none' }}
      title={(
        <Button
          onClick={exportContributionsStatsPage}
          type="primary"
          shape="default"
          className="hp-export-btn"
        >
          <DownloadOutlined />
          {loc.translate('export')}
        </Button>
      )}
      headStyle={{ backgroundColor: 'var(--light-secondary)' }}
    >
      <Space direction="vertical" className="w-100" size="small">
        <Space direction="vertical" className="w-100">
          <Row justify="center">
            <Col span={22}>
              <Card size="small" className=" tool-bar">
                <Space direction="horizontal" className="p-5 tool-bar " size="large">
                  <Text strong>{loc.translate('home_stats_toolBar_startDate')}</Text>
                  {dates.start !== null ? (<DatePicker picker="month" onChange={changeStartDate} defaultValue={dates.start ? moment(dates.start) : null} status={datesVerficationStatus} allowClear={false} />) : ('')}
                  <Text strong>{loc.translate('home_stats_toolBar_endDate')}</Text>
                  <DatePicker picker="month" onChange={changeEndDate} defaultValue={dates.end ? moment(dates.end) : null} status={datesVerficationStatus} allowClear={false} />
                </Space>
              </Card>
              <Space direction="horizontal" className="w-100" size="large">
                {/* eslint-disable react/self-closing-comp */}
                <Text></Text>
              </Space>
              {period != null ? (
                <ContributionsStatsDataTable
                  columns={tableColumns(loc, period, dateRange)}
                  data={longFiberSalesStatsData}
                  period={period}
                  metadata={loc.translate('home_contributions_long_fiber')}
                  unit="t"
                />
              ) : ('')}
            </Col>
          </Row>
        </Space>
        <Row justify="center">
          <Col span={22}>
            {period != null ? (
              <ContributionsStatsDataTable
                columns={tableColumns(loc, period, dateRange)}
                data={surfaceSalesStatsData}
                period={period}
                metadata={loc.translate('home_contributions_harvest_surfaces')}
                unit="ha"
                threeDec={false}
              />
            ) : ('')}
          </Col>
        </Row>
      </Space>
    </Card>
  );
}
