import {
  Card, Descriptions, Space, Typography,
} from 'antd';
import { useLocalization } from 'contexts/LocalizationContext';

const { Text, Title, Paragraph } = Typography;
function PrivacyPolicy() {
  const { locObject } = useLocalization();
  return (
    <Card>
      <Space direction="vertical" size="middle">
        <Title level={3}>{locObject.translate('privacy_policy_1')}</Title>
        <Card>
          <Descriptions title="Général" labelStyle={{ fontWeight: 'bold' }}>
            <Descriptions.Item span={2}>{locObject.translate('privacy_policy_2')}</Descriptions.Item>
            <Descriptions.Item label="ÉDITEUR DU SITE" span={2}>{locObject.translate('privacy_policy_3')}</Descriptions.Item>
            <Descriptions.Item label="Adresse" span={3}>{locObject.translate('privacy_policy_4')}</Descriptions.Item>
            <Descriptions.Item label="Association déclarée" span={2} />
            <Descriptions.Item />
            <Descriptions.Item>  RNA : W751022806</Descriptions.Item>
            <Descriptions.Item>  n° TVA FR18325752871</Descriptions.Item>
          </Descriptions>
        </Card>
        <ol>
          <li>
            <Paragraph strong>{locObject.translate('privacy_policy_5')}</Paragraph>
            <Text>
              {locObject.translate('privacy_policy_6')}

            </Text>
          </li>
          <li>
            <Paragraph strong>
              {locObject.translate('privacy_policy_7')}
            </Paragraph>
            <Text>
              {locObject.translate('privacy_policy_8')}

            </Text>
          </li>
          <li>
            <Paragraph strong>
              {locObject.translate('privacy_policy_9')}
            </Paragraph>
            <Text>
              {locObject.translate('privacy_policy_10')}

            </Text>
          </li>
          <li>
            <Paragraph strong>
              {locObject.translate('privacy_policy_11')}
            </Paragraph>
            <Text>
              {locObject.translate('privacy_policy_12')}

            </Text>
          </li>
          <li>
            <Paragraph strong>
              {locObject.translate('privacy_policy_13')}
            </Paragraph>
            <Text>
              {locObject.translate('privacy_policy_14')}

            </Text>
          </li>
          <li>
            <Paragraph strong>
              {locObject.translate('privacy_policy_15')}
            </Paragraph>
            <Text>
              {locObject.translate('privacy_policy_16')}

              {locObject.translate('privacy_policy_17')}
              <ol>
                <li>
                  {locObject.translate('privacy_policy_18')}

                </li>
                <li>
                  {locObject.translate('privacy_policy_19')}
                </li>
                <li>
                  {locObject.translate('privacy_policy_20')}
                </li>
                <li>
                  {locObject.translate('privacy_policy_21')}
                </li>
                <li>
                  {locObject.translate('privacy_policy_22')}

                  {locObject.translate('privacy_policy_23')}
                </li>
              </ol>

            </Text>
          </li>
        </ol>
      </Space>

    </Card>
  );
}

export default PrivacyPolicy;
