import React, { useState, useEffect } from 'react';
// Row styles
import PropTypes from 'prop-types';
import '../assets/styles/datatable.css';
import { Spin } from 'antd';
import TableData from './TableData';

function TableRow(props) {
  // props
  const {
    row, errorsArray,
    columns, rowArrayIndex, parentChecked, selectedRows, setSelectedRows, handleRowCommit, handleDelete,
  } = props;
  // states

  // initiate the row with initial values to send a complete row to the server .
  const [changedRow, setChangedRow] = useState(row);
  const [selectRow, setSelectRow] = useState();
  const [rowEditing, setRowEditing] = useState(false);
  const [errorIndex, setErrorIndex] = useState(null);
  // update and listen to updates from another component.
  // -- there must be a dependencyIndex key in the column object
  const [callDependency, setCallDependency] = useState('');
  const [modificationsCanceled, setModificationsCanceled] = useState(false);
  const [newData, setNewData] = useState(null);
  const [waitingForSubmit, setWaitingForSubmit] = useState(false);
  useEffect(() => {
    if (errorsArray != null) {
      const err = errorsArray.find((r) => r.lineIndex === rowArrayIndex);
      if (err !== null) {
        setSelectRow('dt-row-with-error');
        setErrorIndex(errorsArray[rowArrayIndex].columnIndex);
      }
    }
  }, [errorsArray]);

  function rowHasBeenClicked() {
    setSelectRow('dt-row-selectd ');
    return row;
  }
  function moveFocusTo(id) {
    setCallDependency(`move_${id}`);
  }
  useEffect(() => {
    if (parentChecked) {
      rowHasBeenClicked();
      return setSelectRow('dt-row-selectd ');
    }
    return setSelectRow('');
  }, [parentChecked]);

  function getCellValue(cellIndex) {
    return changedRow[cellIndex];
  }
  function focusOut() {
    if (selectedRows?.every((r) => r.id !== row.id)) {
      setSelectRow('');
    }
  }
  function deleteRow(rowId) {
    // do some remove effects
    handleDelete(rowId ?? row.id);
  }
  function rowHasBeenUnchecked(checked) {
    if (!checked) {
      setSelectedRows(selectedRows?.filter((el) => el.id !== row.id));
      setSelectRow('');
    }
  }
  function addNewCellValue(coId, val) {
    const ff = {};
    Object.assign(ff, changedRow);
    ff[coId] = val;
    setChangedRow(ff);
  }
  function cancelModifications() {
    if (rowEditing === false) {
      setChangedRow(row);
      setNewData();
      setCallDependency();
      setModificationsCanceled(true);
    }
  }

  function enterRowEditingMod() {
    setRowEditing(true);
  }
  // open dependency editor
  function openDependencyEditor(name, val) {
    setNewData(val);
    setCallDependency(name);
  }
  function undoModifications() {
    setRowEditing(false);
    setSelectRow('');
    setSelectedRows(selectedRows?.filter((el, indx) => indx !== rowArrayIndex));
  }
  function commitRowValues() {
    setWaitingForSubmit(true);
    const response = handleRowCommit(changedRow, rowArrayIndex);
    response.then(() => {
      setSelectRow('');
      setRowEditing(false);
      setWaitingForSubmit(false);
    }).catch(() => {
      // there could be a handler for the error here ..
      setSelectRow('');
      setRowEditing(false);
      setWaitingForSubmit(false);
    });
  }
  if (waitingForSubmit === true) {
    return (
      <tr
        id={row.id}
        className={selectRow}
        style={rowEditing === true ? { boxShadow: '0px 0px 4px 0px var(--primary-color)' } : {}}
      >
        <td colSpan={columns.length} height={60}>
          <Spin spinning={waitingForSubmit} className="d-100 no-default" />
        </td>
      </tr>
    );
  }

  return (
    <tr
      id={row.id}
      onFocus={rowHasBeenClicked}
      onBlur={focusOut}
      className={selectRow}
      style={rowEditing === true ? { boxShadow: '0px 0px 4px 0px var(--primary-color)' } : {}}
    >
      {columns.map((column, index) => (
        <TableData
          key={column.id + row.id}
          errorIndex={errorIndex}
          deleteRow={deleteRow}
          cellIndex={index}
          keyGetter={rowArrayIndex}
          column={column}
          tableRow={row}
          cancelModifications={cancelModifications}
          getCellValue={getCellValue}
          commitRowValues={commitRowValues}
          rowHasBeenClicked={rowHasBeenClicked}
          enterRowEditingMod={enterRowEditingMod}
          callDependency={callDependency}
          rowHasBeenUnchecked={rowHasBeenUnchecked}
          rowEditing={rowEditing}
          undoModifications={undoModifications}
          editorHasValue={addNewCellValue}
          openDependencyEditor={openDependencyEditor}
          moveFocusTo={moveFocusTo}
          newData={newData}
          setCallDependency={setCallDependency}
          modificationsCanceled={modificationsCanceled}
          setModificationsCanceled={setModificationsCanceled}
          {...props}
        />
      ))}

    </tr>
  );
}
TableRow.defaultProps = {
};
TableRow.propTypes = {
  handleCommit: PropTypes.func.isRequired,
};

export default TableRow;
