const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  msal: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    redirectUri: 'http://localhost:3000',
    scopes: ['user.read'],
  },
  google: { clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID },
};

export default config;
