import {
  DeleteFilled, CloseCircleFilled,
} from '@ant-design/icons';
import 'assets/styles/datatable.css';
import { Popconfirm } from 'antd';
import { useLocalization } from 'contexts/LocalizationContext';

// using the same check states to say that the button has been clicked
function DeleteButton(props) {
  const {
    deleteRow,
    undoModifications,
    editorLostFocus,
    editorOnFocus, tableRow, isEditable,
    rowEditing,
  } = props;
  const loc = useLocalization();

  function handleCancelModifications() {
    undoModifications();
  }

  function handleDeleteRow() {
    deleteRow(tableRow.transId);
  }

  if (isEditable !== true) {
    return ' ';
  }
  if (rowEditing) {
    return (
      <div className="cell-item d-100 h-100">
        <CloseCircleFilled
          onClick={handleCancelModifications}
          style={{ fontSize: '2vw' }}
          onFocus={editorOnFocus}
          onBlur={editorLostFocus}
        />
      </div>

    );
  }

  return (
    <Popconfirm onConfirm={handleDeleteRow} title={loc.locObject.translate('delete_button_messege')}>
      <div className="dt-table-delete-button-container d-100 h-100">
        <DeleteFilled
          onFocus={editorOnFocus}
          onBlur={editorLostFocus}
        />
      </div>
    </Popconfirm>
  );
}
export default DeleteButton;
