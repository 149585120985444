export default function StatsTableNumberFormatter(val, withDecimals = true) {
  const formatterDecimals = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const formatterWithoutDecimals = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigit: 0,
  });
  return withDecimals === true
    ? formatterDecimals.format(val === 0 ? 0.00 : val)
    : formatterWithoutDecimals.format(val);
}
