import React, { useContext, useCallback, useState } from 'react';
import { notification, Modal } from 'antd';

const NotificationContext = React.createContext('Notification');

export default function NotificationCenter({ children }) {
  const openNotification = useCallback(() => (settings) => {
    if (settings.type !== 'modal') {
      return notification[settings.type]({
        message: settings.msg,
        description: settings.desc,
        duration: settings.duration ?? 3,
      });
    }
    return Modal.confirm(settings);
  }, []);

  return (
    <NotificationContext.Provider value={openNotification}>
      {children}
    </NotificationContext.Provider>
  );
}

function useNotificationCenter() {
  const notificationHandler = useContext(NotificationContext);
  return notificationHandler;
}

export { NotificationCenter, useNotificationCenter };
