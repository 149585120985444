import {
  createContext, useContext, useReducer, useEffect,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

const ClientRegionsContext = createContext([]);

const initialState = {
  values: [],
  isLoading: false,
  isError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'fetch_start':
      return {
        ...state,
        isLoading: true,
      };
    case 'fetch_success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        values: action.payload,
      };
    case 'fetch_error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

function ClientRegionsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchClientRegions() {
      dispatch({ type: 'fetch_start' });

      try {
        const result = await api.get('/regions/client-regions');
        dispatch({ type: 'fetch_success', payload: result.data });
      } catch (error) {
        dispatch({ type: 'fetch_error' });
      }
    }

    fetchClientRegions();
  }, []);

  return (
    <ClientRegionsContext.Provider value={state}>
      {children}
    </ClientRegionsContext.Provider>
  );
}

ClientRegionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useClientRegions() {
  const value = useContext(ClientRegionsContext);

  if (isUndefined(value)) {
    throw new Error('useClientRegions must be used inside an ClientRegionsProvider component');
  }

  return value;
}

export {
  ClientRegionsProvider, useClientRegions,
};
