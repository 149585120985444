import jwtDecode from 'jwt-decode';

const TOKEN_COOCKIE_KEY = '_Atoken_flx';
const TOKEN_REFRESH_COOCKIE_KEY = '_rf_token_flx';
const USER_REMEMBER_ME = '_usr_flx_remember';
let tokenExpiredTimeoutId;
let tokenWillExpireTimeoutId;

function getToken() {
  let cookieValue = document.cookie;

  if (cookieValue) {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line prefer-destructuring
    cookieValue = cookieValue.split('; ').find((row) => row.startsWith(`${TOKEN_COOCKIE_KEY}=`));
    if (cookieValue) {
      // eslint-disable-next-line prefer-destructuring
      cookieValue = cookieValue.split('=')[1];
    }
    return cookieValue;
  }
  return '';
}

function validateRefreshToken() {
  const token = document.cookie.split('; ').find((row) => row.startsWith(`${TOKEN_REFRESH_COOCKIE_KEY}=`)).split('=')[1];
  const decodedToken = jwtDecode(token);
  const ttlMs = decodedToken.exp - Date.now();
  if (ttlMs <= 0) {
    return false;
  }
  return true;
}

function setCookie(name, value, expiration, dateUTC) {
  const curr = expiration * 1000;
  const date = new Date(curr);
  document.cookie = `${name}=${value}; expires=${dateUTC ?? date}; path=/; secure`;
}
function setToken(token) {
  const decodedToken = jwtDecode(token);
  setCookie(TOKEN_COOCKIE_KEY, token, decodedToken.exp);
}

function removeToken() {
  document.cookie = `${TOKEN_COOCKIE_KEY}=; expires=; path=/; secure`;

  clearTimeout(tokenExpiredTimeoutId);
  clearTimeout(tokenWillExpireTimeoutId);
}

function getRefreshToken() {
  let cookieValue = document.cookie;
  if (cookieValue) {
    // eslint-disable-next-line prefer-destructuring
    cookieValue = cookieValue.split('; ').find((row) => row.startsWith(`${TOKEN_REFRESH_COOCKIE_KEY}=`)).split('=')[1];
    return cookieValue;
  }
  return undefined;
}

function setRefreshToken(token, remember) {
  const date = new Date();
  if (remember === true) {
    date.setMonth(date.getUTCMonth() + 3);
    setCookie(USER_REMEMBER_ME, remember, null, date.toUTCString());
    setCookie(TOKEN_REFRESH_COOCKIE_KEY, token, null, date.toUTCString());
  } else {
    date.setHours(date.getHours() + 10);
    setCookie(USER_REMEMBER_ME, false, null, date.toUTCString());
    setCookie(TOKEN_REFRESH_COOCKIE_KEY, token, null, date.toUTCString());
  }
}

function removeRefreshToken() {
  document.cookie = `${TOKEN_REFRESH_COOCKIE_KEY}=; expires=; path=/; secure`;
}

const TokenService = {
  getToken,
  setToken,
  removeToken,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
  validateRefreshToken,
};

export default TokenService;
