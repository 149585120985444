// check  input values

import { BankFilled, LineOutlined } from '@ant-design/icons';
import {
  Badge, Space, Spin, Tag, Typography,
} from 'antd';
import intlNumberFormater from 'components/formatters/intlNumberFormater';
import { checkAdmin } from 'components/RequireAdmin';
import { checkSuperAdmin } from 'components/RequireSuperAdmin';
import { useCountriesRegions } from 'contexts/CountriesRegionsContext';

const { Text } = Typography;
const filterValues = (index) => {
  if (localStorage.getItem('annual-surfaces') && localStorage.getItem('annual-surfaces') !== '{}') {
    return JSON.parse(localStorage.getItem('annual-surfaces'))[index];
  }
  return false;
};

function toNum(num) {
  return num ? Number(num) : 0;
}
// format dropdown string

function formatString(product, region, year, balance, quality) {
  const formattedBalance = intlNumberFormater(balance, false);
  return (
    <Text>
      <Space direction="horizontal" size={3} className="d-100">
        {product}
        {quality > 0 && (
        <Tag color="green">
          {`${quality} ha`}
        </Tag>
        )}
        <LineOutlined />
        {region}
        <LineOutlined />
        {year}
        <LineOutlined />
        <Badge count={`${formattedBalance} kg`} overflowCount={100000} />
      </Space>
    </Text>
  );
}
// could be refactored to be more generic
function CountriesRegionsSuspense({ children }) {
  const countriesRegionsContext = useCountriesRegions();
  if (countriesRegionsContext.isLoading === false) {
    return children;
  }
  return <Spin size="default" spinning style={{ position: 'absolute', right: '50%', top: '50%' }} />;
}
function hideIfNotAdmin(user) {
  if (checkAdmin(user) === true || checkSuperAdmin(user) === true) {
    return false;
  }
  return true;
}

export {
  formatString, toNum, hideIfNotAdmin, filterValues, CountriesRegionsSuspense,
};
