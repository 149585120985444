import {
  createContext, useContext, useReducer, useEffect, Suspense, useState,
} from 'react';
import api from 'services/api';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { useAuth } from './AuthContext';

const UserContext = createContext([]);

function UserContextProvider({ children }) {
  const [state, setState] = useState({ data: [], loading: false });
  const { user } = useAuth();
  useEffect(() => {
    async function fetch() {
      const result = await api.get(`/users/${user.id}`);
      setState({ data: result.data, loading: true });
    }
    if (user) {
      fetch();
    }
  }, [user]);
  return (
    <UserContext.Provider value={state}>
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useUserContext() {
  const value = useContext(UserContext);
  if (isUndefined(value)) {
    throw new Error('useUserContext must be used inside an UserContextProvider component');
  }

  return value;
}

export {
  UserContextProvider, useUserContext, UserContext,
};
