import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import api from 'services/api';

/**
 * Wrapper around a component that requires auth to be displayed.
 *
 * Redirects to nonAuth page if someone tries to render the children without being authorized.
 */

// do not allow an admin to see

function RequireNormalUser({ children }) {
  const [loading, setloading] = useState(true);
  const [u, setU] = useState([]);
  const nav = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    async function fetch() {
      const result = await api.get(`/users/${user.id}`);
      if (result.data.roles.includes('ROLE_SUPER_ADMIN') === true || result.data.roles.includes('ROLE_FED_ADMIN') === true || result.data.roles.includes('ROLE_MULTISCUTCHING_ADMIN') === true) {
        return nav('/admin');
      }
      setU(result.data);
      setloading(false);
      return true;
    }
    fetch();
  }, [user]);

  if (loading === true) {
    return <p>Loading ..</p>;
  }
  return children;
}

RequireNormalUser.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequireNormalUser;
