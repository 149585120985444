import { CloseCircleFilled, InfoCircleFilled, LineOutlined } from '@ant-design/icons';
import {
  Statistic, Row, Col, Card, Space, List, Typography,
} from 'antd';
import { useLocalization } from 'contexts/LocalizationContext';
import _ from 'lodash';
import React, { useState } from 'react';
import intlNumberFormater from './formatters/intlNumberFormater';
import NumberFormatter from './formatters/NumberFormatter';

function TransformationStatistics(props) {
  const {
    stocks,
    stocksDetails,
    showStraw,
    valueStyle,
  } = props;
  // Decimal places
  const decPlaces = 0;
  // contexts
  const loc = useLocalization();
  const { Title, Text } = Typography;
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  // states
  const [showLongFiberDetails, setShowLongFiberDetails] = useState(false);
  const [showShortFiberDetails, setShowShortFiberDetails] = useState(false);
  const [showStrawDetails, setshowStrawDetails] = useState(false);
  // products balances
  const clf = stocksDetails?.filter((p) => p.product_type === 'CLASSIFIED_LONG_FIBER') ?? { balance: 0, product: 'Fibre longue classée' };
  const dlf = stocksDetails?.filter((p) => p.product_type === 'DECLASSIFIED_LONG_FIBER') ?? { balance: 0, product: 'Fibre longue déclassée' };
  const rsf = stocksDetails?.filter((p) => p.product_type === 'RAW_SHORT_FIBER') ?? { balance: 0, product: 'Fibre courte brute' };
  const sssf = stocksDetails?.filter((p) => p.product_type === 'SEMI_SCUTCHED_SHORT_FIBER') ?? { balance: 0, product: 'Fibre courte semi-teillée' };
  const ssf = stocksDetails?.filter((p) => p.product_type === 'SCUTCHED_SHORT_FIBER') ?? { balance: 0, product: ' Fibre courte teillée' };
  const strawSum = stocksDetails !== null ? stocksDetails?.filter((st) => st.product_type === 'FLAX_STRAW') : { balance: 0 };

  const sumOfProduct = (product) => _.sumBy(product, (item) => Number(item.amount));
  const surfaceOfProduct = (product) => _.sumBy(product, (item) => Number(item.surface));

  const flaxStrawSum = sumOfProduct(strawSum);
  const flaxStrawSurface = surfaceOfProduct(strawSum);
  const longFibersSum = sumOfProduct(clf) + sumOfProduct(dlf);
  const shortFibersSum = sumOfProduct(sssf) + sumOfProduct(ssf) + sumOfProduct(rsf);

  const statisque = (product, titleStatistic, specialSuffix) => (
    stocksDetails ? stocksDetails.map((dt) => (
      dt.product_type === product
        ? (
          <List.Item key={dt.amount}>
            <Statistic
              valueStyle={valueStyle}
              title={`${titleStatistic} - ${dt.label_fr} ${dt.year}`}
              value={dt.amount}
              loading={stocks === null}
              decimalSeparator=","
              groupSeparator=" "
              precision={decPlaces}
              suffix={specialSuffix ? (`kg - ${intlNumberFormater(dt.surface, true, 3, 2)} ha`) : 'kg'}
            />
          </List.Item>
        ) : '')) : '');
  return (
    <Row gutter={12}>
      {/* card paille */}
      <Col span={8} hidden={showStrawDetails}>
        <Card className="d-100" size="small" hidden={!showStraw}>
          <Row className="d-100">
            <Col span={18}>
              <Statistic
                valueStyle={valueStyle}
                title={locObject.translate('stats_straw_title')}
                value={flaxStrawSum}
                loading={stocksDetails === null}
                decimalSeparator=","
                groupSeparator=" "
                precision={0}
                suffix="kg"
              />
              <Statistic
                valueStyle={valueStyle}
                title={locObject.translate('stats_straw_surface')}
                value={intlNumberFormater(flaxStrawSurface, true, 3, 2)}
                loading={stocksDetails === null}
                decimalSeparator=","
                groupSeparator=" "
                precision={3}
                suffix="ha"
              />
            </Col>
            <Col span={6}>
              {flaxStrawSum > 0 && (
                <InfoCircleFilled
                  onClick={() => setshowStrawDetails(!showStrawDetails)}
                  className="d-150 h-50 justify-end"
                  style={{ fontSize: '2vw' }}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={8} hidden={!showStrawDetails}>
        {strawSum.length > 0
          && (
          <Card className="d-100" size="small">
            <Row className="d-100">
              <Col span={18}>
                <List style={{ height: 'auto', overflow: 'auto' }}>
                  {statisque('FLAX_STRAW', locObject.translate('stats_straw_quantity_surface_title'), true)}
                </List>
              </Col>
              <Col span={6}>
                <CloseCircleFilled
                  onClick={() => setshowStrawDetails(!showStrawDetails)}
                  className="d-100 h-100 justify-end"
                  style={{ fontSize: '2vw' }}
                />
              </Col>
            </Row>
          </Card>
          )}
      </Col>
      {/* fin de card paille */}
      <Col span={8} hidden={showLongFiberDetails}>
        <Card className="d-100" size="small">
          <Row className="d-100">
            <Col span={18}>
              <Statistic
                valueStyle={valueStyle}
                title={locObject.translate('stats_longFibers_title')}
                value={stocks !== null ? Number(longFibersSum !== undefined ? longFibersSum : 0) : 0}
                loading={stocks === null}
                decimalSeparator=","
                groupSeparator=" "
                precision={decPlaces}
                suffix="kg"
              />
            </Col>
            <Col span={6}>
              {longFibersSum > 0 && (
                <InfoCircleFilled
                  onClick={() => setShowLongFiberDetails(!showLongFiberDetails)}
                  className="d-100 h-100 justify-end"
                  style={{ fontSize: '2vw' }}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={8} hidden={!showLongFiberDetails}>
        <Card className="d-100" size="small">
          <Space direction="vertical" size={4} className="d-100">
            <Col
              span={6}
              style={{
                position: 'absolute', top: '42px', right: '15px', zIndex: 1,
              }}
            >
              <CloseCircleFilled
                onClick={() => setShowLongFiberDetails(!showLongFiberDetails)}
                className="d-100 h-100 justify-end"
                style={{ fontSize: '2vw' }}
              />
            </Col>
            {clf.length > 0
              && (
              <Card className="d-100" size="small">
                <Row className="d-100 h-100">
                  <Col span={18}>
                    <List style={{ height: 'auto', overflow: 'auto' }}>
                      {statisque('CLASSIFIED_LONG_FIBER', locObject.translate('classedLongFiber'), false)}
                    </List>
                  </Col>
                </Row>
              </Card>
              )}
            {dlf.length > 0
              && (
              <Card size="small">
                <List style={{ height: 'auto', overflow: 'auto' }}>
                  {statisque('DECLASSIFIED_LONG_FIBER', locObject.translate('nonClassedLongFiber'), false)}
                </List>
              </Card>
              )}
          </Space>
        </Card>
      </Col>
      <Col span={8} hidden={showShortFiberDetails}>
        <Card className="d-100" size="small">
          <Row className="d-100 ">
            <Col span={18}>
              <Statistic
                valueStyle={valueStyle}
                title={locObject.translate('stats_shortFibers_title')}
                value={stocks !== null ? Number(shortFibersSum !== undefined ? shortFibersSum : 0) : 0}
                loading={stocks === null}
                decimalSeparator=","
                groupSeparator=" "
                precision={decPlaces}
                suffix="kg"
              />
            </Col>
            <Col span={6}>
              {shortFibersSum > 0 && (
                <InfoCircleFilled
                  onClick={() => setShowShortFiberDetails(!showShortFiberDetails)}
                  className="d-100 h-100 justify-end"
                  style={{ fontSize: '2vw' }}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={8} hidden={!showShortFiberDetails}>
        <Card className="d-100" size="small">
          <Space direction="vertical" size={4} className="d-100">
            <Col
              span={6}
              style={{
                position: 'absolute', top: '42px', right: '15px', zIndex: 1,
              }}
            >
              <CloseCircleFilled
                onClick={() => setShowShortFiberDetails(!showShortFiberDetails)}
                className="d-100 h-100 justify-end"
                style={{ fontSize: '2vw' }}
              />
            </Col>
            {rsf.length > 0
              && (
              <Card className="d-100" size="small">
                <Row className="d-100 ">
                  <Col span={18}>
                    <List style={{ height: 'auto', overflow: 'auto' }}>
                      {statisque('RAW_SHORT_FIBER', locObject.translate('rawShortFiber'), false)}
                    </List>
                  </Col>
                </Row>
              </Card>
              )}
            {sssf.length > 0
              && (
                <Card size="small">
                  <List style={{
                    height: 'auto',
                    overflow: 'auto',
                  }}
                  >
                    {statisque('SEMI_SCUTCHED_SHORT_FIBER', locObject.translate('semiScutchedShortFiberStat'), false)}
                  </List>

                </Card>
              )}
            {ssf.length > 0
              && (
              <Card size="small">
                <List style={{ height: 'auto', overflow: 'auto' }}>
                  {statisque('SCUTCHED_SHORT_FIBER', locObject.translate('scutchedShortFiberStat'), false)}
                </List>
              </Card>
              )}
          </Space>
        </Card>
      </Col>
    </Row>
  );
}

export default TransformationStatistics;
