export default class Localization {
  locale = '';

  #prefixes = {
    trans: 'transformation',
    su: 'surfaces',
    dt: 'dataTable',
    ad_nv: 'admin_nav',
    sts_pdt: 'stats_products_table',
    mgus: 'mangeUsers',
    nu: 'newUser',
    mgcls: 'mangeClients',
    nc: 'newClient',
    mgfrs: 'manageFarmers',
    prm_pro: 'parameters_profile',
    prm_mgus: 'parameters_mangeUsers',
    prm_mgrgs: 'parameters_profile',
    prm_mgvars: 'parameters_profile',
    mgsc: 'parameters_profile',
  };

  #strings;

  constructor(locale, stringObject) {
    this.locale = locale;
    localStorage.setItem('userLang', `label${locale.toUpperCase()}`);
    this.#strings = stringObject.default.resources.strings;
  }

  translate(key, transform) {
    const string = this.#strings[key];
    switch (transform) {
      case 0:
        return string;
      case 1:
        return string.toLowerCase();
      case 2:
        return string.toUpperCase();
      case 3:
        // first letter capitalized
        return string.replace(string.charAt(0), string.charAt(0).toUpperCase());
      default:
        return string;
    }
  }

  translateWithPrefix(prefix, key) {
    return this.#strings[`${prefix}_${key}`];
  }

  translateFormItem(prefix, key) {
    const formItem = {
      label: this.#strings[`${prefix}_${key}Field_label`],
      mand_err: this.#strings[`${prefix}_${key}Field_mandetory_err`],
      ph: this.#strings[`${prefix}_${key}Field_ph`],
    };
    return formItem;
  }

  // strings : Array of
  // {
  // placeholder: the placeholder to be replaced ex : xxxx ,
  //  value : value to be placed.
  //  }
  translateWithReplace(key, ...strings) {
    let string = this.#strings[key];
    strings.forEach((str) => {
      string = string.replace(new RegExp(str.placeholder, 'g'), str.value);
    });
    return string;
  }
}
